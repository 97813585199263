<template>
  <v-row>
    <v-col offset="1" cols="10">
      <v-form ref="wasteForm" v-model="formValid" lazy-validation>
        <v-col v-if="component == 'quote'" class="pa-0">
          <v-col :offset="!headerstore&&!$vuetify.breakpoint.xs ? 1:0" :cols="$vuetify.breakpoint.xs ? 12 : 10" :class="headerstore?'pa-0':''">
            <v-card-title primary-title class="text-title mb-0 py-0" :style="{ display: headerstore?'':'block'}" :class="headerstore?'text-left':'text-center'">
              {{header}}
            </v-card-title>
          </v-col>
          <v-row v-if="!headerstore">
            <v-card-subtitle class="mx-auto text-center pa-2">
              {{ i18n.t('requestquoteCmp.serviceView.headerInstructions') }}
            </v-card-subtitle>
          </v-row>
        </v-col>
        <v-col v-if="component == 'quote'" class="pa-0 mt-4 min-height-22 d-flex align-center" :offset="$vuetify.breakpoint.xs ? 0:1" :cols="$vuetify.breakpoint.xs ? 12 : 10">
          <icon class="flex-grow-1" svg='step-marker-1'/>
        </v-col>
        <!-- Button Residential -->
        <div v-if="component == 'quote'" class="mb-0 pb-0">
          <!-- <v-col v-if="component == 'quote'" class="pa-0">
            <v-col class="pa-0 text-left">
              <v-card-title primary-title class="text-title mb-0 py-0 text-left pl-0" :style="{ display: headerstore?'':'block'}">
                {{header}}
              </v-card-title>
            </v-col>
          </v-col> -->
          <v-item-group :class="[$vuetify.breakpoint.width < 650 ? 'my-5' : 'my-5 d-flex justify-space-between',$vuetify.breakpoint.width < 650 && service === 'commercial' ? 'd-flex flex-column-reverse' : service === 'commercial' ? 'flex-row-reverse' : '']" v-model="form.serviceType">
            <v-item v-show="!(serviceLobLocked && service)" v-for="serviceType in servicesType" :key="serviceType.name" :value="serviceType.leadType">
              <template v-slot="{ active,toggle }">
                <v-hover v-slot="{hover}">
                    <v-card :dark="active?true:false" class="rounded-lg my-3" outlined :style="serviceFormErrors ?'border-width:2px !important;border-color:red !important;':'border-width:2px !important;border-color:'+serviceType.color+'!important;'" :width="$vuetify.breakpoint.width < 650 ? '100%' : '49%'" :class="serviceType.name =='CommercialService' ? classSvgBusiness(active,hover) : classSvgResidential(active,hover)" @click="(event) => warn(toggle,serviceType.leadType)" :value="active ? [(form.selectedTypeId = serviceType.name),(isFormShowing = true),serviceType.checked = true] : serviceType.checked = false" :disabled="loading">
                      <!-- Selected/Clicked icon section -->
                      <v-row align="center" justify="center" class="pa-3">
                          <v-col cols="auto">
                            <v-row align="center" justify="center">
                              <icon :svg="serviceType.icon" class="pa-4" :class="serviceType.name =='CommercialService' ? fillSvgBusiness(active,hover) : fillSvgResidential(active,hover)"></icon>
                              <span class="text-subtitle-1 font-weight-bold pt-0 text-break" style="line-height: 1.2;" v-html="$t(serviceType.text)"></span>
                            </v-row>
                          </v-col>
                          <v-spacer></v-spacer>
                          <v-col class="text-right flex-grow-0">
                            <v-checkbox
                              class="larger-checkbox"
                              :class=" active ? 'input-checkbox.checked' : 'input-checkbox'"
                              v-model="serviceType.checked">
                            </v-checkbox>
                          </v-col>
                      </v-row>
                    </v-card>
                </v-hover>
              </template>
            </v-item>
          </v-item-group>
        </div>
        <UserDetailField :lang="lang" :country="country" :parentRefs="$refs"></UserDetailField>
        <TermsPolicy :name="name" :terms="terms" :privacy="privacy" :lang="lang"></TermsPolicy>
        <v-row v-if="serviceFormErrors || !formValid" class="my-6" >
          <div class="v-messages error--text px-6 text-center" role="alert">
            <div class="subtitle-2" >
              <v-icon class="error--text" left="left" dense="dense">mdi-alert-outline</v-icon> {{ i18n.t('missingField') }}
            </div>
          </div>
        </v-row>
        <div>
          <!-- <div class="text-center mt-0 pt-0"  v-if="isFormShowing">
            <v-btn :color=" serviceFormErrors || !formValid ? 'error' : 'success'" block  height="70px" class="rounded-lg white--text" v-ripple="loading ? false : true" @click="loading ? null : validate()">
              <span class="btn-label">
                {{ loading ? i18n.t("validate") : i18n.t("continue") }}
              </span>
              <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
            </v-btn>
          </div> -->
          <ForwardBtn :parentRefs="$refs" currentTabComponent="ServiceView"></ForwardBtn>
        </div>
      </v-form>
  </v-col>
</v-row>
</template>
<script>

import { useUserStore } from "@/store/user";
import i18n from "@/i18n";
// import axios from "axios";
// import { load } from "recaptcha-v3";
import icon from "../utility/SvgLoader";
import { useIndexStore } from '../../../store/index';
import { mapState, mapWritableState } from 'pinia';
import { mapActions } from 'pinia';
import UserDetailField from "@/components/sfc/UserDetailField";
import TermsPolicy from "@/components/sfc/TermsPolicy";
import ForwardBtn from "../utility/forwardBtn.vue";
// import BackBtn from "../utility/backBtn.vue";

export default {
  name: "ServiceView",
  components:{icon,ForwardBtn,UserDetailField,TermsPolicy},
  props: {
    currentTabComponent: String,
    service: String,
    assigntodistrict: Number,
    lang: String,
    phone: String,
    country: String,
    terms: String,
    privacy: String,
    name: String,
    splitter: Boolean,
    load: Boolean,
    valid: Boolean,
    header: String,
    primary: String,
    secondary: String,
    storelayout: Boolean,
  },

  data() {
    return {
      i18n: i18n,
      hover: -1,
      active: false,
      isFormShowing: true,
      fill: "",

    };
  },
  created() {
      i18n.locale = this.lang;
  },
  computed: {
    ...mapState(useIndexStore, [
    "formRules","headerstore","servicesType","serviceLobLocked"]),
    ...mapWritableState(useIndexStore, [
    "component",
    "apiResult",
    "apiResultOriginal",
    // "dialog",
    'formValid',
    "loading",
    // 'google',
    // 'completeAddress',
    // 'completeAddressMsg',
    // 'view',
    'serviceFormErrors',
    "button"
    ]),
    ...mapWritableState(useUserStore, [
      "form",
    ]),
  },
  watch: {
    "form.serviceType": function () {
      if (this.form.serviceType) {
        this.serviceFormErrors = false;
      }else{
        this.serviceFormErrors = true;
      }
    },
    //Verifying if form is here.
    //if here, activate method for autoComplete

    // isFormShowing - > replace it back when demo is gone
    // isFormShowing: function (val) {
    //   if (val && !this.service) {
    //     this.$nextTick(function () {
    //       this.initializeGoogleAutocomplete();
    //     });
    //   }
    // },
  },
  methods: {
    // FOR MOUNTED AND FUNCTIONS PINIA
    ...mapActions(useIndexStore, [
    'updateViewComponent',
    'getLeadTypeFromServiceType',
    'acceptNumber',
    'updateStages',
    'warn',
    'fillSvgResidential',
    'fillSvgBusiness',
    'classSvgResidential',
    'classSvgBusiness',

  ]),

    getLeadTypeFromServiceType() {
      this.$emit("getLeadTypeFromServiceType",this.form.serviceType,this.form.categoryType);
    },

    // function for google auto complete, update address when typing
   validate() {
      var vm = this;
      if (vm.validateFormAndService()) {
      vm.updateStages("Address Stage");
     } else {
      vm.loading = false;
     }
    },
    validateFormAndService() {
      if(!this.form.serviceType){
        this.serviceFormErrors = true;
        return this.parentRefs.wasteForm.validate() && this.form.serviceType ;
      }
      else{
        this.serviceFormErrors = false;
      }
        return this.parentRefs.wasteForm.validate();
    },
   },
};
</script>
<style scoped>
::v-deep .larger-checkbox .v-icon {
  /* Adjust the size of the v-icon checkbox here */
  font-size: 42px !important;
}
::v-deep .input-checkbox .v-input--selection-controls__input{
  background-color: white !important;
}
::v-deep .input-checkbox.checked .v-input--selection-controls__input{
  background-color: #1BAB42 !important;
}
</style>
