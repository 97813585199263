<template>
    <div :class="$vuetify.breakpoint.width < 730 ? 'px-3 mx-3' : 'px-10 mx-10'">
    <!-- Title -->
        <v-row>
            <v-col>
                <v-card-text
                primary-title
                class="px-0 mt-4 mb-0 title text-center font-weight-bold text-wrap">
                {{i18n.t('chooseYourPreferredRollOffDumpster')}}
                </v-card-text>
                <v-card-text class="text-center pt-0 mb-8">
                <icon svg="step-marker"/>
                </v-card-text>
            </v-col>
        </v-row>

    <!-- List of all Roll Off size -->
        <v-form ref="form" v-model="formValid" lazy-validation @submit.prevent="">
            <v-item-group>
                <v-row>
                    <v-col class="d-flex flex-column" cols="12" sm="6" md="4" v-for="item in rollOffList" :key="item.i">
                        <v-card
                        @click="dumpsterSelected = item;"
                        :outlined="item.size.available && serviceFormErrors && rollOffList.every(function(v){ return v.qty == 0 }) && !serviceCardId.length  ? true : false " :style="item.available && serviceFormErrors && rollOffList.every(function(v){ return v.qty == 0 }) && !serviceCardId.length  ? 'border-width:2px !important;border-color:red !important;' : '' "
                        :class="item.qty ? 'blue-selected-bg' :  recommended && item.size.recommended ? 'recommended blue-default-bg' : 'blue-default-bg'" class="card-outter flex flex-collumn rounded-lg" elevation="2">
                            <icon v-if="recommended  && item.size.recommended" svg="recommended" class="recommended-position"/>
                            <div class="px-6 pt-4 pb-0 flex">
                                <v-row>
                                    <v-col>
                                        <h3 :class="item.qty ? 'white--text' : ''">{{i18n.locale==='fr'?item.size.textFr:item.size.text}}</h3>
                                        <h5 :class="item.qty ? 'white--text' : ''" class="font-weight-regular">{{i18n.locale==='fr'?item.size.specFr:item.size.spec}}</h5>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="mx-auto py-0 text-center">
                                        <v-img class="mx-auto" contain max-width="100%" max-height="100%" min-height="175" :src="item.size.img" :alt="i18n.locale==='fr'?item.size.textFr:item.size.text"/>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col class="pt-0">
                                        <div :class="item.qty ? 'white--text' : ''" v-html="i18n.locale==='fr'?item.size.descriptionFr:item.size.description"></div>
                                    </v-col>
                                </v-row>
                                <v-card-actions class="card-actions">
                                    <v-row>
                                        <v-col class="pl-1">
                                            <p>
                                                <span><icon :svg="item.size.icon1" :fill="item.qty ? '#FFFFFF' : defaultColor"></icon></span>
                                                <span class="pl-2"><icon :svg="item.size.icon2" :fill="item.qty ? '#FFFFFF' : defaultColor"></icon></span>
                                                <span class="pl-2"><icon :svg="item.size.icon3" :fill="item.qty ? '#FFFFFF' : defaultColor"></icon></span>
                                                <span class="pl-2"><icon :svg="item.size.icon4" :fill="item.qty ? '#FFFFFF' : defaultColor"></icon></span>
                                            </p>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                            </div>
                        </v-card>
                    </v-col>
                </v-row>
                <v-row>
                    <v-col>
                        <v-hover v-slot="{ hover }">
                            <v-card @click="servicefromquote = '';component = 'quote';currentTabComponent = form.menuOrder[form.menuOrder.length - 1]" :class="hover?'blue-hover-bg':'blue-default-bg'" class="rounded-lg" elevation="2">
                                <v-card-text class="d-flex justify-center pa-3">
                                    <div class="mx-5">
                                        <icon svg="question-mark" />
                                    </div>
                                    <div>
                                        <h3 class="py-0 card-label font-weight-bold text-wrap black--text">{{i18n.t('notSureWhatYouNeed')}}</h3>
                                        <p class="py-0 mb-0 card-label text-wrap black--text">{{i18n.t('requestAQuote')}}</p>
                                    </div>
                                </v-card-text>
                            </v-card>
                        </v-hover>
                    </v-col>
                </v-row>
            </v-item-group>
        </v-form>
    </div>
</template>

<script>
import icon from "../utility/SvgLoader";
import { mapActions, mapState, mapWritableState } from 'pinia';
import {useRollOffStore} from '../../../store/rolloffdumpster';
import { useDumpsterCommercialStore } from "@/store/dumpstercommercial";
import { useIndexStore } from '@/store/index';
import { useUserStore } from '@/store/user';
import storefront from '@/plugins/storefront';
import i18n from '@/i18n';

export default {
    name:"ChooseRollOff",
    components:{
        icon,
    },

    props:{
        recommended: Boolean,
        newstoreapi: Boolean,
    },

    data(){
        return{
            i18n:i18n,
            serviceFormErrors: false,
            defaultColor: "#1F528F",
        }
    },

    watch: {
        "dumpsterSelected": function(newVal,oldVal){
            if(newVal != oldVal){

                if(this.dumpsterSelected){

                    this.dumpsterSelected.qty = 1;
                    this.updateViewComponent("RollOffForm");

                }
                for (let index = 0; index < this.rollOffList.length; index++) {

                    if(this.dumpsterSelected.size.text == this.rollOffList[index].size.text){

                        this.rollOffList[index].qty = 1;

                    }else{

                        this.rollOffList[index].qty = 0;

                    }
                }
            }
        },
    },

    computed:{

        ...mapState(useRollOffStore,['rollOffList']),
        ...mapState(useIndexStore,['formRules']),
        ...mapWritableState(useDumpsterCommercialStore, ["dumpsterSelected"]),
        ...mapWritableState(useRollOffStore,["serviceCardId"]),
        ...mapWritableState(useIndexStore,['component',"formValid","formErrors","loading","servicefromquote","currentTabComponent"]),
        ...mapWritableState(useUserStore,["form"]),

    },

    methods: {
        ...mapActions(useIndexStore,['updateViewComponent','sendHttpRequestQuote']),

        // Validate for buttons
        validateWithService() {

            this.loading = true;
            storefront.sendInfoWithService();

        },
        validate() {

            this.loading = true;
            var vm = this;
            if (vm.validateForm()) {

                vm.sendHttpRequestQuote()
                vm.updateViewComponent('RollOffForm')
                vm.loading = false;

            } else {

                vm.loading = false;

            }
        },

        validateForm() {

            if (!this.serviceCardId.length && this.rollOffList.every(function(v){return v.qty == 0})) {

                this.serviceFormErrors = true;
                this.formValid = false;
                this.formErrors = [];
                this.formErrors.push(i18n.t("missingField"));
                return this.$refs.form.validate() && !this.formErrors.length;

            }
            return this.$refs.form.validate();
        },
    },


}
</script>

<style>
.card-outter {
    padding-bottom: 50px;
}
.card-actions {
    position: absolute;
    bottom: 0;
}
.card-label {
    font-size:16px;
    font-weight: 500;
}
</style>