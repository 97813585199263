<template>
  <v-alert v-if="errorAxios" class="text-center" type="error" block>
    {{ $i18n.t('wereSorry') }}
  </v-alert>
</template>
<script>
import i18n from "@/i18n";
export default {
  name: "HandlingError",
  props: ["errorAxios"],
  data() {
    return {i18n: i18n};
  },
};
</script>

<style lang="scss" scoped></style>
