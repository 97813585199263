<template>
      <v-card-text>
        <v-form ref="form">
          <v-row>
            <v-col class="text-center">

              <v-img src="https://media.istockphoto.com/id/1414371275/vector/handshake-and-partnership-logo-design-template-best-deal-logo-design.jpg?s=170667a&w=0&k=20&c=boxcPhH7NRqgmzIZpcHlptJL9wE-RnLFu-9MyTymcc4=" width="350px" height="200px" class="mx-auto mb-3"/>
              <v-card-text style="color: #175e45" class="congrats pa-1 mt-6">
                {{form.servicesWantedPricingAvailable ? form.servicesWantedPricingAvailable.toFixed(2) : i18n.t('na')}} $
              </v-card-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-center align-center">
              <v-card elevation="0" width="420px">
                <v-row>
                  <v-col class="pt-2 px-0">
                    <v-card-text class="pb-6 text-center text-quote px-0 " :width="windowSize.x < 600 ? '400px' :'420px'">
                      {{ i18n.t('confirmTheTotalPrice') }}
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center pt-1">
              <v-btn @click="loading ? null : validate('Accepted');" block max-width="100%" height="60px" style="text-transform: unset" class="white--text rounded-lg success"  outlined>
                <span class="btn-label">
                  {{loadingAccept ? i18n.t("validate") : "Accept" }}
                </span>
              <v-progress-circular v-if="loadingAccept" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
              </v-btn>
            </v-col>
            <v-col class="text-center pt-1">
              <v-btn @click="loading ? null : validate('Refused')" block max-width="100%" height="60px" style="text-transform: unset" class="white--text error rounded-lg" outlined>
                <span class="btn-label">
                  {{loadingRefuse ? i18n.t("validate") : "Refuse" }}
                </span>
                <v-progress-circular v-if="loadingRefuse" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
</template>

<script>
import i18n from "@/i18n";
// import { useDumpsterCommercialStore } from '../../store/dumpstercommercial';
import { useIndexStore } from '../../../../store/index';
import { mapWritableState } from 'pinia';
import { mapActions } from 'pinia';



export default {
  name: "ThankyouConfirm",
  props:{
  },
  data() {
    return {
      i18n: i18n,
      selectedType: "",
      loadingAccept: false,
      loadingRefuse: false
    };
  },
  computed: {
    ...mapWritableState(useIndexStore, [
      "windowSize","loading"
    ]),
    ...mapWritableState(useIndexStore, [
      "form","loading",
    ])
  },
  methods: {
    ...mapActions(useIndexStore, [
      'updateViewComponent',"sendHttpRequestQuote"
    ]),
    async validate(status){
      this.loading = true;
      if(status == "Accepted"){
        this.loadingAccept = true;
      }
      if(status == "Refused"){
        this.loadingRefuse = true;
      }
      var vm = this;
      vm.form.servicesWantedOfferStatus = status

      vm.sendHttpRequestQuote().then(function(){
      if(vm.form.servicesWantedOfferStatus == "Accepted"){
      vm.updateViewComponent('ThankyouExplore')
      vm.loadingAccept = false;
      }
      if(vm.form.servicesWantedOfferStatus == "Refused"){
      vm.updateViewComponent('ThankyouExplore')
      vm.loadingRefuse = false;
      }
  })}

    }
};
</script>

<style lang="css" scoped>
::v-deep .text-quote {
  font-size: 22px;
}
::v-deep .text-quote-ty {
  font-size: 20px;
}
::v-deep .congrats {
  font-size: 32px;
}
</style>
