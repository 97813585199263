<template>
  <div>
    <v-card class="d-flex rounded-lg-ni" :height="height ? height : ''" :width="width ? width : '120px'" outlined :color="form.wantedQtyErr ? 'error' : ''" :class="form.wantedQtyErr ? 'error-border':'numberInput-card'">

      <v-btn tile depressed x-small class="rounded-l-lg px-0" :height=" height ? height : '38px'" @click="decrement(value)" v-bind:disabled="disabled">
        <v-icon color="grey darken-2" dense>
          mdi-minus
        </v-icon>
      </v-btn>

      <v-text-field :height="height ? height : ''" readonly solo flat hide-details hide-spin-buttons :value="value" :rules="rules ? rules : []" dense class="numberInput rounded-0" elevation="0" type="number" ref="input" v-bind:disabled="disabled" @input="updateValue($event.target.value)"/>

      <v-btn tile depressed x-small :height="height ? height : '38px'" class="rounded-r-lg px-0" @click="increment(value)" v-bind:disabled="disabled">
        <v-icon color="grey darken-2" dense>
          mdi-plus
        </v-icon>
      </v-btn >
    </v-card>
    <div class="v-messages error--text px-3 pt-1" role="alert">
      <div v-if="form.wantedQtyErr" class="v-messages__message" >
        {{rules[0](value) }}
      </div>
    </div>
 </div>
</template>

<script>
import i18n from "@/i18n";
import { useUserStore } from "../../../store/user";
import { useIndexStore } from '../../../store/index';
import { mapState, mapWritableState } from 'pinia';
export default {
  name: "NumberInput",
  props: ["disabled","value",'height','width',"rules"],
  data() {
    return {
      i18n: i18n,
      };
  },
  computed: {
    // Map state properties as readonly computed properties
    ...mapWritableState(useUserStore, [
      "form",
      ]),
    ...mapState(useIndexStore, [
      "formRules",]),
    ...mapWritableState(useIndexStore, ["windowSize","formErrors"])
  },
  watch: {
    // formError: function (newVal,oldVal){
    //   if (newVal != oldVal) {
    //     if (this.rule[0](this.modelForm) == true) {
    //       this.valid = true;
    //     } else {
    //       this.valid = true;
    //     }
    //   this.updateNumber();
    //   }
    // },

    //  modelForm: function (newVal, oldVal) {
    //   if (newVal != oldVal) {
    //     if (this.rule[0](this.modelForm) == true) {
    //       this.valid = true;
    //     } else {
    //       this.valid = true;
    //     }
    //   }
    //   this.updateNumber();
    // },
  },
  methods: {
    updateValue(quantity){
      this.$emit('input',quantity);
    },
    increment(quantity) {
      quantity++;
      if (quantity >= 7) {
        quantity = 7;
      }
      this.$emit('input',quantity) ;
    },
    decrement(quantity) {
      quantity--;
      if (quantity <= 0) {
        quantity = 0;
      }
      this.$emit('input',quantity) ;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .rounded-lg-ni {
  border-radius:9px !important;
}
::v-deep .v-application .rounded-l-lg {
  border-top-left-radius: 12px !important;
  border-bottom-left-radius: 12px !important ;
  border-top-right-radius: 0px !important;
  border-bottom-right-radius: 0px !important ;
}
::v-deep .v-application .rounded-r-lg {
  border-top-right-radius: 12px !important ;
  border-bottom-right-radius: 12px !important;
  border-top-left-radius: 0px !important ;
  border-bottom-left-radius: 0px !important;
}
::v-deep .numberInput-card{
    border: 1px solid rgba(0, 0, 0, .4) !important;

}
::v-deep .error-border{
  border: 2px solid !important;
}
::v-deep .numberInput input {
  height: 35px;
  font-size: 22px;
  text-align: center;
}
</style>
