<template>
    <v-container fluid="fluid" class="transparent">
        <v-card flat>
            <!-- <v-card-text v-if="view.index&&(canada?!form.type:false)">
                <div class="overline mb-4 text-left">
                    {{ $t('header.title') }}
                </div>
                <div class="text--primary text-center">
                    {{ $t('header.instructions.formTypeFalse') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.index&&(canada?form.type:true)">
                <div class="overline mb-4 text-left">
                    {{ $t('header.title') }}
                </div>
                <div class="text--primary text-center">
                    {{ $t('header.instructions.formTypeTrue') }}
                </div>
            </v-card-text> -->
            <v-card-text v-if="view.contactUs">
                <div class="overline mb-4 text-left">
                    {{ $t('generalInquiry.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('generalInquiry.instructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.landfill">
                <div class="overline mb-4 text-left">
                    {{ $t('landfillInquiry.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('landfillInquiry.instructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.bulkItemPickup">
                <div class="overline mb-4 text-left">
                    {{ $t('bulkItemPickup.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('bulkItemPickup.instructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.missedPickup">
                <div class="overline mb-4 text-left">
                    {{ $t('missedPickup.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('missedPickup.instructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.brokenContainer">
                <div class="overline mb-4 text-left">
                    {{ $t('brokenContainer.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('brokenContainer.instructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.deliveryIssue">
                <div class="overline mb-4 text-left">
                    {{ $t('deliveryIssue.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('deliveryIssue.instructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.iAmMoving||view.transferService">
                <div class="overline mb-4 text-left">
                    {{ $t('iAmMovingAndTransferService.title') }}
                </div>
                <div v-if="view.iAmMoving" class="mb-4 text--primary text-center">
                    {{ $t('iAmMoving.instructions') }}
                </div>
                <div v-if="view.transferService" class="mb-4 text--primary text-center">
                    {{ $t('transferService.instructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.cancelService">
                <div class="overline mb-4 text-left">
                    {{ $t('cancelService.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('cancelService.instructionsPart1') }} {{ phone }} {{ $t('cancelService.instructionsPart2') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.stopService">
                <div class="overline mb-4 text-left">
                    {{ $t('stopService.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('stopService.instructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.serviceUpgrade">
                <div class="overline mb-4 text-left">
                    {{ $t('serviceUpgrade.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('serviceUpgrade.instructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.vacation">
                <div class="overline mb-4 text-left">
                    {{ $t('vacation.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('vacation.instructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.driverReview">
                <div class="overline mb-4 text-left">
                    {{ $t('driverReview.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('driverReview.mainInstructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.requestQuote">
                <div class="overline mb-4 text-left">
                    {{ $t('requestQuote.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('requestQuote.mainInstructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.startService">
                <div class="overline mb-4 text-left">
                    {{ $t('startService.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('startService.mainInstructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.requestPickup">
                <div class="overline mb-4 text-left">
                    {{ $t('requestPickup.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('requestPickup.instructions') }}
                </div>
            </v-card-text>
            <v-card-text v-if="view.recyclePlus">
                <div class="overline mb-4 text-left">
                    {{ $t('recyclePlus.title') }}
                </div>
                <div class="title mb-4 text-center">
                    {{ $t('recyclePlus.instructions') }}
                </div>
                <v-card-text class="text-center pt-0">
                    {{ $t('recyclePlus.instructionsSubtitle') }}
                </v-card-text>
            </v-card-text>
            <v-divider v-if="!view.cancelService&&!view.payMyBill"></v-divider>
            <v-card-actions v-if="view.index">
                <v-container class="pa-0">
                    <v-row v-if="canada" no-gutters="no-gutters" class="pa-2">
                        <v-col>
                            <v-bottom-navigation v-model="form.type" dark="dark" class="ma-auto rounded" color="white" background-color="secondary" height="80" width="368" grow="grow">
                                <v-btn value="Residential" class="mx-2" text="text" active-class="active">
                                    <span class="text-button mt-1">{{ $t('serviceLocationType.home') }}</span>
                                    <v-icon large="large">mdi-home-variant-outline</v-icon>
                                </v-btn>
                                <v-divider inset="inset" vertical="vertical"></v-divider>
                                <v-btn value="Commercial" class="mx-2" text="text" active-class="active">
                                    <span class="text-button mt-1">{{ $t('serviceLocationType.business') }}</span>
                                    <v-icon large="large">mdi-store-outline</v-icon>
                                </v-btn>
                            </v-bottom-navigation>
                        </v-col>
                    </v-row>
                    <div v-if="!services.length">
                        <v-row v-if="canada?form.type:true" no-gutters="no-gutters">
                            <v-col v-if="form.type!=='Residential'" cols="12" v-bind:sm="form.type!=='Residential'?6:12" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="primary" v-on:click="viewForm('bulkItemPickup')">
                                    {{ $t('serviceOptions.bulkItemPickup') }}
                                </v-btn>
                            </v-col>
                            <v-col v-if="form.type!=='Residential'" cols="12" sm="6" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="secondary" v-on:click="viewForm('startService')">
                                    {{ $t('serviceOptions.startService') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="canada?form.type:true" no-gutters="no-gutters">
                            <v-col v-if="form.type!=='Residential'" cols="12" md="4" sm="6" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="primary" v-on:click="viewForm('brokenContainer')">
                                    {{ $t('serviceOptions.brokenContainer') }}
                                </v-btn>
                            </v-col>
                            <v-col v-if="form.type!=='Residential'" cols="12" md="4" sm="6" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="secondary" v-on:click="viewForm('transferService')">
                                    {{ $t('serviceOptions.transferService') }}
                                </v-btn>
                            </v-col>
                            <v-col v-if="form.type!=='Residential'" cols="12" md="4" sm="12" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="primary" v-on:click="viewForm('missedPickup')">
                                    {{ $t('serviceOptions.missedPickup') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="canada?form.type:true" no-gutters="no-gutters">
                            <v-col cols="12" v-bind:md="form.type!=='Residential'?4:12" v-bind:sm="form.type!=='Residential'?6:12" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="secondary" v-on:click="viewForm('driverReview')">
                                    {{ $t('serviceOptions.driverReview') }}
                                </v-btn>
                            </v-col>
                            <v-col v-if="form.type!=='Residential'" cols="12" md="4" sm="6" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="primary" v-on:click="viewForm('iAmMoving')">
                                    {{ $t('serviceOptions.iAmMoving') }}
                                </v-btn>
                            </v-col>
                            <v-col v-if="form.type!=='Residential'" cols="12" v-bind:md="form.type!=='Residential'?4:6" v-bind:sm="12" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="secondary" v-on:click="viewForm('serviceUpgrade')">
                                    {{ $t('serviceOptions.serviceUpgrade') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="canada?form.type:true" no-gutters="no-gutters">
                            <v-col v-if="form.type!=='Residential'&&!stopservice" cols="12" md="4" sm="6" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="primary" v-on:click="viewForm('cancelService')">
                                    {{ $t('serviceOptions.cancelService') }}
                                </v-btn>
                            </v-col>
                            <v-col v-if="form.type!=='Residential'&&stopservice" cols="12" md="4" sm="6" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="primary" v-on:click="viewForm('stopService')">
                                    {{ $t('serviceOptions.stopService') }}
                                </v-btn>
                            </v-col>
                            <v-col v-if="form.type!=='Residential'" cols="12" md="4" sm="6" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="secondary" v-on:click="viewForm('deliveryIssue')">
                                    {{ $t('serviceOptions.deliveryIssue') }}
                                </v-btn>
                            </v-col>
                            <v-col cols="12" v-bind:md="form.type!=='Residential'?4:12" v-bind:sm="12" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="primary" v-on:click="viewForm('contactUs')">
                                    {{ $t('serviceOptions.generalInquiry') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="canada?form.type:true" no-gutters="no-gutters">
                            <v-col v-if="form.type!=='Residential'" cols="12" md="4" sm="6" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="secondary" v-on:click="viewForm('requestQuote')">
                                    {{ $t('serviceOptions.requestQuote') }}
                                </v-btn>
                            </v-col>
                            <v-col v-if="form.type!=='Residential'" cols="12" md="4" sm="6" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="primary" v-on:click="viewForm('vacation')">
                                    {{ $t('serviceOptions.vacation') }}
                                </v-btn>
                            </v-col>
                            <v-col cols="12" v-bind:md="form.type!=='Residential'?4:12" v-bind:sm="12" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="secondary" v-on:click="viewForm('payMyBill')">
                                    {{ $t('serviceOptions.payMyBill') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="(canada?form.type:true)" no-gutters="no-gutters">
                            <v-col v-if="form.type!=='Residential' && requestpickup" cols="12" v-bind:md="form.type!=='Residential'?4:12" v-bind:sm="6" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="primary" v-on:click="viewForm('requestPickup')">
                                    {{ $t('serviceOptions.requestPickup') }}
                                </v-btn>
                            </v-col>
                            <v-col v-if="form.type!=='Residential' && recycleplus" cols="12" v-bind:md="form.type!=='Residential'?4:12" v-bind:sm="6" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" color="primary" v-on:click="viewForm('recyclePlus')">
                                    {{ $t('serviceOptions.recyclePlus') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                    <div v-if="services.length">
                        <v-row no-gutters="no-gutters">
                            <v-col v-for="(service, index) in serviceItems" :key="index" cols="12" md="4" sm="6" class="d-flex">
                                <v-btn class="ma-2 flex-grow-1" outlined="outlined" :color="index%2?'secondary':'primary'" v-on:click="viewForm(service.action)">
                                    {{ service.name }}
                                </v-btn>
                            </v-col>
                        </v-row>
                    </div>
                </v-container>
            </v-card-actions>
            <v-card-text v-if="view.iAmMoving">
                <v-alert class="ma-0" dense="dense" outlined="outlined" prominent="prominent" text="text" color="orange" type="info">
                    <v-row align="center">
                        <v-col class="grow">
                            {{ $t('iAmMoving.transferServiceCardText') }}
                        </v-col>
                        <v-col class="shrink">
                            <v-btn outlined="outlined" color="orange" v-on:click="viewForm('transferService')">
                                {{ $t('iAmMoving.transferCardBtn') }} <br> {{ $t('iAmMoving.serviceCardBtn') }}
                            </v-btn>
                        </v-col>
                    </v-row>
                </v-alert>
            </v-card-text>
            <v-card-text v-if="false"><!--view.missedPickup||view.brokenContainer||view.deliveryIssue||view.iAmMoving||view.serviceUpgrade-->
                <v-expansion-panels>
                    <v-expansion-panel>
                        <v-expansion-panel-header>
                            {{ $t('districtPolicy.title') }}
                        </v-expansion-panel-header>
                        <v-expansion-panel-content>
                            {{ $t('districtPolicy.text') }}
                        </v-expansion-panel-content>
                    </v-expansion-panel>
                </v-expansion-panels>
            </v-card-text>
            <v-divider v-if="view.missedPickup||view.brokenContainer||view.deliveryIssue||view.iAmMoving"></v-divider>
            <v-card-text v-if="!view.index&&!view.landfill&&!light&&!servicefromquote" v-bind:class="view.requestQuote || view.startService?'pr-0 pl-0 pb-0':'pb-0'">
                <v-row v-bind:class="view.cancelService?'mb-0':''">
                    <v-col class="text-center">
                        <v-btn small="small" outlined="outlined" text="text" v-bind:disabled="loading" v-on:click="resetAllContactUs(true)">
                            <v-icon left="left">
                                mdi-restart
                            </v-icon>
                            {{ $t('universalFormContent.backBtn') }}
                        </v-btn>
                    </v-col>
                </v-row>
            </v-card-text>
            <v-card-text v-if="servicefromquote" class="pa-4 min-height-44 d-flex justify-center align-center">
                <BackBtn currentTabComponent="ContactWidget"></BackBtn>
            </v-card-text>
            <v-card-text v-if="view.address||view.prediction">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container v-if="view.address">
                        <UserDetailField :lang="lang" :country="canada?'canada':''" :parentRefs="$refs"></UserDetailField>
                    </v-container>
                    <v-container v-if="view.prediction">
                        <v-row>
                            <v-col>
                                <v-list dense="dense" two-line="two-line" subheader="subheader" v-bind:disabled="loading">
                                    <v-subheader class="justify-center text-center">{{ $t('predictions.subheader') }}</v-subheader>
                                    <v-list-item-group v-model="form.predictionsSelected" mandatory="mandatory" active-class="secondary--text">
                                        <template v-for="(prediction, index) in form.predictions">
                                            <v-list-item two-line v-bind:key="'L'+index" v-bind:value="prediction.description">
                                                <template v-slot:default="{ active }">
                                                    <v-list-item-content>
                                                        <v-list-item-title>{{ prediction.structured_formatting.main_text }}</v-list-item-title>
                                                        <v-list-item-subtitle class="text--primary">{{ prediction.structured_formatting.secondary_text }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                    <v-list-item-action>
                                                        <v-icon v-if="!active">
                                                            mdi-checkbox-blank-circle-outline
                                                        </v-icon>
                                                        <v-icon v-else color="primary">
                                                            mdi-check-circle-outline
                                                        </v-icon>
                                                    </v-list-item-action>
                                                </template>
                                            </v-list-item>
                                            <v-divider v-if="index < form.predictions.length - 1" v-bind:key="'D'+index"></v-divider>
                                        </template>
                                    </v-list-item-group>
                                </v-list>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-form>

                <v-slide-y-transition hide-on-leave>
                    <v-container v-if="!valid">
                        <v-row class="mt-0">
                            <v-col class="text-center">
                                <span class="text-caption error--text"><v-icon class="error--text" left="left" dense="dense">mdi-alert-outline</v-icon>{{ $i18n.t('universalFormContent.itemsAttention') }}</span>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-slide-y-transition>
            </v-card-text>
            <v-card-text v-if="!view.index&&(!view.address&&!view.prediction&&view.main)&&!view.cancelService&&!view.requestQuote&&!view.startService&&!view.payMyBill" class="pb-0">
                <v-form ref="form" v-model="valid" lazy-validation>
                    <v-container>
                        <v-row>
                            <v-col cols="12" class="pa-3">
                                <v-card outlined="outlined" class="flex-grow-1">
                                    <v-list-item dense="dense">
                                        <v-list-item-content>
                                            <v-icon large="large" color="accent">mdi-map-marker-outline</v-icon>
                                            <div class="text-primary text-center">{{form.address}}</div>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row v-if="!servicefromquote" class="mb-0">
                            <v-col class="text-center">
                                <v-btn small="small" outlined="outlined" text="text" v-bind:disabled="loading" v-on:click="viewStep('address')">
                                    <v-icon left="left">
                                        mdi-pencil
                                    </v-icon>
                                    {{ $t('universalFormContent.editAddressBtn') }}
                                </v-btn>
                            </v-col>
                        </v-row>
                        <v-row v-if="view.transferService">
                            <v-col cols="12" class="pa-3">
                                <h4 v-if="view.transferService" class="v-heading text-h6 mb-2 pl-8 text-left">
                                    {{ $t('transferService.fields.newAddress') }}
                                </h4>
                                <v-text-field ref="autocompleteInputTo" dense="dense" outlined="outlined" v-model="form.addressTo" v-bind:rules="formRules.addressRules" prepend-icon="mdi-map-search-outline" :placeholder="$t('universalFormContent.fields.addressTo')" single-line="single-line" v-bind:disabled="loading" required="required"></v-text-field><!--label="Address"-->
                            </v-col>
                        </v-row>
                        <!-- <v-row>
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-text-field dense="dense" outlined="outlined" v-model="form.name" v-bind:rules="formRules.nameRules" prepend-icon="mdi-form-textbox" :label="$t('universalFormContent.fields.name')" v-bind:disabled="loading" required="required"></v-text-field>
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-text-field dense="dense" outlined="outlined" v-model="form.phone" type="tel" v-bind:rules="formRules.phoneRules" prepend-icon="mdi-phone-outline" :label="$t('universalFormContent.fields.phone')" v-bind:disabled="loading" required="required"></v-text-field>
                            </v-col>
                        </v-row> -->
                        <v-row>
                            <!-- <v-col cols="12" sm="6" class="pa-3">
                                <v-text-field dense="dense" outlined="outlined" v-model="form.email" type="email" v-bind:rules="formRules.emailRules" prepend-icon="mdi-email-outline" :label="$t('universalFormContent.fields.email')" v-bind:disabled="loading" required="required"></v-text-field>
                            </v-col> -->
                            <v-col cols="12" sm="12" class="pa-3">
                                <v-select v-if="view.contactUs&&!light" v-bind:rules="formRules.requiredRules" dense="dense" outlined="outlined" v-model="form.containerOut" v-bind:items="generalIssueItems" prepend-icon="mdi-chat-question-outline" :label="$t('generalInquiry.fields.generalIssue')" attach="attach" v-bind:disabled="loading"></v-select>
                                <v-textarea v-if="view.landfill||light" v-bind:rules="formRules.descriptionRules" dense="dense" outlined="outlined" auto-grow="auto-grow" rows="1" v-model="form.description" prepend-icon="mdi-text-box-outline" :label="$t('landfillInquiry.fields.description.label')" :hint="$t('contactUs.fields.description.hint')" v-bind:disabled="loading"></v-textarea>
                                <v-textarea v-if="view.bulkItemPickup" v-bind:rules="formRules.descriptionRules" dense="dense" outlined="outlined" auto-grow="auto-grow" rows="1" v-model="form.description" prepend-icon="mdi-text-box-outline" :label="$t('bulkItemPickup.fields.description.label')" :hint="$t('bulkItemPickup.fields.description.hint')" v-bind:disabled="loading"></v-textarea>
                                <v-textarea v-if="view.requestPickup" v-bind:rules="formRules.descriptionRules" dense="dense" outlined="outlined" auto-grow="auto-grow" rows="1" v-model="form.description" prepend-icon="mdi-text-box-outline" :label="$t('requestPickup.fields.description.label')" :hint="$t('requestPickup.fields.description.hint')" v-bind:disabled="loading"></v-textarea>
                                <v-textarea v-if="view.missedPickup" v-bind:rules="formRules.descriptionRules" dense="dense" outlined="outlined" auto-grow="auto-grow" rows="1" v-model="form.description" prepend-icon="mdi-text-box-outline" :label="$t('missedPickup.fields.description.label')" :hint="$t('missedPickup.fields.description.hint')" v-bind:disabled="loading"></v-textarea>
                                <v-textarea v-if="view.brokenContainer||view.deliveryIssue||view.iAmMoving||view.transferService" v-bind:rules="formRules.descriptionRules" dense="dense" outlined="outlined" auto-grow="auto-grow" rows="1" v-model="form.description" prepend-icon="mdi-text-box-outline" :label="$t('contentForManyServices.fields.description.additionalDetailsLabel')" :hint="$t('contentForManyServices.fields.description.additionalDetailsHint')" v-bind:disabled="loading"></v-textarea>
                                <v-textarea v-if="view.serviceUpgrade" v-bind:rules="formRules.descriptionRules" dense="dense" outlined="outlined" auto-grow="auto-grow" rows="1" v-model="form.description" prepend-icon="mdi-text-box-outline" label="Description" :hint="$t('serviceUpgrade.fields.description.label')" v-bind:disabled="loading"></v-textarea>
                                <v-textarea v-if="view.vacation||view.stopService" v-bind:rules="formRules.descriptionRules" dense="dense" outlined="outlined" auto-grow="auto-grow" rows="1" v-model="form.description" prepend-icon="mdi-text-box-outline" :label="$t('contentForManyServices.fields.description.additionalDetailsLabel')" :hint="$t('contentForManyServices.fields.description.hintVacationAndStopService')" v-bind:disabled="loading"></v-textarea>
                                <v-textarea v-if="view.driverReview" v-bind:rules="formRules.descriptionRules" dense="dense" outlined="outlined" auto-grow="auto-grow" rows="1" v-model="form.description" prepend-icon="mdi-text-box-outline" :label="$t('driverReview.fields.description.label')" :hint="$t('driverReview.fields.description.hint')" v-bind:disabled="loading"></v-textarea>
                                <v-textarea v-if="view.recyclePlus" v-bind:rules="formRules.descriptionRules" dense="dense" outlined="outlined" auto-grow="auto-grow" rows="1" v-model="form.description" prepend-icon="mdi-text-box-outline" :label="$t('recyclePlus.fields.description.label')" :hint="$t('recyclePlus.fields.description.hint')" v-bind:disabled="loading"></v-textarea>
                            </v-col>
                        </v-row>
                        <v-row v-if="view.contactUs||view.missedPickup||view.deliveryIssue||view.iAmMoving||view.transferService||view.requestPickup||view.recycePlus">
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-textarea v-if="view.contactUs&&!light" :required="form.containerOut==='Other'?true:false" :rules="form.containerOut==='Other'?formRules.requiredDescriptionRules:formRules.descriptionRules" dense="dense" outlined="outlined" auto-grow="auto-grow" rows="1" v-model="form.description" prepend-icon="mdi-text-box-outline" label="Description" :hint="$t('contactUs.fields.description.hint')" v-bind:disabled="loading"></v-textarea>
                                <v-menu v-if="view.requestPickup" v-model="form.datePickupMenu" v-bind:close-on-content-click="false" transition="fade-transition" offset-y="offset-y" max-width="290px" min-width="auto" top="top" attach="attach" v-bind:disabled="loading">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense="dense" outlined="outlined" v-model="form.datePickupFormatted" v-bind:rules="canada?formRules.dateCanadaRules:formRules.dateRules" :label="$t('requestPickup.fields.date')" prepend-icon="mdi-calendar" @click:prepend="form.datePickupMenu = true" :hint="canada?'YYYY-MM-DD':'MM/DD/YYYY'" persistent-hint="persistent-hint" v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.datePickup" no-title="no-title" v-bind:min="minDate" v-bind:max="maxDate" v-bind:locale="!canada?'en-US':lang=='fr'?'fr-CA':'en-CA'" v-on:input="form.datePickupMenu = false"></v-date-picker>
                                </v-menu>
                                <v-menu v-if="view.missedPickup" v-model="form.datePickupMenu" v-bind:close-on-content-click="false" transition="fade-transition" offset-y="offset-y" max-width="290px" min-width="auto" top="top" attach="attach" v-bind:disabled="loading">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense="dense" outlined="outlined" v-model="form.datePickupFormatted" v-bind:rules="canada?formRules.dateCanadaRules:formRules.dateRules" :label="$t('missedPickup.fields.date')" prepend-icon="mdi-calendar" @click:prepend="form.datePickupMenu = true" :hint="canada?'YYYY-MM-DD':'MM/DD/YYYY'" persistent-hint="persistent-hint" v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.datePickup" no-title="no-title" v-bind:min="minDate" v-bind:max="maxDate" v-bind:locale="!canada?'en-US':lang=='fr'?'fr-CA':'en-CA'" v-on:input="form.datePickupMenu = false"></v-date-picker>
                                </v-menu>
                                <v-menu v-if="view.deliveryIssue" v-model="form.datePickupMenu" v-bind:close-on-content-click="false" transition="fade-transition" offset-y="offset-y" max-width="290px" min-width="auto" top="top" attach="attach" v-bind:disabled="loading">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense="dense" outlined="outlined" v-model="form.datePickupFormatted" v-bind:rules="canada?formRules.dateCanadaRules:formRules.dateRules" :label="$t('deliveryIssue.fields.date')" prepend-icon="mdi-calendar" @click:prepend="form.datePickupMenu = true" :hint="canada?'YYYY-MM-DD':'MM/DD/YYYY'" persistent-hint="persistent-hint" v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.datePickup" no-title="no-title" v-bind:min="minDate" v-bind:max="maxDate" v-bind:locale="!canada?'en-US':lang=='fr'?'fr-CA':'en-CA'" v-on:input="form.datePickupMenu = false"></v-date-picker>
                                </v-menu>
                                <v-menu v-if="view.iAmMoving||view.transferService" v-model="form.datePickupMenu" v-bind:close-on-content-click="false" transition="fade-transition" offset-y="offset-y" max-width="290px" min-width="auto" top="top" attach="attach" v-bind:disabled="loading">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense="dense" outlined="outlined" v-model="form.datePickupFormatted" v-bind:rules="canada?formRules.dateCanadaRules:formRules.dateRules" :label="$t('contentForManyServices.fields.lastServiceDate')" prepend-icon="mdi-calendar" @click:prepend="form.datePickupMenu = true" :hint="canada?'YYYY-MM-DD':'MM/DD/YYYY'" persistent-hint="persistent-hint" v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.datePickup" no-title="no-title" v-bind:min="minDate" v-bind:max="maxDate" v-bind:locale="!canada?'en-US':lang=='fr'?'fr-CA':'en-CA'" v-on:input="form.datePickupMenu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col v-if="view.requestPickup" cols="12" sm="6" class="pa-3">
                                <v-combobox dense="dense" outlined="outlined" clearable="clearable" hide-selected="hide-selected" small-chips="small-chips" v-model="form.cartType" v-bind:rules="formRules.requiredRules" v-bind:items="cartTypeItems" :label="$t('missedPickup.fields.cartType')" attach="attach" prepend-icon="mdi-delete-outline" v-bind:disabled="loading"></v-combobox>
                            </v-col>
                            <v-col v-if="view.missedPickup" cols="12" sm="6" class="pa-3">
                                <v-combobox v-if="view.missedPickup" dense="dense" outlined="outlined" clearable="clearable" hide-selected="hide-selected" small-chips="small-chips" v-model="form.containerOut" v-bind:rules="formRules.requiredRules" v-bind:items="missedPickupItems" :label="$t('missedPickup.fields.containerOut')" attach="attach" prepend-icon="mdi-crosshairs-question" v-bind:disabled="loading"></v-combobox>
                            </v-col>
                            <v-col v-if="view.deliveryIssue" cols="12" sm="6" class="pa-3">
                                <v-combobox v-if="view.deliveryIssue" dense="dense" outlined="outlined" clearable="clearable" hide-selected="hide-selected" small-chips="small-chips" v-model="form.containerOut" v-bind:rules="formRules.requiredRules" v-bind:items="deliveryIssueItems" :label="$t('deliveryIssue.fields.containerOut')" attach="attach" prepend-icon="mdi-crosshairs-question" v-bind:disabled="loading"></v-combobox>
                            </v-col>
                        </v-row>
                        <v-row v-if="view.requestPickup" class="pt-0">
                            <v-col class="pt-0">
                                <v-card-text class="pt-1 pl-9
                                 text-left">
                                    {{ $t('requestPickup.fields.noGuaranteeService') }}
                                </v-card-text>
                            </v-col>
                        </v-row>
                        <v-row v-if="view.deliveryIssue">
                            <v-col>
                                <v-file-input dense="dense" outlined="outlined" chips="chips" small-chips="small-chips" show-size="show-size" accept="image/*" v-model="form.fileUpload" v-bind:rules="formRules.resumeRules" :label="$t('deliveryIssue.fields.image')" :hint="$t('deliveryIssue.fields.imageHint')" persistent-hint="persistent-hint" v-bind:disabled="loading"></v-file-input>
                            </v-col>
                        </v-row>
                        <v-row v-if="view.missedPickup">
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-combobox dense="dense" outlined="outlined" clearable="clearable" hide-selected="hide-selected" small-chips="small-chips" v-model="form.cartType" v-bind:rules="formRules.requiredRules" v-bind:items="cartTypeItems" :label="$t('missedPickup.fields.cartType')" attach="attach" prepend-icon="mdi-delete-outline" v-bind:disabled="loading"></v-combobox>
                            </v-col>
                        </v-row>
                        <v-row v-if="view.brokenContainer">
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-combobox dense="dense" outlined="outlined" clearable="clearable" hide-selected="hide-selected" small-chips="small-chips" v-model="form.containerIssue" v-bind:rules="formRules.requiredRules" v-bind:items="brokenContainerIssueItems" :label="$t('brokenContainer.fields.issue')" attach="attach" prepend-icon="mdi-alert-outline" v-bind:disabled="loading"></v-combobox><!-- Container issue -->
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-combobox dense="dense" outlined="outlined" clearable="clearable" hide-selected="hide-selected" small-chips="small-chips" v-model="form.cartType" v-bind:rules="formRules.requiredRules" v-bind:items="cartTypeItems" :label="$t('brokenContainer.fields.cartType')" attach="attach" prepend-icon="mdi-delete-outline" v-bind:disabled="loading"></v-combobox><!-- Type of cart that is broken/lost -->
                            </v-col>
                        </v-row>
                        <v-row v-if="view.brokenContainer">
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-menu v-model="form.datePickupMenu" v-bind:close-on-content-click="false" transition="fade-transition" offset-y="offset-y" max-width="290px" min-width="auto" top="top" attach="attach" v-bind:disabled="loading">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense="dense" outlined="outlined" v-model="form.datePickupFormatted" v-bind:rules="canada?formRules.dateCanadaRules:formRules.dateRules" :label="$t('brokenContainer.fields.date')" prepend-icon="mdi-calendar" @click:prepend="form.datePickupMenu = true" :hint="canada?'YYYY-MM-DD':'MM/DD/YYYY'" persistent-hint="persistent-hint" v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.datePickup" no-title="no-title" v-bind:min="minDate" v-bind:max="maxDate" v-bind:locale="!canada?'en-US':lang=='fr'?'fr-CA':'en-CA'" v-on:input="form.datePickupMenu = false"></v-date-picker>
                                </v-menu><!-- Date container was broken/lost -->
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-combobox v-if="form.containerIssue==='Broken'" dense="dense" outlined="outlined" clearable="clearable" hide-selected="hide-selected" small-chips="small-chips" v-model="form.containerOut" v-bind:rules="formRules.requiredRules" v-bind:items="brokenContainerPartItems" :label="$t('brokenContainer.fields.containerOut')" attach="attach" prepend-icon="mdi-crosshairs-question" v-bind:disabled="loading"></v-combobox><!-- What is broken? -->
                            </v-col>
                        </v-row>
                        <v-row v-if="view.serviceUpgrade">
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-select dense="dense" outlined="outlined" v-model="form.upgradeService" v-bind:rules="formRules.requiredRules" v-bind:items="upgradeServiceItems" item-text="name" item-value="name" max-height="auto" autocomplete="autocomplete" multiple="multiple" attach="attach" v-bind:menu-props="{auto:true,offsetY:true}" prepend-icon="mdi-crosshairs-question" :label="$t('serviceUpgrade.fields.upgradeChoices')" v-bind:disabled="loading"></v-select>
                            </v-col>
                        </v-row>
                        <v-row v-if="view.vacation||view.stopService">
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-menu v-model="form.pickupBeforeMenu" v-bind:close-on-content-click="false" transition="fade-transition" offset-y="offset-y" max-width="290px" min-width="auto" top="top" attach="attach" v-bind:disabled="loading">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense="dense" outlined="outlined" v-model="form.pickupBeforeFormatted" v-bind:rules="canada?formRules.dateCanadaRules:formRules.dateRules" :label="$t('contentForManyServices.fields.lastPickupDate')" prepend-icon="mdi-calendar" @click:prepend="form.pickupBeforeMenu = true" :hint="canada?'YYYY-MM-DD':'MM/DD/YYYY'" persistent-hint="persistent-hint" v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.pickupBefore" no-title="no-title" v-bind:min="minDate" v-bind:max="maxDate" v-bind:locale="!canada?'en-US':lang=='fr'?'fr-CA':'en-CA'" v-on:input="form.pickupBeforeMenu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-menu v-if="view.vacation" v-model="form.pickupAfterMenu" v-bind:close-on-content-click="false" transition="fade-transition" offset-y="offset-y" max-width="290px" min-width="auto" top="top" attach="attach" v-bind:disabled="loading">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense="dense" outlined="outlined" v-model="form.pickupAfterFormatted" v-bind:rules="canada?formRules.dateCanadaRules:formRules.dateRules" :label="$t('vacation.fields.firstPickupDate')" prepend-icon="mdi-calendar" @click:prepend="form.pickupAfterMenu = true" :hint="canada?'YYYY-MM-DD':'MM/DD/YYYY'" persistent-hint="persistent-hint" v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.pickupAfter" no-title="no-title" v-bind:min="minDate" v-bind:max="maxDate" v-bind:locale="!canada?'en-US':lang=='fr'?'fr-CA':'en-CA'" v-on:input="form.pickupAfterMenu = false"></v-date-picker>
                                </v-menu>
                                <v-combobox v-if="view.stopService" dense="dense" outlined="outlined" clearable="clearable" hide-selected="hide-selected" small-chips="small-chips" v-model="form.stopRequestStatus" v-bind:rules="formRules.requiredRules" v-bind:items="stopServiceItems" :label="$t('stopService.fields.requestStatus')" attach="attach" prepend-icon="mdi-crosshairs-question" v-bind:disabled="loading"></v-combobox>
                            </v-col>
                        </v-row>
                        <v-row v-if="view.driverReview">
                            <v-col cols="12" sm="6" class="pa-3">
                                <v-menu v-model="form.driverTimeMenu" v-bind:close-on-content-click="false" transition="fade-transition" offset-y="offset-y" max-width="290px" min-width="auto" top="top" attach="attach" v-bind:disabled="loading">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field dense="dense" outlined="outlined" v-model="form.driverTimeFormatted" v-bind:rules="canada?formRules.dateCanadaRules:formRules.dateRules" :label="$t('driverReview.fields.date')" prepend-icon="mdi-calendar" @click:prepend="form.driverTimeMenu = true" :hint="canada?'YYYY-MM-DD':'MM/DD/YYYY'" persistent-hint="persistent-hint" v-bind="attrs" v-on="on"></v-text-field>
                                    </template>
                                    <v-date-picker v-model="form.driverTime" no-title="no-title" v-bind:min="minDate" v-bind:max="maxDate" v-bind:locale="!canada?'en-US':lang=='fr'?'fr-CA':'en-CA'" v-on:input="form.driverTimeMenu = false"></v-date-picker>
                                </v-menu>
                            </v-col>
                            <v-col cols="12" sm="6" class="pa-3 align-start">
                                <span class="mr-2">
                                    {{ $t('driverReview.fields.rating') }} ({{ form.driverRating }})
                                </span>
                                <v-rating v-model="form.driverRating" background-color="primary" color="primary" half-increments="half-increments" hover="hover" x-large="x-large" v-bind:disabled="loading"></v-rating>
                            </v-col>
                        </v-row>
                        <v-row v-if="view.iAmMoving">
                            <v-col>
                                <v-checkbox v-model="form.rollOffBox" :label="$t('iAmMoving.fields.rollOffBox')" v-bind:disabled="loading"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row v-if="view.transferService">
                            <v-col>
                                <v-checkbox v-model="form.rollOffBox" :label="$t('transferService.fields.rollOffBox')" v-bind:disabled="loading"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-checkbox v-model="form.checkbox" :label="$t('universalFormContent.fields.rememberMe')" v-bind:disabled="loading"></v-checkbox>
                        <v-checkbox v-model="form.acceptConditions" v-bind:rules="formRules.acceptConditions" v-bind:disabled="loading" required="required">
                            <template slot="label">
                                <div>
                                    {{ $t('universalFormContent.fields.termsAndConditions.part1') }} {{$i18n.locale=='en'?name:''}}
                                    <v-tooltip attach bottom>
                                        <template v-slot:activator="{ on }">
                                            <a target="_blank" rel="nofollow noopener" v-bind:href="terms" v-on:click.stop="return false" v-on="on">
                                                {{ $t('universalFormContent.fields.termsAndConditions.part2') }}
                                            </a>
                                        </template>
                                        {{ $t('universalFormContent.fields.termsAndConditions.part2') }}
                                    </v-tooltip>
                                    {{ $t('universalFormContent.fields.termsAndConditions.part3') }}
                                    <v-tooltip attach bottom>
                                        <template v-slot:activator="{ on }">
                                            <a target="_blank" rel="nofollow noopener" v-bind:href="privacy" v-on:click.stop="return false" v-on="on">
                                                {{ $t('universalFormContent.fields.termsAndConditions.part4') }}
                                            </a>
                                        </template>
                                        {{ $t('universalFormContent.fields.termsAndConditions.part4') }}
                                    </v-tooltip>
                                    {{ $t('universalFormContent.fields.termsAndConditions.part5') }} {{$i18n.locale=='fr'?name:''}}
                                </div>
                            </template>
                        </v-checkbox>
                    </v-container>
                </v-form>
                <v-slide-y-transition hide-on-leave>
                    <v-container v-if="!valid">
                        <v-row class="mt-0">
                            <v-col class="text-center">
                                <span class="text-caption error--text"><v-icon class="error--text" left="left" dense="dense">mdi-alert-outline</v-icon>{{ $i18n.t('universalFormContent.itemsAttention') }}</span>
                            </v-col>
                        </v-row>
                    </v-container>
                </v-slide-y-transition>
            </v-card-text>
            <v-divider v-if="!view.index&&!view.cancelService&&!view.payMyBill"></v-divider>
            <v-card-actions v-if="view.address">
                <v-btn :color="!valid?'red':'secondary'" block="block" v-ripple="loading ? false : true" v-on:click="loading ? null : validate('address')">
                    {{loading ? $t('universalFormContent.validate') : $t('universalFormContent.next')}}
                    <v-icon v-if="!loading" right="right">mdi-chevron-right</v-icon>
                    <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
                </v-btn>
            </v-card-actions>
            <v-card-actions v-if="view.prediction" class="justify-space-between">
                <v-btn class="flex-grow-1" color="secondary" v-ripple="loading ? false : true" v-on:click="loading ? null : viewStep('address')">
                    <v-icon left="left">mdi-chevron-left</v-icon>
                    {{$t('universalFormContent.backBtn')}}
                </v-btn>
                <v-btn class="flex-grow-1" color="secondary" v-ripple="loading ? false : true" v-on:click="loading ? null : getAddressPrediction(google,form.predictionsSelected,'address')">
                    {{loading ? $t('universalFormContent.validate') : $t('universalFormContent.next')}}
                    <v-icon v-if="!loading" right="right">mdi-chevron-right</v-icon>
                    <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
                </v-btn>
            </v-card-actions>
            <v-card-actions v-if="!view.index&&!view.address&&!view.prediction&&!view.cancelService&&!view.requestQuote&&!view.startService&&!view.payMyBill">
                <v-btn :color="!valid?'red':'secondary'" block="block" v-ripple="loading ? false : true" v-on:click="loading ? null : view.transferService&&view.main&&!form.addressTo?validate('addressTo'):validate('sendAddressTo')">
                    {{loading ? $t('universalFormContent.loading') : $t('universalFormContent.submit')}}
                    <v-icon v-if="!loading" right="right">mdi-send-outline</v-icon>
                    <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
                </v-btn>
            </v-card-actions>
            <v-card-actions v-if="!view.index&&!view.cancelService&&!view.requestQuote&&!view.startService&&!view.payMyBill">
                <span class="text-caption text--disabled ma-auto"><v-icon class="text--disabled" left="left" dense="dense">mdi-lock-outline</v-icon>{{ $t('universalFormContent.recaptcha') }}</span>
            </v-card-actions>
        </v-card>
        <v-col v-if="view.payMyBill">
            <div class="title mb-4 text-center">
                {{ $t('universalFormContent.mainLoading') }}
            </div>
        </v-col>
        <v-row class="mb-0" justify="center">
            <v-dialog v-model="questionDialog" persistent="persistent" attach="attach" hide-overlay max-width="644">
                <v-card>
                    <v-card-title class="headline">
                        {{ $t('universalFormContent.accountLocated') }}
                    </v-card-title>
                    <v-card-text>
                        {{ $t('universalFormContent.selectAnswersIdentity.instructions') }}
                        <v-form ref="apiValues" v-model="valid" lazy-validation>
                            <v-container>
                                <v-row>
                                    <v-col>
                                        <v-select dense="dense" outlined="outlined" v-model="apiValues.answerName" v-bind:items="apiValues.questionsName" prepend-icon="mdi-form-textbox" :menu-props="{maxHeight:150}"
                                            :label="$t('universalFormContent.selectAnswersIdentity.name')" required="required" v-bind:rules="formRules.requiredRules" attach="attach" v-bind:disabled="loading"></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-select dense="dense" outlined="outlined" v-model="apiValues.answerEmail" v-bind:items="apiValues.questionsEmail" prepend-icon="mdi-email-outline" :menu-props="{maxHeight:150}"
                                            :label="$t('universalFormContent.selectAnswersIdentity.email')" required="required" v-bind:rules="formRules.requiredRules" attach="attach" v-bind:disabled="loading"></v-select>
                                    </v-col>
                                </v-row>
                                <v-row>
                                    <v-col>
                                        <v-select dense="dense" outlined="outlined" v-model="apiValues.answerPhone" v-bind:items="apiValues.questionsPhone" prepend-icon="mdi-phone-outline" :menu-props="{maxHeight:150}"
                                            :label="$t('universalFormContent.selectAnswersIdentity.phone')" required="required" v-bind:rules="formRules.requiredRules" attach="attach" v-bind:disabled="loading"></v-select>
                                    </v-col>
                                </v-row>
                            </v-container>
                        </v-form>
                    </v-card-text>
                    <v-card-actions>
                        <v-btn outlined="outlined" color="primary" width="35%" v-on:click="resetDialog" v-bind:disabled="loading">
                            <v-icon left="left">mdi-cancel</v-icon>
                            {{ $t('universalFormContent.cancel') }}
                        </v-btn>
                        <v-btn outlined="outlined" color="secondary" width="65%" v-ripple="loading?false:true" v-on:click="loading?null:validateAnswers()">
                            {{loading?$t('universalFormContent.loading'):$t('universalFormContent.submit')}}
                            <v-icon v-if="!loading" right="right">mdi-send-outline</v-icon>
                            <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-row justify="center">
            <v-dialog v-model="standardDialog" persistent="persistent" attach="attach" hide-overlay max-width="644">
                <v-card>
                    <v-card-title class="headline">
                        {{textDialog.title}}
                    </v-card-title>
                    <v-card-text class="text-left">
                        {{textDialog.feedback}}
                    </v-card-text>
                    <v-card-text v-if="textDialog.googleRating!==''">
                        {{textDialog.googleRating}}
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn outlined="outlined" color="primary" width="25%" v-on:click="resetDialog">
                            <v-icon left="left">mdi-close-circle-outline</v-icon>
                            {{ $t('universalFormContent.close') }}
                        </v-btn>
                        <v-btn v-if="textDialog.buttonLocations" width="74%" outlined="outlined" color="secondary" v-bind:href="map">
                            {{ $t('universalFormContent.locationsMap') }}
                            <v-icon right="right">mdi-map-search-outline</v-icon>
                        </v-btn>
                        <v-btn v-if="textDialog.buttonGoogleRating" width="74%" outlined="outlined" color="secondary" href="https://www.google.com" target="_blank" rel="nofollow noopener">
                            {{ $t('universalFormContent.googleReview') }}
                            <v-icon right="right">mdi-message-draw</v-icon>
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </v-row>
        <v-dialog v-model="recaptchaDialog" attach hide-overlay max-width="290">
            <v-card>
                <v-card-title class="text-h5 d-flex justify-center">
                    <v-icon x-large color="primary">mdi-alert-circle-outline</v-icon>
                </v-card-title>
                <v-card-text class="text-center">{{ i18n.t('snackbar.body1') }}</v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="recaptchaDialog = false">
                        {{ i18n.t('snackbar.close') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>
</template>

<script>
import { useIndexStore } from '../../store/index';
import { mapWritableState,mapState, mapActions } from 'pinia';
import axios from 'axios';
// import { load } from 'recaptcha-v3';
import { useContactUsStore } from '@/store/contactus';
import { useUserStore } from '@/store/user';
import UserDetailField from "@/components/sfc/UserDetailField";
import i18n from "@/i18n";
import googleApi from "@/plugins/google"
import BackBtn from "../requestquote/utility/backBtn.vue"
export default {
    name: 'ContactWidget',
    components: {
        UserDetailField,
        BackBtn
    },
    props: {
        canada: Boolean,
        service: String,
        services: String,
        assigntolocation: Number,
        assigntodistrict: Number,
        bulkitempickup: String,
        requestpickup: Boolean,
        recycleplus: Boolean,
        stopservice: Boolean,
        phone: String,
        lang: String,
        light: Boolean,
        terms: String,
        privacy: String,
        map: String,
        pay: String,
        name: String,
    },
    data: () => ({
        i18n: i18n,
        recaptchaDialog: false,
    }),
    created() {
        // BIND PROPS TO PINIA TO USE VARIABLES IN PINIA STORE
        if(this.requestpickup){
            this.requestpickupCU = this.requestpickup;
        }
        if(this.recycleplus){
            this.recycleplusCU = this.recycleplus;
        }
        if(this.bulkitempickup){
            this.bulkitempickupCU = this.bulkitempickup;
        }
        if(this.pay){
            this.payCU = this.pay;
        }
        if(this.services){
            this.servicesCU = this.services
        }
        if(this.assigntolocation){
            this.assigntolocationCU = this.assigntolocation;
        }
        if(this.canada){
            this.canadaCU = this.canada
        }
        if(this.lang){
            this.langCU = this.lang;
        }
        if(this.light){
            this.lightCU = this.light;
        }
        i18n.locale = this.lang;
    },
    mounted: function(){
        // i18n languages
        // Default vuetify color theme
        this.$i18n.locale = this.lang;
        this.$vuetify.theme.themes.light.primary = '#00624a';
        this.$vuetify.theme.themes.light.secondary = '#0053a0';

        if(this.view.name){
            this.viewForm(this.view.name)
        }

        // if(this.service){

        //     this.viewForm(this.service, true);

        // }

        //  Get the cookie from browser if it exist
        if(this.getCookie('SiteSettings')){

            const obj = JSON.parse(this.getCookie('SiteSettings'));
            const objForm = this.form;
            const a = [objForm, obj];
            Object.assign(...a);

        }
    },
    watch: {

        'view.main':function(val){if(val&&this.view.transferService){this.$nextTick(function(){this.mapsPlacesAutocomplete(this.google,this.$refs.autocompleteInputTo.$el.querySelector("input"),this.updateAddress);})}},

        // Check dates if exist from v-model and format the date to preview
        'form.datePickup':function(){this.form.datePickupFormatted = this.form.datePickup?this.formatDate(this.form.datePickup, this.canada?'en_CA':''):null},
        'form.pickupBefore':function(){this.form.pickupBeforeFormatted = this.form.pickupBefore?this.formatDate(this.form.pickupBefore, this.canada?'en_CA':''):null},
        'form.pickupAfter':function(){this.form.pickupAfterFormatted = this.form.pickupAfter?this.formatDate(this.form.pickupAfter, this.canada?'en_CA':''):null},
        'form.driverTime':function(){this.form.driverTimeFormatted = this.form.driverTime?this.formatDate(this.form.driverTime, this.canada?'en_CA':''):null},

    },
    computed: {

        // Get variables from Pinia Store
        // CONTACT US PINIA STORE
        ...mapState(useContactUsStore,["formRules", "serviceItems", "generalIssueItems", "missedPickupItems", "brokenContainerIssueItems", "brokenContainerPartItems", "cartTypeItems", "deliveryIssueItems", "upgradeServiceItems", "stopServiceItems"]),

        ...mapWritableState(useContactUsStore, ["view", "google", "valid", "loading", "standardDialog", "questionDialog", "minDate", "maxDate", "apiValues", "textDialog", "payCU", "servicesCU", "assigntolocationCU", "canadaCU", "langCU","lightCU","bulkitempickupCU","requestpickupCU","recycleplusCU"]),

        // INDEX PINIA STORE
        ...mapWritableState(useIndexStore, ["component", "currentTabComponent","servicefromquote"]),

        // USER  PINIA STORE
        ...mapWritableState(useUserStore, ['form']),
    },
    methods: {

        //  **********************************************
        //   PINIA STORE
        //  **********************************************
        // CONTACT US FUNCTIONS
        ...mapActions(useContactUsStore,['verifyGoogleAddress', 'getAddressPrediction', 'verificationByAddress',
        'sendEmailCustomerAndDistrict', 'sendCase', 'addBackClosestDistrictInfo', 'resetAllContactUs', 'resetDialog','resetStep', 'resetForm', 'viewFormLabel', 'viewForm', 'viewStep', 'setCookie', 'getCookie'
        ]),
        // INDEX FUNCTIONS
        ...mapActions(useIndexStore,['updateViewComponent','formatDate','reCaptchaValidation']),

        // Function to activate second address
        mapsPlacesAutocomplete(google,input,callback){

            var vm = this;

            //  Check if view is transfer service or main
            if(this.view.transferService&&this.view.main){

                if(!googleApi.initializeGoogleAutocomplete(google,input,callback)){

                    // If address doesn't exist, show error message in
                    // field text
                    if(vm.form.address.length){
                    vm.completeAddressMsg = i18n.t("completeAddressRequired");
                    }else{
                    vm.completeAddressMsg = '';
                    }

                }else{

                    // If address exist and selected, bind it to form address
                    vm.form.addressTo = googleApi.initializeGoogleAutocomplete(google,input,callback);

                }
            }

        },
        // For callback function to bind form.addressTo (second address bar)
        updateAddress(address) {
        this.form.addressTo = address;
        },

        // ! this function is to validate form. Pinia can't use $refs to validate forms !
        // validate a form depending on which one by using a parameter to check which validation to trigger
        validate(step) {

            var vm = this;
            if(vm.$refs.form.validate() && step == 'address'){
            vm.loading = true;

            // Trigger RECAPTCHA after validating the address
                vm.reCaptchaValidation('validationContact')

            }

            if(vm.$refs.form.validate() && step == 'addressTo'){

                // Check validation from form and the parameter
                vm.verifyGoogleAddress(step);

            }

            if(vm.$refs.form.validate() && step == 'sendAddressTo'){

                // Check validation from form and the parameter
                vm.verificationByAddress();

            }
        },

        validateAnswers(){

            this.loading = true;
            let vm = this;
            if(this.$refs.apiValues.validate()){

                axios.post(process.env.VUE_APP_RESTURL+"services/apexrest/PayNowSearch", {
                    sessionID: this.apiValues.sessionID,
                    answer: {
                        "name": this.apiValues.answerName,
                        "email": this.apiValues.answerEmail,
                        "phone": this.apiValues.answerPhone
                    },
                    method: 'getVerifyAnswerLite'

                }).then(function (response) {

                    if(response.status===200) {

                        vm.addBackClosestDistrictInfo(response.data);
                        var res = JSON.parse(vm.apiValues.apiResponse);

                        if(res.Message === 'Session has been completed before. Cannot be used again') {

                            vm.customerAction(false, false, 'time');

                        }
                        else if(res.accountFound){

                            vm.sendEmailCustomerAndDistrict(true, true, true);

                        } else {

                            if(res.districtNumber||(res.closestDistrictInfo&&res.closestDistrictInfo.District)) {
                                vm.sendEmailCustomerAndDistrict(true, true, true);// true false true
                            }else{
                                vm.sendEmailCustomerAndDistrict(true, true, false); // false, false, false
                            }

                        }

                    }else {
                        vm.loading = false;
                    }

                }).catch(function (error) {

                    vm.loading = false;
                    vm.customerAction(false, false, 'read');
                    console.log(error);

                });
            } else {
                vm.loading = false;
            }
        },
    },
}
</script>