import { defineStore } from 'pinia';
import { useUserStore } from "./user";
import { useIndexStore } from './index';
import i18n from "@/i18n";

// helper.js
export const useAxiosFormStore = defineStore('AxiosStore',{
  state: () => ({
    // à voir
    form: useUserStore().form,
    apiResult: useIndexStore().apiResult,
    leadExternalId: '',
    businessName: '',
    street: '',
    city: '',
    state: '',
    zip: '',
    country: '',
    firstName: '',
    lastName: '',
    email: '',
    phone: '',
    districtID: '',
    alreadyACustomer: '',
    serviceDetail: '',
    categoryType: '',
    assignToDistrict: 0,
    // shreddingDetail: '',
    description: '',
    webLeadStep: '',
    serviceType: '',
    webLeadServiceFor: '',
    serviceForm: [],
    wantedSize: '',
    wantedFrequency: '',
    wantedStartDate: '',
    wantedDeliveryDate: '',
    wantedEndDate: '',
    wantedQty: '',
    businessType: '',
    servicesWantedJSON: null,
    survey: '',
    servicesWantedOfferStatus: '',
    servicesWantedWithPricing: false,
    utmSource: '',
    utmCampaign: '',
    utmMedium: '',
    utmTerm: '',
    utmReferrer: '',
    gclid: '',
    fclid: '',
    lang: '',
    leadHOA: false,
    leadTenant: false,
    leadHOAAnswer: null,
    leadTenantAnswer: null,
    leadAPILoadTime: 0,
    lng: '',
    lat: '',
  }),
  actions: {

    // Send all data needed to salesforce
    getDataToAxios(){
      const campaignURL = JSON.parse(sessionStorage.getItem("campaignURL"));
      let utmSource = '', utmCampaign = '', utmMedium = '', utmTerm = '', gclid = '', fclid = '', referrer = '', promoCode = '';
      let description = useUserStore().form.description;
      if(campaignURL){
          utmSource = campaignURL["utmSource"];
          utmCampaign = campaignURL["utmCampaign"];
          utmMedium = campaignURL["utmMedium"];
          utmTerm = campaignURL["utmTerm"]||campaignURL["etid"];
          gclid = campaignURL["gclid"];
          fclid = campaignURL["fclid"];
          referrer = campaignURL["referrer"];
          promoCode = campaignURL["promoCode"];
      }
      if (promoCode) {
        description = 'Promo Code: Spring Clean\n' + description;
      }
      return{
        leadExternalId: useUserStore().form.leadExternalId ? useUserStore().form.leadExternalId : "",
        company: useUserStore().form.businessName,
        streetAddress: useUserStore().form.street,
        cityAddress: useUserStore().form.city,
        stateAddress:useUserStore().form.state,
        zipAddress: useUserStore().form.zip,
        lat: useUserStore().form.lat,
        lng: useUserStore().form.lng,
        countryAddress: useUserStore().form.country,
        firstName: useUserStore().form.firstName,
        lastName: useUserStore().form.lastName,
        email: useUserStore().form.email?.toLowerCase(),
        phone: useIndexStore().formatPhone(useUserStore().form.phone),
        district: useIndexStore().apiResult.districtID,
        currentCustomer: useIndexStore().duplicateCustomerCheck(true) || useUserStore().form.alreadyACustomer,
        webLeadServiceDetail: useUserStore().form.categoryType,
        priorityId: useUserStore().form.assignToDistrict !== 0 ? useUserStore().form.assignToDistrict : "",
        webLeadFrequency: useUserStore().form.shreddingDetail ?  useUserStore().form.shreddingDetail : '',
        description: description,
        leadSource: "Web",
        webSourceDetail: window.location.href.split("?")[0],
        completeWebSourceDetail: window.location.href,
        webSourceForm: useUserStore().form.webSourceForm,
        webLeadAction: useIndexStore().trackViewSplitter?'Request Quote Splitter':'',
        formStep: useUserStore().form.webLeadStep,
        serviceType: useIndexStore().getLeadTypeFromServiceType(useUserStore().form.serviceType, useUserStore().form.categoryType),
        webLeadServiceFor: useUserStore().form.serviceType === 'Residential' ? 'Home' : useUserStore().form.serviceType === 'Commercial' ? 'Business and Organizations' : '',
        servicesWanted: useUserStore().form.serviceForm ? useUserStore().form.serviceForm.toString() : "",
        // servicesWantedSize: useUserStore().form.wantedSize,
        // servicesWantedFrequency: useUserStore().form.wantedFrequency,
        servicesWantedStartDate: useUserStore().form.wantedStartDate || useUserStore().form.wantedDeliveryDate,
        servicesWantedEndDate: useUserStore().form.wantedEndDate,
        servicesWantedQty: useUserStore().form.wantedQty || '',
        servicesWantedBusinessType: useUserStore().form.serviceDetail ? useUserStore().form.serviceDetail : useUserStore().form.businessType,
        servicesWantedJSON: useUserStore().form.title ? '' : useUserStore().form.servicesWantedJSON,
        servicesWantedOfferFeedback: useUserStore().form.survey ? useIndexStore().formatSurvey(useUserStore().form.survey) : '', // is nowhere to be found
        servicesWantedOfferStatus: useUserStore().form.servicesWantedOfferStatus,
        servicesWantedWithPricing: useUserStore().form.servicesWantedWithPricing,
        storefrontCommercial: useIndexStore().containServicePackgesOf('commercial'),
        storefrontConstruction: useIndexStore().containServicePackgesOf('construction'),
        storefrontResidential: useIndexStore().containServicePackgesOf('residential'),
        gid: typeof window.parent.ga !== "undefined" && typeof window.parent.ga.getAll === 'function' ? window.parent.ga.getAll()[0].get("clientId") : "",
        utmSource: utmSource,
        utmCampaign: utmCampaign,
        utmMedium: utmMedium,
        utmTerm: utmTerm,
        utmReferrer: referrer,
        gclid: gclid,
        fclid: fclid,
        lang: i18n?.locale?.toUpperCase(),
        leadHOA: useIndexStore().apiResult?.data?.StoreFrontSetting?.Storefront_HOA_Enabled?useIndexStore().apiResult.data.StoreFrontSetting.Storefront_HOA_Enabled:this.leadHOA,
        leadHOAAnswer: this.leadHOAAnswer,
        leadTenant: useIndexStore().apiResult?.data?.StoreFrontSetting?.EnableCustomerTenantCheck?useIndexStore().apiResult.data.StoreFrontSetting.EnableCustomerTenantCheck:this.leadTenant,
        leadTenantAnswer: this.leadTenantAnswer,
        leadAPILoadTime: this.leadAPILoadTime?parseFloat(this.leadAPILoadTime.toFixed(2)):0,
        agreeToTermOfService: useUserStore().form.acceptConditions?true:false,
      }
    },
}
})