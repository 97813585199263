import { render, staticRenderFns } from "./Hoa.vue?vue&type=template&id=323e704e"
import script from "./Hoa.vue?vue&type=script&lang=js"
export * from "./Hoa.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports