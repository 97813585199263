<!-- BackBtn -->
<!-- Button to return to the previous page -->


<template>

    <!-- ********************************************** -->
    <!-- If currentTabComponent === 'AddressView', the button displays "Back" and closes a dialog if the page is not loading. -->
    <!-- ********************************************** -->
    <v-btn v-if="currentTabComponent==='AddressView'" block large color="grey" class="white--text rounded-lg"
        @click="loading||loadingWithService?null:dialog=false">
        <v-icon x-large left>
            mdi-chevron-left
        </v-icon>
        <v-spacer></v-spacer>
        {{ i18n.t("back") }}
        <v-spacer></v-spacer>
    </v-btn>


    <!-- ********************************************** -->
    <!-- If currentTabComponent === 'ContactWidget', the button displays "Back" and executes the backFromContactUsToRequestQuote() function to return to the previous tab. -->
    <!-- ********************************************** -->
    <v-btn v-else-if="currentTabComponent==='ContactWidget'" large color="grey" class="white--text rounded-lg"
        @click="loading||loadingWithService?null:backFromContactUsToRequestQuote()">
        <v-icon x-large left>
            mdi-chevron-left
        </v-icon>
        <v-spacer></v-spacer>
        {{ i18n.t("back") }}
        <v-spacer></v-spacer>
    </v-btn>


    <!-- ********************************************** -->
    <!-- button displays "Back" and calls the back() function if no loading action is in progress. -->
    <!-- ********************************************** -->
    <v-btn v-else absolute large color="grey" class="white--text rounded-lg"
        @click="loading||loadingWithService?null:back()">
        <v-icon x-large left>
            mdi-chevron-left
        </v-icon>
        <v-spacer></v-spacer>
        {{ i18n.t("back") }}
        <v-spacer></v-spacer>
    </v-btn>
</template>

<script>
    import i18n from "@/i18n";
    import { useIndexStore } from '../../../store/index';
    import { useContactUsStore } from '@/store/contactus';
    import { mapState, mapWritableState } from 'pinia';
    import { mapActions } from 'pinia';
    export default {
        name: "BackBtn",
        props: ["currentTabComponent"],
        data() {
            return {
                i18n: i18n,
            };
        },
        computed: {
            ...mapState(useIndexStore, []),
            ...mapWritableState(useIndexStore, ["loading","loadingWithService","dialog","servicefromquote","component","form"]),
            ...mapWritableState(useContactUsStore, ["loading"])
        },
        methods: {
            ...mapActions(useIndexStore, ['back']),
            backFromContactUsToRequestQuote() {
                this.back(true);
                useIndexStore().servicefromquote = '';
                useIndexStore().form.menuNavigationId = '';
                useIndexStore().currentTabComponent = this.form.menuOrder[this.form.menuOrder.length - 1];
                useIndexStore().component = "quote";
            }
        },
    };
</script>