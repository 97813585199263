<template>
    <div>
        <v-row :class="!hideStore ? '' : 'text-center'">
                <v-col :cols="$vuetify.breakpoint.xs || hideStore ? '12' : '6'">
                    <v-btn :disabled="disableQuote" :block="hideStore ? true : $vuetify.breakpoint.xs ? true : false" @click="$emit('clickQuote')" height="44px" width="100%" :color="!form ? 'error' : 'secondary'" class="rounded-lg" :class="!form ? 'red-outlined' : ''">
                        <icon svg="buttonquote"/>
                        <span class="pl-4">{{ i18n.t('getAQuote') }}</span>
                        <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
                    </v-btn>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.xs ? '12' : '6'">
                    <v-btn v-if="!hideStore" :disabled="disabledStore ? true : false" :block="hideStore ? true : $vuetify.breakpoint.xs ? true : false" @click="$emit('clickStore')" height="44px" width="100%" color="success" class="rounded-lg">
                        <icon svg="shoppingcart"/>
                        <span class="pl-4">{{ i18n.t("goToStore") }}</span>
                        <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
                    </v-btn>
                </v-col>
        </v-row>
    </div>
</template>

<script>
import icon from "../requestquote/utility/SvgLoader"
import i18n from '@/i18n'
// import { mapActions } from 'pinia'
// import { useIndexStore } from '@/store'
    export default {
        name: "ButtonsView",
        components:{icon},
        data(){
            return{
                i18n:i18n
            }
        },
        props:['clickQuote','clickStore','disabledStore','hideStore','disableQuote','form','loading'],
        methods:{
            // ...mapActions(useIndexStore,['haveServicesForThisType']),
        },

    }
</script>

<style lang="scss" scoped>
/* for number input */
::v-deep .red-outlined {
  border-color: red !important;
}
</style>