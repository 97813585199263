import Vue from 'vue'
import App from './App.vue'
import { createPinia, PiniaVuePlugin } from 'pinia'
import vuetify from './plugins/vuetify'
import i18n from './i18n'
import vueCustomElement from 'vue-custom-element'

Vue.config.productionTip = false

Vue.use(PiniaVuePlugin)
Vue.use(vueCustomElement)

const pinia = createPinia()

App.vuetify = vuetify;
App.i18n = i18n;
App.pinia = pinia;

Vue.customElement('embed-widget', App)

// new Vue({
//   vuetify,
//   i18n,
//   store,
//   pinia,
//   render: h => h(App)
// }).$mount('#app')
