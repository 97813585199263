import { defineStore } from "pinia";
import reset from "@/plugins/reset";

import i18n from "@/i18n";
// import { load } from 'recaptcha-v3';
import axios from "axios";
import {useIndexStore} from "./index";
import {useUserStore} from "@/store/user";

import googleApi from "@/plugins/google"
export const useContactUsStore = defineStore("ContactUs", {
  state: () => ({
    form: useUserStore().form,
    search: "",
    lightCU: false,
    canadaCU: "",
    langCU: "",
    assigntolocationCU: 0,
    serviceCU: "",
    servicesCU: "",
    bulkitempickupCU: '',
    requestpickupCU: false,
    recycleplusCU: false,
    faqCU: '',
    pickupscheduleurlCU:'',
    payCU: "",
    stopserviceCU:"",
    i18n: i18n,
    servicefromquote: "",
    selectedId: null,
    valid: true,
    loading: false,
    standardDialog: false,
    questionDialog: false,
    minDate: new Date(
      new Date().getFullYear() - 1,
      new Date().getMonth(),
      new Date().getDate()
    )
      .toISOString()
      .slice(0, 10),
    maxDate: new Date(
      new Date().getFullYear() + 1,
      new Date().getMonth(),
      new Date().getDate()
    )
      .toISOString()
      .slice(0, 10),
    view: {
      name: "",
      index: true,
      contactUs: false,
      landfill: false,
      bulkItemPickup: false,
      missedPickup: false,
      brokenContainer: false,
      deliveryIssue: false,
      iAmMoving: false,
      transferService: false,
      cancelService: false,
      stopService: false,
      serviceUpgrade: false,
      vacation: false,
      driverReview: false,
      requestQuote: false,
      startService: false,
      payMyBill: false,
      address: false,
      prediction: false,
      main: false,
    },
    apiValues: {
      sessionID: "",
      apiResponse: "",
      questionsName: [],
      questionsEmail: [],
      questionsPhone: [],
      answerName: "",
      answerPhone: "",
      answerEmail: "",
    },
    textDialog: {
      title: "",
      feedback: "",
      googleRating: "",
      buttonLocations: false,
      buttonGoogleRating: false,
    },

    // Customer Services menu list
    communications: [
      {
        id: 0,
        title: 'serviceOptions.missedPickup',
        description: 'description.missedPickup',
        view: "MissedPickup",
        viewform: "missedPickup",
        icon: "missedpickup",
        keywords: '',
      },
      {
        id: 1,
        title: 'serviceOptions.bulkItemPickup',
        description: 'description.bulkItemPickup',
        view: "BulkItemPickup",
        viewform: "bulkItemPickup",
        icon: "sofa",
        keywords: 'keywords.bulkItemPickup',
      },
      {
        id: 2,
        title: 'serviceOptions.brokenContainer',
        description: 'description.brokenContainer',
        view: "BrokenLostContainer",
        viewform: "brokenContainer",
        icon: "brokencontainer",
        keywords: '',
      },
      {
        id: 3,
        title: 'serviceOptions.vacation',
        description: 'description.vacation',
        view: "SeasonVacationHold",
        viewform: "vacation",
        icon: "seasonvacation",
        keywords: '',
      },
      // {
      //   id: 3,
      //   title: "Extra Pickup",
      //   description:
      //     "Request an extra pickup if one container is not enough.",
      //   view: "ExtraPickup",
      //   viewform: "extraPickup",
      //   icon: "extrapickup",
      //    keywords: '',
      // },

      // {
      //   id: 4,
      //   title: 'serviceOptions.deliveryIssue',
      //   description: 'description.deliveryIssue',
      //   view: "DeliveryIssue",
      //   viewform: "deliveryIssue",
      //   icon: "truck",
      //   keywords: '',
      // },
    ],
    servicesOther: [
      {
        id: 4,
        title: 'serviceOptions.payMyBill',
        description: 'description.payMyBill',
        view: "PayMyBill",
        viewform: "payMyBill",
        icon: "paymybill",
        type: 'Residential',
        url:'N/A',
        keywords: '',
      },

      {
        id: 5,
        title: 'serviceOptions.transferService',
        description: 'description.transferService',
        view: "TransferService",
        viewform: "transferService",
        icon: "transferservice",
        keywords: '',
      },
      {
        id: 6,
        title: 'serviceOptions.serviceUpgrade',
        description: 'description.serviceUpgrade',
        view: "ServiceUpgrade",
        viewform: "serviceUpgrade",
        icon: "serviceupgrade",
        keywords: '',
      },
      {
        id: 7,
        title: 'serviceOptions.iAmMoving',
        description: 'description.iAmMoving',
        view: "IAmMoving",
        viewform: "iAmMoving",
        icon: "iammoving",
        keywords: '',
      },
      {
        id: 14,
        title: 'serviceOptions.cancelService',
        description: 'description.cancelService',
        view: "CancelService",
        viewform: "cancelService",
        icon: "xtask",
        keywords: '',
      },
      // {
      //   id: 16,
      //   title: 'serviceOptions.recyclePlus',
      //   description: 'description.recyclePlus',
      //   view: "RecyclePlus",
      //   viewform: "recyclePlus",
      //   icon: "clock",
      //   keywords: '',
      // },
    ],
    otherServices: [
      {
        id: 8,
        title: 'serviceOptions.generalInquiry',
        description: 'description.generalInquiry',
        view: "GeneralInquiry",
        viewform: "contactUs",
        icon: "generalinquiry",
        type: 'Residential',
        keywords: '',
      },
      {
        id: 9,
        title: 'serviceOptions.startService',
        description: 'description.startService',
        view: "StartService",
        viewform: "startService",
        icon: "startservice",
        keywords: '',
      },
      {
        id: 10,
        title: 'serviceOptions.requestQuote',
        description: 'description.requestQuote',
        view: "RequestQuote",
        viewform: "requestQuote",
        icon: "requestquote",
        url:'N/A',
        keywords: '',
      },
      // {
      //   id: 13,
      //   title: 'serviceOptions.requestPickup',
      //   description: 'description.requestPickup',
      //   view: "RequestPickup",
      //   viewform: "requestPickup",
      //   icon: "chatbubble",
      //   url:'N/A',
      //   keywords: '',
      // },
      {
        id: 11,
        title: 'serviceOptions.driverReview',
        description: "description.driverReview",
        view: "AppreciateMyDriver",
        viewform: "driverReview",
        icon: "appreciatemydriver",
        type: 'Residential',
        keywords: '',
      },
      // {
      //   id: 15,
      //   title: "Pickup Schedule",
      //   description: "Check the pickup schedule at your address.",
      //   view: "PickupSchedule",
      //   viewform: 'pickupSchedule',
      //   icon: "clockfilled",
      //   url: '',
      //    keywords: '',
      // },
      {
        id: 12,
        title: "FAQs",
        description: 'description.faq',
        view: "FaqsView",
        viewform: 'faq',
        icon: "question",
        url: '',
        keywords: '',
      },
    ],
  }),

  getters: {
    // ********************
    //  google apis
    // ********************
    google: () => useIndexStore().google,
    // ********************
    //  i18n
    // ********************
    serviceItems() {
        let serviceList = [
                {name:this.i18n.t('serviceOptions.requestPickup'),action:'requestPickup'},
                {name:this.i18n.t('serviceOptions.recyclePlus'),action:'recyclePlus'},
                {name:this.i18n.t('serviceOptions.bulkItemPickup'),action:'bulkItemPickup'},
                {name:this.i18n.t('serviceOptions.startService'),action:'startService'},
                {name:this.i18n.t('serviceOptions.brokenContainer'),action:'brokenContainer'},
                {name:this.i18n.t('serviceOptions.transferService'),action:'transferService'},
                {name:this.i18n.t('serviceOptions.missedPickup'),action:'missedPickup'},
                {name:this.i18n.t('serviceOptions.driverReview'),action:'driverReview'},
                {name:this.i18n.t('serviceOptions.iAmMoving'),action:'iAmMoving'},
                {name:this.i18n.t('serviceOptions.serviceUpgrade'),action:'serviceUpgrade'},
                {name:this.i18n.t('serviceOptions.cancelService'),action:'cancelService'},
                {name:this.i18n.t('serviceOptions.stopService'),action:'stopService'},
                {name:this.i18n.t('serviceOptions.deliveryIssue'),action:'deliveryIssue'},
                {name:this.i18n.t('serviceOptions.generalInquiry'),action:'contactUs'},
                {name:this.i18n.t('serviceOptions.requestQuote'),action:'requestQuote'},
                {name:this.i18n.t('serviceOptions.vacation'),action:'vacation'},
                {name:this.i18n.t('serviceOptions.payMyBill'),action:'payMyBill'},
            ];
        if(!this.servicesCU.length){
            return serviceList;
        }else{
            this.communications = this.communications.filter(service => !this.servicesCU.includes(this.i18n.t(service.title)));
            this.servicesOther = this.servicesOther.filter(service => !this.servicesCU.includes(this.i18n.t(service.title)));
            this.otherServices = this.otherServices.filter(service => !this.servicesCU.includes(this.i18n.t(service.title)));
            return serviceList.filter(service => !this.servicesCU.includes(service.name));
        }
    },
    generalIssueItems() {
        let serviceList = [
                this.i18n.t('serviceOptions.requestPickup'),
                this.i18n.t('serviceOptions.recyclePlus'),
                this.i18n.t('serviceOptions.bulkItemPickup'),
                this.i18n.t('serviceOptions.vacation'),
                this.i18n.t('serviceOptions.brokenContainer'),
                this.i18n.t('serviceOptions.transferService'),
                this.i18n.t('serviceOptions.missedPickup'),
                this.i18n.t('serviceOptions.iAmMoving'),
                this.i18n.t('serviceOptions.serviceUpgrade'),
                this.i18n.t('serviceOptions.deliveryIssue'),
                this.i18n.t('serviceOptions.requestQuote'),
                this.i18n.t('serviceOptions.startService'),
                this.i18n.t('serviceOptions.other'),
            ];
        if(!this.servicesCU.length){
            return serviceList;
        }else{
            this.communications = this.communications.filter(service => !this.servicesCU.includes(service.title));
            this.servicesOther = this.servicesOther.filter(service => !this.servicesCU.includes(service.title));
            this.otherServices = this.otherServices.filter(service => !this.servicesCU.includes(service.title));
            return serviceList.filter(service => !this.servicesCU.includes(service));
        }
    },
    missedPickupItems() {
        return [
            this.i18n.t('items.missedPickup.out'),
            this.i18n.t('items.missedPickup.notOut'),
        ];
    },
    brokenContainerIssueItems() {
        return [
            this.i18n.t('items.brokenContainer.issue.broken'),
            this.i18n.t('items.brokenContainer.issue.lost'),
        ];
    },
    brokenContainerPartItems() {
        return [
            this.i18n.t('items.brokenContainer.part.lid'),
            this.i18n.t('items.brokenContainer.part.wheels'),
            this.i18n.t('items.brokenContainer.part.container'),
        ];
    },
    cartTypeItems() {
        return [
            this.i18n.t('items.cartType.trash'),
            this.i18n.t('items.cartType.recycling'),
            this.i18n.t('items.cartType.yardDebris'),
            this.i18n.t('items.cartType.organics'),
        ];
    },
    deliveryIssueItems() {
        return [
            this.i18n.t('items.deliveryIssue.location'),
            this.i18n.t('items.deliveryIssue.container'),
        ];
    },
    upgradeServiceItems() {
        return [
            { header: this.i18n.t('items.upgradeService.trash') },
            { name: this.i18n.t('items.upgradeService.64gallon'), group: this.i18n.t('items.upgradeService.trash') },
            { name: this.i18n.t('items.upgradeService.96gallon'), group: this.i18n.t('items.upgradeService.trash') },
            { name: this.i18n.t('items.upgradeService.additional96gallon'), group: this.i18n.t('items.upgradeService.trash') },
            { divider: true },
            { header: this.i18n.t('items.upgradeService.recycling') },
            { name: this.i18n.t('items.upgradeService.addRecycling'), group: this.i18n.t('items.upgradeService.recycling') },
            { divider: true },
            { header: this.i18n.t('items.upgradeService.yardDebris') },
            { name: this.i18n.t('items.upgradeService.addYardDebris'), group: this.i18n.t('items.upgradeService.yardDebris') }
        ];
    },
    stopServiceItems() {
        return [
            this.i18n.t('items.stopService.notNeeded'),
            this.i18n.t('items.stopService.moved'),
            this.i18n.t('items.stopService.temporary'),
        ];
    },
    // ********************************
    //  Custom form rules
    // ********************************
    formRules() {
        return {
            nameRules: [
                v => !!v || this.i18n.t('formRules.name.required'),
                v => (v && v.length >= 2 && v.length <= 100) || this.i18n.t('formRules.name.nbCaracters'),
                v => /^([A-Za-zàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]{1})+([[A-Za-zàâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]+[,.]?[ ]?|[A-Za-z]+['-àâäèéêëîïôœùûüÿçÀÂÄÈÉÊËÎÏÔŒÙÛÜŸÇ]]?)+$/.test(v) || this.i18n.t('formRules.name.invalid'),
            ],
            searchRules: [
                v => (v.length <= 300) || this.i18n.t('formRules.search.nbCaracters'),
            ],
            addressRules: [
                v => !!v || this.i18n.t('formRules.address.required'),
                v => !!v && v.length >= 2 && v.length <= 300 || this.i18n.t('formRules.address.nbCaracters'),
            ],
            cityRules: [
                v => !!v || this.i18n.t('formRules.city.required'),
                v => (v && v.length >= 2 && v.length <= 100) || this.i18n.t('formRules.city.nbCaracters'),
            ],
            stateRules: [
                v => !!v || this.i18n.t('formRules.state.required'),
                v => (v && v.length >= 2 && v.length <= 100) || this.i18n.t('formRules.state.nbCaracters'),
            ],
            zipRules: [
                v => !!v || this.i18n.t('formRules.zip.required'),
                v => (v && v.length >= 2 && v.length <= 100) || this.i18n.t('formRules.zip.nbCaracters'),
            ],
            phoneRules: [
                v => !!v || this.i18n.t('formRules.phone.required'),
                v => (v && v.length >= 2 && v.length <= 100) || this.i18n.t('formRules.phone.nbCaracters'),
                v => /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/.test(v) || this.i18n.t('formRules.phone.invalid'),
            ],
            emailRules: [
                v => !!v || this.i18n.t('formRules.email.required'),
                v => /.+@.+\..+/.test(v) || this.i18n.t('formRules.email.invalid'),
            ],
            acceptConditions: [
                v => !!v || this.i18n.t('formRules.acceptConditions'),
            ],
            descriptionRules: [
                v => (v.length <= 600) || this.i18n.t('formRules.description'),
            ],
            requiredRules: [
                v => !!v || this.i18n.t('formRules.required'),
            ],
            requiredDescriptionRules: [
                v => !!v || this.i18n.t('formRules.required'),
                v => (v.length <= 600) || this.i18n.t('formRules.description'),
            ],
            dateRules:[
                v => !!v || this.i18n.t('formRules.date.required'),
                v => new Date(v) >= new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()) || this.i18n.t('formRules.date.invalid'),//minDate
                v => new Date(v) <= new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()) || this.i18n.t('formRules.date.invalid'),//maxDate
                v => /^(0[1-9]|1[0-2])\/(0[1-9]|1\d|2\d|3[01])\/(19|20)\d{2}$/.test(v) || this.i18n.t('formRules.date.invalid'),
            ],
            dateCanadaRules:[
                v => !!v || this.i18n.t('formRules.date.required'),
                v => new Date(new Date(v).getFullYear(), new Date(v).getMonth(), new Date().getDate()) >= new Date(new Date().getFullYear() - 1, new Date().getMonth(), new Date().getDate()) || this.i18n.t('formRules.date.invalid'),//minDate
                v => new Date(new Date(v).getFullYear(), new Date(v).getMonth(), new Date().getDate()) <= new Date(new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate()) || this.i18n.t('formRules.date.invalid'),//maxDate
                v => /^([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))$/.test(v) || this.i18n.t('formRules.date.invalid'),
            ],
            resumeRules: [
                v => !v || v.size < 4000000 || this.i18n.t('formRules.resume.invalid'),
            ],
        }
    },
  },

  actions: {
    // *******************************************************
    // Google functions
    // *******************************************************
    //
    // Check address and push multiple addresses in a array
    verifyGoogleAddress(inputAutoC) {
      this.loading = true;

      // bind address key to a variable depending on the parameter entered
      let formInputAddress = inputAutoC =='addressTo'?this.form.addressTo:this.form.address;

      // return promises response
      googleApi.autocompleteSuggestions(this.google, formInputAddress).then((result) => {

        // check promises
        if (result.status == 'OK'){

            if (result.predictions.length === 1) {

              this.getAddressPrediction(this.google, result.predictions[0].description,inputAutoC);

            } else if (result.predictions[0].description === this.form.address) {

              this.getAddressPrediction(this.google,result.predictions[0].description,inputAutoC);

            } else {

              // check parameter is not set to addressTo
              if(inputAutoC !=='addressTo'){
                var currentAddress = {

                  description: "other",
                  structured_formatting: {
                    main_text: this.i18n.t("continueWithThisAddress"),
                    secondary_text: formInputAddress,
                  },

                }

                // Push all the closest address that exist when we enter a string in the field.
                result.predictions.push(currentAddress);
                this.form.predictions = result.predictions;
                this.viewStep("prediction");
              }

              this.loading = false;
            }

        } else {

          // If response status is not equals to OK
          // & parameter (inputAutoC) not equal to address,
          // Set "addressTo" to empty
          if(inputAutoC!=='address'){

            this.form.addressTo = '';

          }else{

            // Show view of main step (first page with the address)
            this.viewStep('main');
            this.loading = false;
          }
          this.loading = false;
        }

      })
      .catch((error) => {
        console.error("Error getting address suggestions:", error);
        this.loading = false;
      });
    },

    // Get the selected address from selects/options list and bind them to their variables
    getAddressPrediction(google,prediction,inputAutoC){
      this.loading = true;


      if (prediction !== "other") {

        googleApi.selectedPrediction(google, prediction).then((result) => {

            // check promises
            if (result.status == "OK") {

              if(inputAutoC!=='addressTo'){

              // if address is found, split address in parts
              // and bind them to their variables
              this.form.streetNumber = result.streetNumber;
              this.form.route = result.route;
              this.form.street = result.street;
              this.form.city = result.city;
              this.form.state = result.state;
              this.form.zip = result.zip;
              this.form.country = result.country;
              this.form.lat = result.lat;
              this.form.lng = result.lng;
              this.form.address = result.address;

              // Show main view for the form
              this.viewStep("main");
              this.loading = false;

              } else {

                // If input parameter is not addressTo OR is address,
                // bind result to the right variable
                this.form.addressTo = result.address;
                this.verificationByAddress();

              }
            }else{
              this.loading = false;
            }

        });
      } else {

        // if address is prediction is "other"
        // bind street and route value from address
        // Show main view for the form
        this.viewStep("main");
        this.loading = false;

      }
    },

    // *******************************************************
    // Cookies functions
    // *******************************************************
    //
    // Set cookie for remember informations
    setCookie(cname, cvalue, exdays) {

      var d = new Date();

      // Set the date (timestamp) of the cookie)
      d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
      var expires = "expires=" + d.toUTCString();

      // create cookie info
      cvalue = {
        name: cvalue.name,
        address: cvalue.address,
        city: cvalue.city,
        state: cvalue.state,
        zip: cvalue.zip,
        email: cvalue.email,
        phone: cvalue.phone,
        checkbox: cvalue.checkbox,
      };

      // add cookie to brower
      document.cookie =
        cname + "=" + JSON.stringify(cvalue) + ";" + expires + ";path=/";
    },

    // Get cookie values and bind to form
    getCookie(cname) {

      var name = cname + "=";
      var decodedCookie = decodeURIComponent(document.cookie);
      var ca = decodedCookie.split(";");
      for (var i = 0; i < ca.length; i++) {
        var c = ca[i];
        while (c.charAt(0) == " ") {
          c = c.substring(1);
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length);
        }
      }
      return "";
    },

    // *******************************************************
    // API Call functions
    // *******************************************************
    //
    // Trigger API call from PayNowSearch
    // API call will verify the address and send email to the customer
    // and district for submitting the form.
    async verificationByAddress(){
        var vm = this;
        // Check if landfill view is not shown
        if(!this.view.landfill){
            this.loading = true;

            // Check if checkbox of cookies is checked
            if(vm.form.checkbox){
              vm.setCookie('SiteSettings', vm.form, 14);
            }else{
              vm.setCookie('SiteSettings', vm.form, -1);
            }
            const file = await this.convertFileUpload(this.form.fileUpload);
            axios.post(process.env.VUE_APP_RESTURL+"services/apexrest/PayNowSearch", {
                name: this.form.firstName + ' ' + this.form.lastName,
                address: this.form.route,
                street: this.form.streetNumber,
                city: this.form.city,
                state: this.form.state,
                zip: this.form.zip,
                country: this.form.country,
                email: this.form.email?.toLowerCase(),
                phone: this.form.phone,
                description: this.form.description,
                type: this.form.type,
                rollOffBox: this.form.rollOffBox?this.i18n.t('universalFormContent.yes'):this.i18n.t('universalFormContent.no'),
                addressTo: this.form.addressTo,
                datePickup: this.form.datePickup,
                pickupBefore: this.form.pickupBefore,
                pickupAfter: this.form.pickupAfter,
                containerOut: this.form.containerOut,
                upgradeService: this.form.upgradeService?this.form.upgradeService.join(", "):'',
                driverRating: this.form.driverRating,
                driverTime: this.form.driverTime,
                fileUpload: file,
                fileUploadType: this.form.fileUpload?this.form.fileUpload.type:'',
                fileUploadName: this.form.fileUpload?this.form.fileUpload.name:'',
                accountNumber: this.apiValues.accountNumber,
                source: window.location.href,
                lang: this.langCU,
                method: 'getVerificationLite'
            }).then(function (response) {
                if (response.status===200) {
                    vm.apiValues.apiResponse = response.data;
                    var res = JSON.parse(response.data);
                    if (res.accountFound) {
                        vm.apiValues.questionsName = res.questions.name;
                        vm.apiValues.questionsEmail = res.questions.email;
                        vm.apiValues.questionsPhone = res.questions.phone;
                        vm.apiValues.sessionID = res.sessionID;
                        vm.questionDialog = true;
                        vm.loading = false;
                    }else if(res.districtNumber||(res.closestDistrictInfo&&res.closestDistrictInfo.District)) {
                        vm.sendEmailCustomerAndDistrict(true, true, true);// true false true
                    }else{
                        vm.sendEmailCustomerAndDistrict(true, false, false); // false, false, false
                    }
                }else{
                    vm.loading = false;
                }
            }).catch(function (error) {
                vm.loading = false;
                vm.sendEmailCustomerAndDistrict(true, false, false, 'Request Error'); // customerAction(false, false, 'read'); vm.sendEmailCustomerAndDistrict(true, true, 'read');
                console.log(error);
            });
        }
        if(this.view.landfill){
            this.loading = true;
            const campaignURL = JSON.parse(sessionStorage.getItem("campaignURL"));
            if (campaignURL) {
                vm.utmSource = campaignURL["utmSource"];
                vm.utmCampaign = campaignURL["utmCampaign"];
                vm.utmMedium = campaignURL["utmMedium"];
                vm.utmTerm = campaignURL["utmTerm"]||campaignURL["etid"];
                vm.utmReferrer = campaignURL["referrer"];
                vm.gclid = campaignURL["gclid"];
                vm.fclid = campaignURL["fclid"];
                vm.promoCode = campaignURL["promoCode"];
            }
            if(vm.form.checkbox){
                vm.setCookie('SiteSettings', vm.form, 14);
            }else{
                vm.setCookie('SiteSettings', vm.form, -1);
            }
            axios.post(process.env.VUE_APP_RESTURL+"services/apexrest/PayNowSearch", {
                name: this.form.name,
                address: this.form.route,
                street: this.form.streetNumber,
                city: this.form.city,
                state: this.form.state,
                zip: this.form.zip,
                country: this.form.country,
                lat: this.form.lat,
                lng: this.form.lng,
                email: this.form.email?.toLowerCase(),
                phone: this.form.phone,
                description: this.form.description,
                type: this.form.type,
                source: window.location.href,
                templateName: 'Contact Us',
                lang: this.langCU,
                location: this.assigntolocation,
                utmSource: this.utmSource || '',
                utmCampaign: this.utmCampaign || '',
                utmMedium: this.utmMedium || '',
                utmTerm: this.utmTerm || '',
                utmReferrer: this.utmReferrer || '',
                gclid: this.gclid || '',
                method: 'sendLandfillCase'
            }).then(function (response) {
                if(response.status === 200) {
                    var res = JSON.parse(response.data);
                    if(res[0].code!==202||res[1].code!==202||(res[2]&&res[2].code!==200)){
                        console.log(res[0].message,res[1].message,res[2]?res[2].message:'');
                    }
                    vm.customerAction(true, true);
                } else {
                    vm.customerAction(false, false);
                }
            }).catch(function (error) {
                vm.customerAction(false, false, 'read');
                console.log(error);
            });
        }
    },

    async convertFileUpload(image) {
      if (image) {
        const reader = new FileReader();
        return new Promise((resolve) => {
          reader.addEventListener(
            "load",
            () => {
              resolve((window.result = reader.result.split(",").pop()));
            },
            false
          );
          reader.readAsDataURL(image);
        });
      }
    },

    modalGenerator(version) {
      if (version === "requestSent") {
        const APIRESPONSE = JSON.parse(this.apiValues.apiResponse);
        this.textDialog.title = this.i18n.t("modals.requestSent.title");
        this.textDialog.feedback =
          APIRESPONSE.Message === this.i18n.t("modals.invalidAnswers.title")
            ? this.i18n.t("modals.requestSent.feedbackAccount")
            : this.i18n.t("modals.requestSent.feedback");
        this.textDialog.buttonLocations = false;
        // if(this.view.driverReview && this.form.driverRating >= 3.5){
        //     this.textDialog.googleRating = this.i18n.t('modals.requestSent.googleRating');
        //     this.textDialog.buttonGoogleRating = true;
        // }
      } else if (version === "invalidAnswers") {
        this.textDialog.title = this.i18n.t("modals.invalidAnswers.title");
        this.textDialog.feedback = this.i18n.t(
          "modals.invalidAnswers.feedback"
        );
        this.textDialog.buttonLocations = true;
      } else if (version === "noDistrictFound") {
        this.textDialog.title = this.i18n.t("modals.noDistrictFound.title");
        this.textDialog.feedback = this.i18n.t(
          "modals.noDistrictFound.feedback"
        );
        this.textDialog.buttonLocations = true;
      } else if (version === "districtFound") {
        this.textDialog.title = this.i18n.t("modals.districtFound.title");
        this.textDialog.feedback = this.i18n.t("modals.districtFound.feedback");
        this.textDialog.buttonLocations = false;
      } else if (version === "timeOutError") {
        this.textDialog.title = this.i18n.t("modals.timeOutError.title");
        this.textDialog.feedback = this.i18n.t("modals.timeOutError.feedback");
        this.textDialog.buttonLocations = false;
      } else if (version === "readError") {
        this.textDialog.title = this.i18n.t("modals.readError.title");
        this.textDialog.feedback = this.i18n.t("modals.readError.feedback");
        this.textDialog.buttonLocations = false;
      }
      this.standardDialog = true;
    },

    customerAction(validUser, validAnswers, error, apiResponse) {

      if (validUser && validAnswers && !error) {
        this.modalGenerator("requestSent");
        this.questionDialog = false;
      } else if (validUser && !validAnswers && !error) {
        this.resetDialog();
        this.modalGenerator("invalidAnswers");
      } else if (!validUser && !validAnswers && !error) {
        this.modalGenerator("noDistrictFound");
      } else if (!validUser && validAnswers && !error) {
        this.modalGenerator("districtFound", apiResponse);
      } else if (error === "time") {
        this.modalGenerator("timeOutError");
        this.questionDialog = false;
      } else if (error === "read" || error === "Request Error") {
        this.modalGenerator("readError");
        this.questionDialog = false;
      }
    },

    async sendEmailCustomerAndDistrict( sendCase, validUser, validAnswers, error ) {

      let vm = this;
      if (!sendCase) {
        vm.sendCase(validUser, validAnswers);
      } else {
        const file = await vm.convertFileUpload(vm.form.fileUpload);
        axios
          .post(
            process.env.VUE_APP_RESTURL + "services/apexrest/PayNowSearch",
            {
              name: vm.form.firstName + ' ' + vm.form.lastName ,
              address: vm.form.route,
              street: vm.form.streetNumber,
              city: vm.form.city,
              state: vm.form.state,
              zip: vm.form.zip,
              country: vm.form.country,
              email: vm.form.email?.toLowerCase(),
              phone: vm.form.phone,
              description: vm.form.description,
              type: vm.form.type,
              rollOffBox: vm.form.rollOffBox
                ? vm.i18n.t("universalFormContent.yes")
                : vm.i18n.t("universalFormContent.no"),
              addressTo: vm.form.addressTo,
              datePickup: vm.form.datePickup
                ? useIndexStore().formatDate(vm.form.datePickup, vm.canadaCU ? "en_CA" : "")
                : "",
              pickupBefore: vm.form.pickupBefore
                ? useIndexStore().formatDate(
                    vm.form.pickupBefore,
                    vm.canadaCU ? "en_CA" : ""
                  )
                : "",
              pickupAfter: vm.form.pickupAfter
                ? useIndexStore().formatDate(vm.form.pickupAfter, vm.canadaCU ? "en_CA" : "")
                : "",
              stopRequestStatus: vm.form.stopRequestStatus,
              containerOut: vm.form.containerOut,
              cartType: vm.form.cartType,
              containerIssue: vm.form.containerIssue,
              upgradeService: vm.form.upgradeService
                ? vm.form.upgradeService.join(", ")
                : "",
              driverRating: vm.form.driverRating,
              driverTime: vm.form.driverTime
                ? useIndexStore().formatDate(vm.form.driverTime, vm.canadaCU ? "en_CA" : "")
                : "",
              fileUpload: file,
              fileUploadType: vm.form.fileUpload ? vm.form.fileUpload.type : "",
              fileUploadName: vm.form.fileUpload ? vm.form.fileUpload.name : "",
              apiResponse:
                error === "Request Error" ? "{}" : vm.apiValues.apiResponse,
              templateName: vm.viewFormLabel(),
              lang: vm.langCU,
              location: vm.assigntolocationCU,
              method: "sendMailToDistrictAndUser",
            }
          )
          .then(function (response) {
            if (response.status === 200) {
              var res = JSON.parse(response.data);
              if (
                res[0].code !== 202 ||
                res[1].code !== 202 ||
                (res[2] && res[2].code !== 200)
              ) {
                console.log(
                  res[0].message,
                  res[1].message,
                  res[2] ? res[2].message : ""
                );
              }
              vm.sendCase(validUser, validAnswers, error);
            } else {
              vm.sendCase(validUser, validAnswers, error);
            }
          })
          .catch(function (error) {
            vm.sendCase(validUser, validAnswers, "read");
            console.log(error);
          });
      }
    },

    sendCase(validUser, validAnswers, error) {
      var vm = this;
      const campaignURL = JSON.parse(sessionStorage.getItem("campaignURL"));
      if (campaignURL) {
          vm.utmSource = campaignURL["utmSource"];
          vm.utmCampaign = campaignURL["utmCampaign"];
          vm.utmMedium = campaignURL["utmMedium"];
          vm.utmTerm = campaignURL["utmTerm"]||campaignURL["etid"];
          vm.utmReferrer = campaignURL["referrer"];
          vm.gclid = campaignURL["gclid"];
          vm.fclid = campaignURL["fclid"];
          vm.promoCode = campaignURL["promoCode"];
      }
      let jsonParseAPI = vm.apiValues.apiResponse
        ? JSON.parse(vm.apiValues.apiResponse)
        : "";
      let fields = {
        orgid: process.env.VUE_APP_ORGANIZATIONID,
        "00N0Z00000H34lZ": "No Robot",
        "00N0Z00000H34m3": window.location.href,
        "00Ni000000FrdHP": this.form.street + " " + this.form.route,
        "00Ni000000FrdHK": this.form.city,
        "00Ni000000FrdHO": this.form.state,
        "00Ni000000FrdHN": this.form.zip,
        "00Ni000000FrdHL": this.form.country,
        "00Ni000000FrdHR": this.i18n.t("sendCase.fr"),
        "00N0Z00000H3KbB": "Contact Us From Global Component",
        [process.env.VUE_APP_GCLID]: this.gclid ? this.gclid : "",
        [process.env.VUE_APP_UTM_CAMPAIGN]: this.utmCampaign ? this.utmCampaign : "",
        [process.env.VUE_APP_UTM_MEDIUM]: this.utmMedium ? this.utmMedium : "",
        [process.env.VUE_APP_UTM_REFERRER]: this.utmReferrer ? this.utmReferrer : "",
        [process.env.VUE_APP_UTM_SOURCE]: this.utmSource ? this.utmSource : "",
        [process.env.VUE_APP_UTM_TERM]: this.utmTerm ? this.utmTerm : "",
        [process.env.VUE_APP_DISTRICTCODE]:
          this.assigntolocationCU !== 0
            ? this.assigntolocationCU
            : jsonParseAPI.closestDistrictInfo &&
              jsonParseAPI.closestDistrictInfo.District
            ? jsonParseAPI.closestDistrictInfo.District
            : jsonParseAPI.districtNumber
            ? jsonParseAPI.districtNumber
            : "",
        [process.env.VUE_APP_ACCOUNTNUMBER]: jsonParseAPI.accountNumber
          ? jsonParseAPI.accountNumber
          : "",
        [process.env.VUE_APP_WEBTYPE]: this.form.type ? this.form.type : "",
        [process.env.VUE_APP_REQUESTERROR]: error === "Request Error" ? 1 : 0,
        [process.env.VUE_APP_EVENTDATE]:
          (this.view.driverReview && this.form.driverTime) ||
          ((this.view.missedPickup ||
            this.view.brokenContainer ||
            this.view.deliveryIssue ||
            this.view.requestPickup) &&
            this.form.datePickup)
            ? useIndexStore().formatDate(
                this.view.driverReview
                  ? this.form.driverTime
                  : this.form.datePickup
              )
            : "", //Event_Date__c
        [process.env.VUE_APP_EVENTDETAILS]: this.form.containerOut
          ? this.form.containerOut
          : "", //Event_Details__c
        [process.env.VUE_APP_TYPEOFCART]: this.form.cartType
          ? this.form.cartType
          : "", //Type_of_Cart__c
        [process.env.VUE_APP_CONTAINERISSUE]: this.form.containerIssue
          ? this.form.containerIssue
          : "", //Container_Issue__c
        [process.env.VUE_APP_ORDERAROLLOFFBOX]: this.form.rollOffBox
          ? this.form.rollOffBox
          : "", //Order_a_Roll_Off_Box_Dumpster__c
        [process.env.VUE_APP_SERVICEUPGRADES]: this.form.upgradeService
          ? this.form.upgradeService
          : "", //Service_Upgrades__c
        [process.env.VUE_APP_DRIVERRATING]:
          this.view.driverReview && this.form.driverRating
            ? this.form.driverRating
            : "", //Driver_Rating__c
        [process.env.VUE_APP_STARTDATE]: this.form.pickupAfter
          ? useIndexStore().formatDate(this.form.pickupAfter)
          : "", //Start_Date__c
        [process.env.VUE_APP_STOPDATE]:
          ((this.view.iAmMoving || this.view.transferService) &&
            this.form.datePickup) ||
          this.form.pickupBefore
            ? useIndexStore().formatDate(
                this.view.iAmMoving || this.view.transferService
                  ? this.form.datePickup
                  : this.form.pickupBefore
              )
            : "", //Stop_Date__c
        [process.env.VUE_APP_STOPREQUESTSTATUS]: this.form.stopRequestStatus
          ? this.form.stopRequestStatus
          : "", //Stop_Request_Status__c
        [process.env.VUE_APP_NEWADDRESS]: this.form.addressTo
          ? this.form.addressTo
          : "", //New_Address__c
        external: 1,
        name: this.form.firstName + ' ' + this.form.lastName,
        email: this.form.email?.toLowerCase(),
        phone: this.form.phone,
        subject: this.viewFormLabel(),
        description: this.form.description,
        origin: "Embed",
      };
      let customHiddenIframeName = "CU_API";
      if (!document.getElementById(customHiddenIframeName)) {
        let theiFrame = document.createElement("iframe");
        theiFrame.id = customHiddenIframeName;
        theiFrame.name = customHiddenIframeName;
        theiFrame.src = "about:blank";
        theiFrame.style.display = "none";
        document.body.appendChild(theiFrame);
      }
      fields["retURL"] = "https://";
      let form = document.createElement("form");
      form.method = "POST";
      form.action =
        "https://" +
        process.env.VUE_APP_DOMAIN +
        ".salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8";
      form.setAttribute("target", customHiddenIframeName);
      for (var fieldName in fields) {
        let theInput = document.createElement("input");
        theInput.name = fieldName;
        theInput.value = fields[fieldName];
        theInput.setAttribute("type", "hidden");
        form.appendChild(theInput);
      }
      document.body.appendChild(form);
      form.submit();
      setTimeout(() => {
        document.body.removeChild(
          document.getElementById(customHiddenIframeName)
        );
        vm.customerAction(validUser, validAnswers, error, jsonParseAPI);
      }, 2000);
    },

    addBackClosestDistrictInfo(data) {
      const APIRESPONSE = JSON.parse(this.apiValues.apiResponse);
      if (
        APIRESPONSE.closestDistrictInfo &&
        APIRESPONSE.closestDistrictInfo.DistrictDetail &&
        APIRESPONSE.closestDistrictInfo.DistrictDetail.districtNumber
      ) {
        var apiReponse = JSON.parse(data);
        apiReponse.closestDistrictInfo = {};
        apiReponse.closestDistrictInfo = APIRESPONSE.closestDistrictInfo;
        apiReponse.closestDistrictInfo.District =
          APIRESPONSE.closestDistrictInfo.DistrictDetail.districtNumber;
        this.apiValues.apiResponse = JSON.stringify(apiReponse);
      }
    },

    // *******************************************************
    // Reset functions for only contact us
    // *******************************************************
    //
    // Reset everything of contact us
    resetAllContactUs(keepAddress) {
      this.resetForm();
      this.resetStep();

      // reset customer's info of returned values from api call when entering an address
      reset.apiStoreData();

      // reset all quote variable to default
      reset.quote();

      // if parameter is true, it will reset everything
      // except the address and user
      if(!keepAddress || this.getCookie('SiteSettings')){
        reset.address();
        reset.user();
      }
    },

    // Reset/close all view of contact us form
    resetForm() {
      this.loading = false;
      if (
        this.service === "landfill" ||
        this.service === "landfillinquiry" ||
        this.lightCU
      ) {
        this.view.index = false;
      } else {

        if (!this.standardDialog) {
          useIndexStore().updateViewComponent("ContactWidgetV3");
        }
      }
      if (this.lightCU) {
        this.view.contactUs = true;
      } else {
        this.view.contactUs = false;
      }
      if (this.service === "landfill" || this.service === "landfillinquiry") {
        this.view.landfill = true;
      } else {
        this.view.landfill = false;
      }
      this.view.recyclePlus = false;
      this.view.bulkItemPickup = false;
      this.view.requestPickup = false;
      this.view.missedPickup = false;
      this.view.brokenContainer = false;
      this.view.deliveryIssue = false;
      this.view.iAmMoving = false;
      this.view.transferService = false;
      this.view.cancelService = false;
      this.view.stopService = false;
      this.view.serviceUpgrade = false;
      this.view.vacation = false;
      this.view.driverReview = false;
      this.view.requestQuote = false;
      this.view.startService = false;
      this.view.payMyBill = false;
      this.form.datePickupMenu = false;
      this.form.pickupBeforeMenu = false;
      this.form.pickupAfterMenu = false;
      this.form.driverTimeMenu = false;
    },

    // Reset by default the step ( main menu, address, prediction view)
    resetStep() {

      if ( this.service === "landfill" || this.service === "landfillinquiry" || this.lightCU ) {

        this.$refs.form.resetValidation();
        this.view.address = true;

      } else {

        this.view.address = false;

      }

      this.view.prediction = false;
      this.view.main = false;

    },

    // reset dialog popup message by default value

    resetDialog() {

      this.standardDialog = false;
      this.questionDialog = false;
      this.textDialog.title = "";
      this.textDialog.feedback = "";
      this.textDialog.googleRating = "";
      this.textDialog.buttonLocations = false;
      this.textDialog.buttonGoogleRating = false;

      // check if the dialog value is closed
      // it will reset everything by default and go back to the main page of Contact us menu
      if (!this.standardDialog) {
        const backUpService = this.serviceCU;
        this.resetAllContactUs()
        if(backUpService === "contactUs" || backUpService === "contactus" || backUpService === "generalinquiry"){
          useIndexStore().updateViewComponent("ContactWidget")
          this.viewForm(backUpService, true)
        }else{
          useIndexStore().updateViewComponent("ContactWidgetV3");
        }
      }

    },

    // *******************************************************
    // View form functions
    // *******************************************************
    //
    // Get string/name of the form in the parameter to show the form
    viewForm(viewFormSelected, skipScroll) {
      var vm = this;
      let position = 0;
      this.view.index = false;
      this.view.contactUs = false;
      this.view.landfill = false;
      this.view.bulkItemPickup = false;
      this.view.recyclePlus = false;
      this.view.requestPickup = false;
      this.view.missedPickup = false;
      this.view.brokenContainer = false;
      this.view.deliveryIssue = false;
      this.view.iAmMoving = false;
      this.view.transferService = false;
      this.view.stopService = false;
      this.view.cancelService = false;
      this.view.serviceUpgrade = false;
      this.view.vacation = false;
      this.view.driverReview = false;
      this.view.requestQuote = false;
      this.view.startService = false;
      this.view.payMyBill = false;
      if ( viewFormSelected === "contactUs" || viewFormSelected === "contactus" || viewFormSelected === "generalinquiry"
      ) {
          position = 10;
          this.view.contactUs = true;
          this.viewStep("address", skipScroll);
          googleApi.gTagSendEvents(viewFormSelected, position, "select_content", "engagement");
      }else if ( viewFormSelected === "landfill" || viewFormSelected === "landfillinquiry" ) {
          position = 0;
          this.view.landfill = true;
          this.viewStep("address", skipScroll);
          googleApi.gTagSendEvents(viewFormSelected, position, "select_content", "engagement");
      } else if ( viewFormSelected === "bulkItemPickup" || viewFormSelected === "bulkitempickup" ) {
          position = 0;
          if (this.bulkitempickupCU) {
            this.view.payMyBill = true;
            if (window.google_tag_manager && typeof window.dataLayer !== "undefined") {
              window.dataLayer = window.dataLayer || [];
              window.dataLayer.push({
                event: "eventTracking",
                action: "select_content",
                event_category: "engagement",
                event_label: viewFormSelected,
                value: position,
                eventCallback: function () {
                  window.location.href = vm.bulkitempickupCU;
                },
                eventTimeout: 2000,
              });
              setTimeout(function() {
                  window.location.href = vm.bulkitempickupCU;
              }, 2000);
            } else {
              window.location.href = vm.bulkitempickupCU;
            }
          } else {
            this.view.bulkItemPickup = true;
            this.viewStep("address", skipScroll);
            googleApi.gTagSendEvents(viewFormSelected, position, "select_content", "engagement");
          }
      }else if (
        viewFormSelected === "missedPickup" || viewFormSelected === "missedpickup" ) {
        position = 4;
        this.view.missedPickup = true;
        this.viewStep("address", skipScroll);
        googleApi.gTagSendEvents(viewFormSelected, position, "select_content", "engagement");
      }else if ( viewFormSelected === "brokenContainer" || viewFormSelected === "brokencontainer" ) {
          position = 2;
          this.view.brokenContainer = true;
          this.viewStep("address", skipScroll);
          googleApi.gTagSendEvents(viewFormSelected, position, "select_content", "engagement");
      }else if ( viewFormSelected === "deliveryIssue" || viewFormSelected === "deliveryissue" ) {
          position = 9;
          this.view.deliveryIssue = true;
          this.viewStep("address", skipScroll);
          googleApi.gTagSendEvents(viewFormSelected, position, "select_content", "engagement");
      }else if ( viewFormSelected === "iAmMoving" || viewFormSelected === "iammoving") {
          position = 6;
          this.view.iAmMoving = true;
          this.viewStep("address", skipScroll);
          googleApi.gTagSendEvents(viewFormSelected, position, "select_content", "engagement");
      }else if ( viewFormSelected === "transferService" || viewFormSelected === "transferservice" ) {
          position = 3;
          this.view.transferService = true;
          this.viewStep("address", skipScroll);
          googleApi.gTagSendEvents(viewFormSelected, position, "select_content", "engagement");
      }else if ( viewFormSelected === "cancelService" || viewFormSelected === "cancelservice" ) {
          position = 8;
          this.view.cancelService = true;
          googleApi.gTagSendEvents(viewFormSelected, position, "select_content", "engagement");
      }else if ( viewFormSelected === "stopService" || viewFormSelected === "stopservice" ) {
          position = 8;
          this.view.stopService = true;
          this.viewStep("address", skipScroll);
          googleApi.gTagSendEvents(viewFormSelected, position, "select_content", "engagement");
      }else if ( viewFormSelected === "serviceUpgrade" || viewFormSelected === "serviceupgrade"  ) {
          position = 7;
          this.view.serviceUpgrade = true;
          this.viewStep("address", skipScroll);
          googleApi.gTagSendEvents(viewFormSelected, position, "select_content", "engagement");
      }else if ( viewFormSelected === "vacation" || viewFormSelected === "seasonvacationhold" ) {
          position = 1;
          this.view.vacation = true;
          this.viewStep("address", skipScroll);
          googleApi.gTagSendEvents(viewFormSelected, position, "select_content", "engagement");
      }else if ( viewFormSelected === "driverReview" || viewFormSelected === "driverreview" || viewFormSelected === "appreciatemydriver" ) {
          position = 5;
          this.view.driverReview = true;
          this.viewStep("address", skipScroll);
          googleApi.gTagSendEvents(viewFormSelected, position, "select_content", "engagement");
      }else if ( viewFormSelected === "requestQuote" || viewFormSelected === "requestquote" ) {
          position = 11;
          this.view.payMyBill = true;
          googleApi.gTagSendEvents(viewFormSelected, position, "select_content", "engagement");
          this.resetAllContactUs(true)
          useIndexStore().component = "quote";
          useIndexStore().currentTabComponent = this.form.menuOrder[this.form.menuOrder.length - 1]
      }else if ( viewFormSelected === "startService" || viewFormSelected === "startservice" ) {
          position = 12;
          this.view.payMyBill = true;
          googleApi.gTagSendEvents(viewFormSelected, position, "select_content", "engagement");
          this.resetAllContactUs(true)
          useIndexStore().component = "quote";
          useIndexStore().currentTabComponent = this.form.menuOrder[this.form.menuOrder.length - 1]
      }else if ( viewFormSelected === "payMyBill" || viewFormSelected === "paymybill" ) {
          position = 13;
          this.view.payMyBill = true;
          if (window.google_tag_manager && typeof window.dataLayer !== "undefined") {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "eventTracking",
              action: "select_content",
              event_category: "engagement",
              event_label: viewFormSelected,
              value: position,
              eventCallback: function () {
                window.location.href = vm.payCU;
              },
              eventTimeout: 2000,
            });
            setTimeout(function() {
                window.location.href = vm.payCU;
            }, 2000);
          } else {
            window.location.href = vm.payCU;
          }
      }else if ( viewFormSelected === "pickupSchedule" || viewFormSelected === "pickupschedule" ) {
          position = 14;
          if (window.google_tag_manager && typeof window.dataLayer !== "undefined") {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "eventTracking",
              action: "select_content",
              event_category: "engagement",
              event_label: viewFormSelected,
              value: position,
              eventCallback: function () {
                window.location.href = vm.pickupscheduleurlCU;
              },
              eventTimeout: 2000,
            });
            setTimeout(function() {
              window.location.href = vm.pickupscheduleurlCU;
            }, 2000);
          } else {
            window.location.href = vm.pickupscheduleurlCU;
          }
      }else if ( viewFormSelected === "faq" || viewFormSelected === "Faq" ) {
          position = 15;
          this.view.payMyBill = true;
          if (window.google_tag_manager && typeof window.dataLayer !== "undefined") {
            window.dataLayer = window.dataLayer || [];
            window.dataLayer.push({
              event: "eventTracking",
              action: "select_content",
              event_category: "engagement",
              event_label: viewFormSelected,
              value: position,
              eventCallback: function () {
                window.location.href = vm.faqCU;
              },
              eventTimeout: 2000,
            });
            setTimeout(function() {
              window.location.href = vm.faqCU;
            }, 2000);
          } else {
            window.location.href = vm.faqCU;
          }
      }else if(viewFormSelected === 'recyclePlus' || viewFormSelected === 'recycleplus'){
          position = 15;
          this.view.recyclePlus = true;
          this.viewStep('address', skipScroll);
          googleApi.gTagSendEvents(viewFormSelected,position);
      }else if(viewFormSelected === 'requestPickup' || viewFormSelected === 'requestpickup'){
          position = 14;
          this.view.requestPickup = true;
          this.viewStep('address', skipScroll);
          googleApi.gTagSendEvents(viewFormSelected,position);
      }
      // Check if second parameter is activate to scroll on the top of the component
      if (!skipScroll) {
        useIndexStore().scrollToTop();
      }
    },

    // Return view form of the label depending which variable has been set to true
    viewFormLabel() {
      if (this.view.contactUs) {
        return "Contact Us";
      } else if (this.view.landfill) {
        return "Landfill";
      } else if (this.view.bulkItemPickup) {
        return "Bulk Item Pickup";
      } else if (this.view.requestPickup) {
        return "Request Pickup";
      }else if(this.view.recyclePlus){
        return 'Recycle+';
      } else if (this.view.missedPickup) {
        return "Missed Pickup";
      } else if (this.view.brokenContainer) {
        return "Broken Container";
      } else if (this.view.deliveryIssue) {
        return "Delivery Issue";
      } else if (this.view.iAmMoving) {
        return "I Am Moving";
      } else if (this.view.transferService) {
        return "Transfer Service";
      } else if (this.view.stopService) {
        return "Stop Service";
      } else if (this.view.cancelService) {
        return "Cancel Service";
      } else if (this.view.serviceUpgrade) {
        return "Service Upgrade";
      } else if (this.view.vacation) {
        return "Vacation";
      } else if (this.view.driverReview) {
        return "Driver Review";
      } else if (this.view.requestQuote) {
        return "Request Quote";
      } else if (this.view.startService) {
        return "Start Service";
      }
    },

    // Show view of the step..
    // This will help to trigger the view of the form
    viewStep(viewStepSelected, skipScroll) {
      this.view.address = false;
      this.view.prediction = false;
      this.view.main = false;
      if (viewStepSelected === "address") {
        this.view.address = true;
      } else if (viewStepSelected === "prediction") {
        this.view.prediction = true;
      } else if (viewStepSelected === "main") {
        this.view.main = true;
      }

      // Check in second parameter if its true to
      // scroll on the top of the component.
      if (!skipScroll) {
        useIndexStore().scrollToTop();
      }
    },

    contactUsServiceOnly(){
      const serviceIncludeCommunicationsList = this.communications.filter(service => this.serviceCU.toLowerCase().includes(service.viewform.toLowerCase()));
      const serviceIncludeServicesOtherList = this.servicesOther.filter(service => this.serviceCU.toLowerCase().includes(service.viewform.toLowerCase()));
      const serviceIncludeOtherServicesList = this.otherServices.filter(service => this.serviceCU.toLowerCase().includes(service.viewform.toLowerCase()));
      if(serviceIncludeCommunicationsList.length || serviceIncludeServicesOtherList.length || serviceIncludeOtherServicesList.length) {
        return true;
      } else {
        return false;
      }

    }
  },

});