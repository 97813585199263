<template>
  <v-container fluid>
    <v-main>
      <!-- <keep-alive> -->
      <v-card outlined>
        <v-flex>
          <component class="mb-10 mt-1" :class="$vuetify.breakpoint.mdAndUp ? '' : 'px-0'"
                  v-bind:is="currentTabComponent" :name="name"
                  :service="service" :assigntodistrict="assigntodistrict" :phone="phone" :country="country" :terms="terms" :privacy="privacy" :splitter="splitter" :lang="lang" :header="header" :primary="primary" :secondary="secondary"
                  :serviceswantedwithpricing="serviceswantedwithpricing">
          </component>
        </v-flex>
      </v-card>
    </v-main>
  </v-container>

</template>

<script>
//Commercial Dumpster Chart
import googleApi from "../../plugins/google";
import { useUserStore } from "../../store/user";
import ChooseDumpster from "../dumpstercommercialchartquote/ChooseDumpster";
import DumpsterCommercialForm from "../dumpstercommercialchartquote/DumpsterCommercialForm";
import DetailDumpster from "../dumpstercommercialchartquote/DetailDumpster";
import ThankyouExplore from "../requestquote/view/thankyou/ThankyouExplore.vue";
import ProcessingOrder from "../requestquote/view/thankyou/ProcessingOrder"

import NumberInput from "../requestquote/utility/NumberInput";

// Single components
import i18n from "@/i18n";
import CustomerServiceBar from "../requestquote/footer/CustomerServiceBar";
import { useIndexStore } from '../../store/index';
import { mapState, mapWritableState } from 'pinia';
import { mapActions } from 'pinia';

export default {
  name: "CommercialView",
  props: {
    service: String,
    assigntodistrict: Number,
    lang: String,
    phone: String,
    country: String,
    terms: String,
    privacy: String,
    name: String,
    splitter: Boolean,

    header: String,
    primary: String,
    secondary: String,

    showPrice: Boolean,
    price:Number,

    serviceswantedwithpricing: Boolean,
    commChartOfficeLabel: Boolean,
  },
    components: {
    CustomerServiceBar,
    ChooseDumpster,
    DumpsterCommercialForm,
    DetailDumpster,
    NumberInput,
    ThankyouExplore,
    ProcessingOrder,
  },
  data: () => ({
    i18n: i18n,
  }),
created() {
    i18n.locale = this.lang;
    this.form.servicesWantedWithPricing = this.serviceswantedwithpricing
    this.commChartOfficeLabelEdit = this.commChartOfficeLabel;
  },
  mounted: function () {

    this.checkIfGTMLoadedThenSendLoadForm();

    // salesforce.campaignStorageTracking();
    // this.getPNParameterInURL();

    if (this.service === "residential") {
      this.form.serviceType = 'Residential';
    }
    if (this.service === "commercial" || this.service === "construction") {
      this.form.serviceType = 'Commercial';
    }
    if(this.primary){
      this.$vuetify.theme.themes.light.primary = this.primary;
    }
    if(this.secondary){
      this.$vuetify.theme.themes.light.secondary = this.secondary;
    }
  },
  computed: {
    ...mapWritableState(useUserStore, [
      "form",
      ]),
    ...mapState(useIndexStore,['formRules',"hoas","windowSize"]),
    ...mapWritableState(useIndexStore, {
      apiResult: "apiResult",
      storefrontResult: "storefrontResult",
      component: "component",
      currentTabComponent: "currentTabComponent",
      loading: "loading",
      servicefromquote: "servicefromquote",
      snackbar: 'snackbar',
      completeAddress: 'completeAddress',
      completeAddressMsg: 'completeAddressMsg',
      errorAxios: 'errorAxios',
      commChartOfficeLabelEdit: 'commChartOfficeLabel'
    }),
  },
  methods: {
    ...mapActions(useIndexStore, [
    'updateViewComponent',
    ]),
    checkIfGTMLoadedThenSendLoadForm() {
      if (window.google_tag_manager && typeof window.dataLayer) {
        googleApi.gTagSendEvents(window.parent.location.href, 0, "Load Form", "Start Service");
      }else {
        setTimeout(this.checkIfGTMLoadedThenSendLoadForm, 500);
      }
    }
   }
}
</script>


<style scoped>
::v-deep .input-label {
  font-size: 14px !important;
  word-break: normal !important;
  color: black !important;
  border-radius: 10px !important;
}
::v-deep .dumpster-active{
  background-color: #1F528F !important;
}
::v-deep .dumpster-hover{
  background-color:#DAE7F5 !important;
}
::v-deep .dumpster-default{
  background-color: #F0F7FE !important;
}
::v-deep .dumpster-icon-white{
  fill:white;
}
::v-deep .light-grey{
  background-color:#EDEDED;
}::v-deep .unactive-grey{
  background-color: #B2B2B2;
}
::v-deep .dumpster-icon-default{
  fill:#1F528F;
}
::v-deep .title{
  font-size: 20px;
}
::v-deep .card-title{
  font-size: 18px;
}
::v-deep .card-label{
  font-size:16px;
  font-weight: 500;
}
::v-deep .card-label-sm{
  font-size:14px;
  font-weight: 500;
}
::v-deep .card-label{
  font-size:16px;
}
::v-deep .garbage-number{
  font-size:16px;
  font-weight: 900;
  color:#1F528F;
}
::v-deep .checkmark-dumpster {
  position: absolute;
  left:90%;
  bottom: 85%;
}
::v-deep .checkmark-solo {
  position: absolute;
  left:96%;
  bottom: 75%;
}
::v-deep .checkmark-selected-form {
  position: absolute;
  left:90%;
  bottom: 65%;
}
::v-deep .rounded-lg{
  border-radius: 8px !important;
}
::v-deep .label-form{
  font-size:16px;
  font-weight: 500;
  }
::v-deep .title-form{
  font-size:20px;
  font-weight: 700;
  }
::v-deep .btn-form{
  font-size:16px;
  }
::v-deep button {
  text-transform: none !important;
}
::v-deep .box-shadow-1 {
  box-shadow: 2px 2px 8px 0px rgba(0, 0, 0, 0.14);
}
</style>