<template>
  <v-form ref="wasteForm">
    <v-row>
      <v-col class="text-center mt-5">
        <v-img src="https://img.wasteconnections.com/corporate-office-the-woodlands/request_quote/thank-you/sad-garbage.png" width="139px" height="235px" class="mx-auto"/>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" class="d-flex justify-center align-center pt-0">
        <v-card elevation="0" width="418px">
          <v-row>
            <v-col class="px-6">
              <v-card-text class="pb-5 text-center text-quote">
                <b>{{ i18n.t('sorry') }}</b>, {{name}} {{ i18n.t('doesNotProvideThisServiceAtYourAddress') }}
              </v-card-text>
              <v-card-text class="pb-1 text-center text-quote">
                {{ i18n.t('doYouNeedAnyOtherService') }}
              </v-card-text>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col class="text-center pt-1">
        <v-btn @click="updateView('ServiceView')" width="305px" height="60px" style="text-transform: unset" class="service-bar-btn rounded-lg" outlined>
          <span class="service-bar-span">
            {{ i18n.t('exploreOtherServices') }}
          </span>
        </v-btn>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>
import i18n from "@/i18n";
import { useIndexStore } from '../../../../store/index';
import { mapActions } from 'pinia';

export default {
  name: "ThankyouExplore",
  props: {name: String},
  data() {
    return {
      i18n: i18n,
      selectedType: "",
    };
  },

  methods: {
    ...mapActions(useIndexStore, ['updateViewComponent']),
  },
};
</script>
