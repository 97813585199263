<template>
      <v-card-text>
        <v-form ref="wasteForm">
          <v-row>
            <v-col class="text-center">
              <v-img src="https://img.wasteconnections.com/corporate-office-the-woodlands/request_quote/thank-you/icon-action-check_circle.png" width="104px" height="104px" class="mx-auto mb-6"/>
              <v-card-text style="color: #175e45" class="congrats pa-1">
                {{ i18n.t('congrats') }}
              </v-card-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-center align-center">
              <v-card elevation="0" :width="windowSize.x < 600 ? '400px' :'420px'">
                <v-row>
                  <v-col class="pt-4 px-0">
                    <v-card-text class="pb-6 text-center text-quote px-0" >
                      {{ i18n.t('serviceRequest') }}
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
</template>

<script>
import i18n from "@/i18n";
import { useIndexStore } from '../../../../store/index';
import { mapWritableState } from 'pinia';
import { mapActions } from 'pinia';


export default {
  name: "ThankyouNote",
  props: ["currentTabComponent"],
  data() {
    return {
      i18n: i18n,
      selectedType: "",
    };
  },
  computed: {
    ...mapWritableState(useIndexStore, ["windowSize",])
  },
  methods: {
    ...mapActions(useIndexStore, ['updateViewComponent']),

  },
};
</script>
