<template>

    <!-- Inside the Dialog for Hoa and Tenant -->
    <v-card>

        <!-- Title -->
        <v-card-title class="text-h5 justify-center text-center text-break">
            {{ i18n.t('hoaAndTenant') }}
        </v-card-title>

        <!-- Description -->
        <v-card-text v-if="!hoaAndTenantList" class="pb-0 text-center  text-subtitle-2">
            {{ i18n.t('memberOfHoaAndTenant') }}
        </v-card-text>

        <!-- Text when Yes to Hoa and Tenant -->
        <v-card-text v-if="hoaAndTenantList && (hoaList || tenantList)" class="pb-0">
            <v-alert color="warning" outlined text class="text-center my-4">
                {{ (hoaList ? i18n.t('hoaContact') : '') + (tenantList ? i18n.t('tenantContact') : '') }}
            </v-alert>
        </v-card-text>

        <!-- Divider -->
        <v-divider v-if="!hoaAndTenantList" class="ma-4"></v-divider>

        <!-- Both Hoa and Tenant User must confirm using the "Yes" button -->
        <v-card-text v-if="!hoaAndTenantList">
            <v-list>

                <!-- HOA Section -->
                <v-list-item>

                    <!-- HOA Text -->
                    <v-list-item-content>
                        <v-list-item-title v-text="i18n.t('memberOfHoa')"></v-list-item-title>
                    </v-list-item-content>

                    <!-- HOA Button -->
                    <v-list-item-icon>
                        <v-btn block large color="success" class="white--text rounded-lg" :loading="loading"
                            :disabled="!loading ? false : true" @click="setHoaToYes">
                            <v-icon left>mdi-thumb-up-outline</v-icon>
                            {{ i18n.t('yes') }}
                            <template v-slot:loader>
                                <span>{{ i18n.t("validate") }}</span>
                                <v-progress-circular class="ml-2" indeterminate="indeterminate"
                                    size="23"></v-progress-circular>
                            </template>
                        </v-btn>
                    </v-list-item-icon>
                </v-list-item>

                <!-- Tenant Section -->
                <v-list-item>

                    <!-- Tenant Text -->
                    <v-list-item-content>
                        <v-list-item-title v-text="i18n.t('memberOfTenant')"></v-list-item-title>
                    </v-list-item-content>

                    <!-- Tenant Button -->
                    <v-list-item-icon>
                        <v-btn block large color="success" class="white--text rounded-lg" :loading="loading"
                            :disabled="!loading ? false : true" @click="setTenantToYes">
                            <v-icon left>mdi-thumb-up-outline</v-icon>
                            {{ i18n.t('yes') }}
                            <template v-slot:loader>
                                <span>{{ i18n.t("validate") }}</span>
                                <v-progress-circular class="ml-2" indeterminate="indeterminate"
                                    size="23"></v-progress-circular>
                            </template>
                        </v-btn>
                    </v-list-item-icon>
                </v-list-item>
            </v-list>
        </v-card-text>

        <!-- Divider -->
        <v-divider v-if="!hoaAndTenantList"></v-divider>

        <!-- Actions -->
        <v-card-actions>
            <v-row class="no-gutters flex-wrap flex-row fill-height">
                <v-col v-if="!hoaAndTenantList" cols="auto" class="grow pa-2">

                    <!-- No Button -->
                    <v-btn block large color="secondary" class="white--text rounded-lg" :loading="loading"
                        :disabled="!loading ? false : true"
                        @click="loading ? null : callHomeOwnerAssociationOrTenant()">
                        <v-icon left>mdi-thumb-down-outline</v-icon>
                        {{ i18n.t('no') }}
                        <template v-slot:loader>
                            <span>{{ i18n.t("validate") }}</span>
                            <v-progress-circular class="ml-2" indeterminate="indeterminate"
                                size="23"></v-progress-circular>
                        </template>
                    </v-btn>
                </v-col>

                <!-- Close Button -->
                <v-col v-if="hoaAndTenantList" cols="auto" class="grow pa-2">
                    <v-btn block large color="success" class="white--text rounded-lg" :loading="loading"
                        :disabled="!loading ? false : true" @click="loading ? null : updateLeadForHoaAndTenant()">
                        {{ i18n.t('snackbar.close') }}
                        <template v-slot:loader>
                            <span>{{ i18n.t("validate") }}</span>
                            <v-progress-circular class="ml-2" indeterminate="indeterminate"
                                size="23"></v-progress-circular>
                        </template>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>
import i18n from "@/i18n";
import { useIndexStore } from '@/store/index';
import { mapActions, mapWritableState } from 'pinia';
import { useAxiosFormStore } from "@/store/axios";
import salesforce from "@/plugins/salesforce"

export default {
    name: "HoaAndTenantDialog",
    props: ["disabled"],
    data() {
        return {
            i18n: i18n,
            hoaList: false,
            tenantList: false,
            hoaAndTenantList: false,
        };
    },
    computed: {
        ...mapWritableState(useIndexStore, ["loading", "dialog"]),
    },
    watch: {
        dialog(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.hoaAndTenantList = false;
            }
        },
    },
    methods: {
        ...mapActions(useIndexStore, ["homeOwnerAssociationOrTenant"]),
        setHoaToYes() {
            if (!this.loading) {
                this.hoaList = true;
                this.tenantList = false;
                this.hoaAndTenantList = true;
            }
        },
        setTenantToYes() {
            if (!this.loading) {
                this.hoaList = false;
                this.tenantList = true;
                this.hoaAndTenantList = true;
            }
        },
        updateLeadForHoaAndTenant() {
            this.hoaList ? useAxiosFormStore().leadHOAAnswer = true : useAxiosFormStore().leadTenantAnswer = true;
            salesforce.sendHttpRequestQuote('LeadInformation',useAxiosFormStore().getDataToAxios());
            this.dialog = false;
        },
        callHomeOwnerAssociationOrTenant() {
            this.hoaList ? useAxiosFormStore().leadHOAAnswer = false : useAxiosFormStore().leadTenantAnswer = false;
            this.homeOwnerAssociationOrTenant();
            this.dialog = false;
        },
    },
};
</script>