<template>
    <v-row v-if="duplicateCustomerCheck()" class="mx-3 mb-0 mt-8">
        <v-alert class="ma-0" width="100%" dark dense outlined prominent elevation="2" rounded="lg" color="darksecondary" type="info">
            <v-row align="center">
                <v-col class="grow">
                    <div class="text-h6">{{ i18n.t('alertAlreadyACustomerTitle') }}</div>
                    <div>{{ i18n.t('alertAlreadyACustomerPara') }}</div>
                </v-col>
                <v-col class="shrink">
                    <v-btn depressed color="darksecondary" class="white--text" :href="`tel:${apiResult.data?.DistrictDetail.phone || apiResultOriginal.data?.DistrictDetail.phone}`">
                        {{ i18n.t('tollFreeCall') }}
                    </v-btn>
                    <div class="my-1 text-center">{{ apiResult.data?.DistrictDetail.phone || apiResultOriginal.data?.DistrictDetail.phone}}</div>
                </v-col>
            </v-row>
        </v-alert>
    </v-row>
</template>

<script>
import i18n from '@/i18n';
import { mapWritableState, mapActions } from 'pinia';
import { useIndexStore } from '@/store';
export default {
    name: "AlertBox",
    data() {
        return {
            i18n: i18n,
        }
    },
    computed: {
        ...mapWritableState(useIndexStore, ['apiResult', 'apiResultOriginal'])
    },
    methods: {
        ...mapActions(useIndexStore, ['duplicateCustomerCheck']),
    }
}
</script>