// *******************************************************
// API request to Salesforce
// *******************************************************
// Send request data to salesforce for price
import axios from "axios";
const sendHttpRequestQuote = async (apiName, json) => {
  campaignStorageTracking(true);
  try {
    let response = await axios.post(process.env.VUE_APP_RESTURL + "services/apexrest/" + apiName, json);
    return response;
  } catch (error) {
    return error;
  }
};
const campaignStorageTracking = (skipGetUTMFromURL) => {
    const urlPathname = window.parent.location.pathname;
    const urlParams = new URLSearchParams(window.parent.location.search);
    let campaignStorage = {};
    let utmSource = "", utmCampaign = "", utmMedium = "", utmTerm = "", gclid = "", fclid = "", referrer = "", etid = "", promoCode = "";
    const campaignURL = JSON.parse(sessionStorage.getItem("campaignURL"));

    if (campaignURL) {
      utmSource = campaignURL["utmSource"];
      utmCampaign = campaignURL["utmCampaign"];
      utmMedium = campaignURL["utmMedium"];
      utmTerm = campaignURL["utmTerm"];
      gclid = campaignURL["gclid"];
      fclid = campaignURL["fclid"];
      referrer = campaignURL["referrer"];
      etid = campaignURL["etid"];
      promoCode = campaignURL["promoCode"];
    }

    if(!skipGetUTMFromURL){
    campaignStorage["utmSource"] = urlParams.get("utm_source")?encodeURIComponent(urlParams.get("utm_source")):utmSource?utmSource:"";
    campaignStorage["utmCampaign"] = urlParams.get("utm_campaign")?encodeURIComponent(urlParams.get("utm_campaign")):utmCampaign?utmCampaign:"";
    campaignStorage["utmMedium"] = urlParams.get("utm_medium")?encodeURIComponent(urlParams.get("utm_medium")):utmMedium?utmMedium:"";
    campaignStorage["utmTerm"] = urlParams.get("utm_term")?encodeURIComponent(urlParams.get("utm_term")):utmTerm?utmTerm:"";
    campaignStorage["gclid"] = urlParams.get("gclid")?encodeURIComponent(urlParams.get("gclid")):gclid?gclid:"";
    campaignStorage["fclid"] = urlParams.get("fclid")?encodeURIComponent(urlParams.get("fclid")):fclid?fclid:"";
    campaignStorage["referrer"] = urlParams.get("referrer")?encodeURIComponent(urlParams.get("referrer")):referrer?referrer:"";
    campaignStorage["etid"] = urlParams.get("etid")?encodeURIComponent(urlParams.get("etid")):etid?etid:"";
    campaignStorage["promoCode"] = urlPathname==="/rolloff-springclean-lp/"?true:promoCode?promoCode:false;

    if (campaignStorage["utmSource"]||campaignStorage["utmCampaign"]||campaignStorage["utmMedium"]||campaignStorage["utmTerm"]||campaignStorage["gclid"]||campaignStorage["fclid"]||campaignStorage["referrer"]||campaignStorage["etid"]||campaignStorage["promoCode"]){
      sessionStorage.setItem("campaignURL", JSON.stringify(campaignStorage));
    }
}
};
export default {sendHttpRequestQuote,campaignStorageTracking};