<template>
  <v-row>
    <v-col offset="1" cols="10">
      <v-form ref="wasteForm" v-model="formValid" lazy-validation>
        <v-row>
          <v-col :offset="$vuetify.breakpoint.xs ? 0 : 1" :cols="$vuetify.breakpoint.xs ? 12 : 10">
            <v-card-text primary-title class="text-title mb-0 px-0" :class="headerstore ? 'text-left' : 'text-center'">
              Choose Your Next Path
            </v-card-text>
          </v-col>
        </v-row>
        <v-col class="pa-0 min-height-44 d-flex align-center">
          <BackBtn></BackBtn>
          <icon class="flex-grow-1" svg='step-marker-2' />
        </v-col>
        <v-row class="d-flex justify-center my-3">
          <p v-if="showGetAQuote && showGoToStore" class="text-h6 font-weight-light">Would you prefer to request a quote or visit the store?</p>
          <p v-if="!showGetAQuote && showGoToStore" class="text-h6 font-weight-light">Would you like to visit the store?</p>
        </v-row>
        <v-row v-if="!formValid" class="pt-5">
          <div class="v-messages error--text px-6 text-center" role="alert">
            <div class="subtitle-2">
              <v-icon class="error--text" left="left" dense="dense">mdi-alert-outline</v-icon> {{ i18n.t('missingField')
              }}
            </div>
          </div>
        </v-row>
        <div class="mt-6">
          <ForwardBtn :parentRefs="$refs" currentTabComponent="RedirectView"></ForwardBtn>
        </div>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { useUserStore } from "../../../../store/user";
import i18n from "@/i18n";
import icon from "../../utility/SvgLoader.vue"
import { useIndexStore } from '../../../../store/index';
import { mapState, mapWritableState } from 'pinia';
import ForwardBtn from "../../utility/forwardBtn.vue"
import BackBtn from "../../utility/backBtn.vue"

export default {
  name: "ResiStorefront",
  components: { icon, BackBtn, ForwardBtn },
  props: {
    canada: Boolean,
    terms: String,
    privacy: String,
    name: String,
    lang: String,
  },
  data() {
    return {
      i18n: i18n,
    };
  },
  mounted: function () {
    if(this.showGetAQuote && this.showGoToStore){
      this.trackViewSplitter = true;
    }
  },
  computed: {
    ...mapState(useIndexStore, [
      "showGetAQuote",
      "showGoToStore",
      "headerstore",
    ]),
    ...mapWritableState(useIndexStore, [
      'formValid',
      'trackViewSplitter',
    ]),
    ...mapWritableState(useUserStore, [
      "form",
    ]),
  },
};
</script>

<style scoped>
.min-height-44 {
  min-height: 44px;
}

.box-shadow-custom {
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px !important;
}</style>