<template>
    <div>
        <v-row>
            <v-col class="py-0 pb-3">
                <v-card-text class="px-0 py-0 mb-1 text-label">
                {{i18n.t("tellUsWhat")}}<span :class="$vuetify.breakpoint.mobile ? '' : 'float-right'" class="grey--text lighten-1">{{i18n.t("optional")}}</span>
                </v-card-text>
                <v-textarea flat v-model="form.description" v-bind:disabled="loading && disabled" v-bind:rules="formRules.descriptionRules" :hint="i18n.t('descriptionHint')" counter="counter" height="90px" :placeholder="i18n.t('descriptionPlaceholder')" solo outlined no-resize class="rounded-xl-description input-label"></v-textarea>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import i18n from "@/i18n";
import {useUserStore} from "@/store/user"
import {useIndexStore} from "@/store/index"
import {mapState,mapWritableState} from "pinia"

export default {
  name: "TermsPolicy",
  components:{},
  props: {
    name: String,
    lang: String,
    terms: String,
    privacy: String,
    disabled: Boolean,
  },

  data() {
    return {
      i18n: i18n,

    };
  },
    computed: {
        ...mapWritableState(useUserStore, [
            "form",
        ]),
        ...mapState(useIndexStore, [
            'formRules',
            'loading'
        ]),

    },
    created() {
      i18n.locale = this.lang;
    },

};
</script>

<style scoped>
::v-deep .rounded-xl-description {
  border-radius: 12px !important;
  height: 82px !important;
  margin-bottom: 20px !important ;
}
::v-deep .input-label {
  font-size: 14px !important;
  word-break: normal !important;
  color: black !important;
  border-radius: 10px !important;
}
::v-deep .text-label {
  font-size: 16px;
  line-height: 21px;
  word-break: normal !important;
  color: black !important;
}
</style>