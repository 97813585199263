//  **********************************
//  Functions for reset variables
//  **********************************
import { useContactUsStore } from "@/store/contactus";
import {useIndexStore} from "@/store/index"
import {useUserStore} from "@/store/user"
import { useDumpsterCommercialStore } from "@/store/dumpstercommercial"
import { useRollOffStore } from "@/store/rolloffdumpster";
import i18n from "@/i18n";

// reset one variable
const variable = (variable, defaultValue) => {

  if (typeof variable === 'number') {
    variable = defaultValue !== undefined ? defaultValue : 0;
  } else if (typeof variable === 'string') {
    variable = defaultValue !== undefined ? defaultValue : '';
  } else if (typeof variable === 'boolean') {
    variable = defaultValue !== undefined ? defaultValue : false;
  } else if (typeof variable === 'object') {
    variable = defaultValue !== undefined ? defaultValue : {}
  }
  return variable;
};

// reset commercial chart form to default (Need to be check for something better)
const commercialChart = () => {
  const commercial = useDumpsterCommercialStore();
  commercial.dumpsterDetail = {
    path: '',
    title: '',
    qty: 0,
    pickupFrequency: '',
    recyclingDumpster: false,
    // serviceCardId: [],
    selectedDumpsterId: [],
  };
  commercial.dumpsters = [
    {
      id:1,
      qty: 0,
      recyclingDumpster: false,
      size:{
        text: i18n.t('dumpsters.96GallonCart', 'en'),
        textFr: i18n.t('dumpsters.96GallonCart', 'fr'),
        binSize: "96 GALLON",
        amountGarbages: 7,
        weight:'117 lbs',
        spec: '',
        specFr: '',
        businessType: i18n.t('dumpsters.smallRetailsShopsRestaurants', 'en'),
        businessTypeFr: i18n.t('dumpsters.smallRetailsShopsRestaurants', 'fr'),
        description: i18n.t('dumpsters.25-50OfficeStaff', 'en'),
        descriptionFr: i18n.t('dumpsters.25-50OfficeStaff', 'fr'),
        icon:'home-garbage',
      },
      frequency: {},
    },
    {
      id:2,
      qty: 0,
      recyclingDumpster: false,
      size:{
        text: i18n.t('dumpsters.2YardDumpster', 'en'),
        textFr: i18n.t('dumpsters.2YardDumpster', 'fr'),
        binSize: "2 YARD",
        amountGarbages:24,
        weight:'117 lbs',
        spec: i18n.t('dumpsters.2YardDumpsterSpec', 'en'),
        specFr: i18n.t('dumpsters.2YardDumpsterSpec', 'fr'),
        businessType: i18n.t('dumpsters.smallRetailsShopsRestaurants', 'en'),
        businessTypeFr: i18n.t('dumpsters.smallRetailsShopsRestaurants', 'fr'),
        description: i18n.t('dumpsters.25-50OfficeStaff', 'en'),
        descriptionFr: i18n.t('dumpsters.25-50OfficeStaff', 'fr'),
        icon:'commercial-dumpster',
      },
      frequency: {},
    },
    {
      id:3,
      qty: 0,
      recyclingDumpster: false,
      size:{
        text: i18n.t('dumpsters.3YardDumpster', 'en'),
        textFr: i18n.t('dumpsters.3YardDumpster', 'fr'),
        binSize: "3 YARD",
        amountGarbages:36,
        weight:'600 lbs',
        spec: i18n.t('dumpsters.2YardDumpsterSpec', 'en'),
        specFr: i18n.t('dumpsters.2YardDumpsterSpec', 'fr'),
        businessType: i18n.t('dumpsters.smallRetailsShopsRestaurants', 'en'),
        businessTypeFr: i18n.t('dumpsters.smallRetailsShopsRestaurants', 'fr'),
        description: i18n.t('dumpsters.50OfficeStaff', 'en'),
        descriptionFr: i18n.t('dumpsters.50OfficeStaff', 'fr'),
        icon:'commercial-dumpster',
      },
      frequency: {},
    },
    {
      id:4,
      qty: 0,
      recyclingDumpster: false,
      size: {
        text: i18n.t('dumpsters.4YardDumpster', 'en'),
        textFr: i18n.t('dumpsters.4YardDumpster', 'fr'),
        binSize: "4 YARD",
        amountGarbages:48,
        weight:'800 lbs',
        spec: i18n.t('dumpsters.4YardDumpsterSpec', 'en'),
        specFr: i18n.t('dumpsters.4YardDumpsterSpec', 'fr'),
        businessType: i18n.t('dumpsters.midsizeRetailsShopsRestaurants', 'en'),
        businessTypeFr: i18n.t('dumpsters.midsizeRetailsShopsRestaurants', 'fr'),
        description: i18n.t('dumpsters.100OfficeStaff', 'en'),
        descriptionFr: i18n.t('dumpsters.100OfficeStaff', 'fr'),
        icon:'commercial-dumpster',
      },
      frequency: {},
    },

    {
      id:5,
      qty: 0,
      recyclingDumpster: false,
      size:{
        text: i18n.t('dumpsters.6YardDumpster', 'en'),
        textFr: i18n.t('dumpsters.6YardDumpster', 'fr'),
        binSize: "6 YARD",
        amountGarbages:72,
        weight:'1200 lbs',
        spec: i18n.t('dumpsters.6YardDumpsterSpec', 'en'),
        specFr: i18n.t('dumpsters.6YardDumpsterSpec', 'fr'),
        businessType: i18n.t('dumpsters.bigRetailsShopsRestaurants', 'en'),
        businessTypeFr: i18n.t('dumpsters.bigRetailsShopsRestaurants', 'fr'),
        description: i18n.t('dumpsters.250OfficeStaff', 'en'),
        descriptionFr: i18n.t('dumpsters.250OfficeStaff', 'fr'),
        icon:'commercial-dumpster',
      },
      frequency: {},
    },
    {
      id:6,
      qty: 0,
      recyclingDumpster: false,
      size:{
        text: i18n.t('dumpsters.8YardDumpster', 'en'),
        textFr: i18n.t('dumpsters.8YardDumpster', 'fr'),
        binSize: "8 YARD",
        amountGarbages:96,
        weight:'1800 lbs',
        spec: i18n.t('dumpsters.8YardDumpsterSpec', 'en'),
        specFr: i18n.t('dumpsters.8YardDumpsterSpec', 'fr'),
        businessType: i18n.t('dumpsters.smallRetailsShopsGroceryStores', 'en'),
        businessTypeFr: i18n.t('dumpsters.smallRetailsShopsGroceryStores', 'fr'),
        description: i18n.t('dumpsters.500OfficeStaff', 'en'),
        descriptionFr: i18n.t('dumpsters.500OfficeStaff', 'fr'),
        icon:'commercial-dumpster',
      },
      frequency: {},
    },
  ];
  commercial.dumpsterSelected = [
  ];
}
const rolloff = () => {
  const rolloff = useRollOffStore();
  rolloff.rollOffList = [
    {
        qty: 0 ,
        size:{
          text: i18n.t('rollOffList.10YardDumpster', 'en'),
          textFr: i18n.t('rollOffList.10YardDumpster', 'fr'),
          binSize: "10 YARD",
          spec: i18n.t('rollOffList.10YardDumpsterSpec', 'en'),
          specFr: i18n.t('rollOffList.10YardDumpsterSpec', 'fr'),
          img: "https://img.wasteconnections.com/corporate-office-the-woodlands/request_quote/construction-service/10_Yard_Person.png",
          description: i18n.t('rollOffList.10YardDumpsterDesc', 'en'),
          descriptionFr: i18n.t('rollOffList.10YardDumpsterDesc', 'fr'),
          icon1: "house",
          icon2: "axe",
          icon3: "shovel",
          icon4: "leaf",
          recommended: false,
          available: null,
        },
        frequency: {}
    },
    {
        qty: 0 ,
        size:{
          text: i18n.t('rollOffList.15YardDumpster', 'en'),
          textFr: i18n.t('rollOffList.15YardDumpster', 'fr'),
          binSize: "15 YARD",
          spec: i18n.t('rollOffList.15YardDumpsterSpec', 'en'),
          specFr: i18n.t('rollOffList.15YardDumpsterSpec', 'fr'),
          img: "https://img.wasteconnections.com/corporate-office-the-woodlands/request_quote/construction-service/15_Yard_Person.png",
          description: i18n.t('rollOffList.15YardDumpsterDesc', 'en'),
          descriptionFr: i18n.t('rollOffList.15YardDumpsterDesc', 'fr'),
          icon1: "couch",
          icon2: "box",
          icon3: "officechair",
          icon4: "",
          recommended: false,
          available: null,
        },
        frequency: {}
    },
    {
        qty: 0 ,
        size:{
          text: i18n.t('rollOffList.20YardDumpster', 'en'),
          textFr: i18n.t('rollOffList.20YardDumpster', 'fr'),
          binSize: "20 YARD",
          spec: i18n.t('rollOffList.20YardDumpsterSpec', 'en'),
          specFr: i18n.t('rollOffList.20YardDumpsterSpec', 'fr'),
          img: "https://img.wasteconnections.com/corporate-office-the-woodlands/request_quote/construction-service/20_Yard_Person.png",
          description: i18n.t('rollOffList.20YardDumpsterDesc', 'en'),
          descriptionFr: i18n.t('rollOffList.20YardDumpsterDesc', 'fr'),
          icon1: "brickwall",
          icon2: "shovel",
          icon3: "wheelbarrow",
          icon4: "cementmixer",
          recommended: false,
          available: null,
        },
        frequency: {}
    },
    {
        qty: 0 ,
        size:{
          text: i18n.t('rollOffList.30YardDumpster', 'en'),
          textFr: i18n.t('rollOffList.30YardDumpster', 'fr'),
          binSize: "30 YARD",
          spec: i18n.t('rollOffList.30YardDumpsterSpec', 'en'),
          specFr: i18n.t('rollOffList.30YardDumpsterSpec', 'fr'),
          img: "https://img.wasteconnections.com/corporate-office-the-woodlands/request_quote/construction-service/30_Yard_Person.png",
          description: i18n.t('rollOffList.30YardDumpsterDesc', 'en'),
          descriptionFr: i18n.t('rollOffList.30YardDumpsterDesc', 'fr'),
          icon1: "gardenshovel",
          icon2: "saw",
          icon3: "paintroller",
          icon4: "constructionhelmet",
          recommended: false,
          available: null,
        },
        frequency: {}
    },
    {
        qty: 0,
        size:{
          text: i18n.t('rollOffList.40YardDumpster', 'en'),
          textFr: i18n.t('rollOffList.40YardDumpster', 'fr'),
          binSize: "40 YARD",
          spec: i18n.t('rollOffList.40YardDumpsterSpec', 'en'),
          specFr: i18n.t('rollOffList.40YardDumpsterSpec', 'fr'),
          img: "https://img.wasteconnections.com/corporate-office-the-woodlands/request_quote/construction-service/40_Yard_Person.png",
          description: i18n.t('rollOffList.40YardDumpsterDesc', 'en'),
          descriptionFr: i18n.t('rollOffList.40YardDumpsterDesc', 'fr'),
          icon1: "liftmachine",
          icon2: "wheelloader",
          icon3: "brickwall",
          icon4: "",
          recommended: false,
          available: null,
        },
        frequency: {}
    },
]

}
// reset quote to default (Need to be check for something better)
const quote = () => {
  const index = useIndexStore();
  const user = useUserStore();
  const contactus = useContactUsStore();
  index.servicesType = [
    { id: 0, name: "ResidentialService", text: "serviceView.home", leadType:"Residential", icon: "residential", color: "#175E45", checked: false, },
    { id: 1, name: "CommercialService", text: "serviceView.business", leadType:"Commercial", icon: "business", color: "#1F528F", checked: false, },
  ],
  index.servicefromquote = '',
  index.serviceFormErrors = false,
  index.formErrors = [],
  index.snackbar = false,
  index.completeAddress = false,
  index.dialog = false,
  index.completeAddressMsg = "",
  index.trackViewSplitter = false;
  index.errorAxios = false,
    index.apiName = '',
    // user.orderView = "",
    index.view.prediction = false,
    index.loading = false,
    index.loadingWithService = false,
    index.formValid = true,
    index.isPhoneNumberShowing = false;


    // Contact Us reset form
    user.form.checkbox = '';
    user.form.type = '';
    user.form.rollOffBox = '';
    user.form.acceptConditions = '';
    user.form.addressTo = '';
    user.form.datePickup = '';
    user.form.datePickupFormatted = '';
    user.form.pickupBefore = '';
    user.form.pickupBeforeFormatted = '';
    user.form.pickupAfter = '';
    user.form.pickupAfterFormatted = '';
    user.form.driverTime = '';
    user.form.driverTimeFormatted = '';
    user.form.containerOut = '';
    user.form.stopRequestStatus = '';
    user.form.cartType = '';
    user.form.containerIssue = '';
    user.form.upgradeService = '';
    user.form.driverRating = 4,
    user.form.driverTime = '';
    user.form.fileUpload = null;
    user.form.type = undefined;
    contactus.serviceCU = '';
    contactus.view.name = '';
    contactus.view.index = true;
    contactus.view.recyclePlus = false;
    contactus.view.bulkItemPickup = false;
    contactus.view.requestPickup = false;
    contactus.view.missedPickup = false;
    contactus.view.brokenContainer = false;
    contactus.view.deliveryIssue = false;
    contactus.view.iAmMoving = false;
    contactus.view.transferService = false;
    contactus.view.cancelService = false;
    contactus.view.stopService = false;
    contactus.view.serviceUpgrade = false;
    contactus.view.vacation = false;
    contactus.view.driverReview = false;
    contactus.view.requestQuote = false;
    contactus.view.startService = false;
    contactus.view.payMyBill = false;
    user.form.datePickupMenu = false;
    user.form.pickupBeforeMenu = false;
    user.form.pickupAfterMenu = false;
    user.form.driverTimeMenu = false;
    contactus.valid = true;

    // Request Quote
    user.form.leadExternalId = '';
    user.form.businessName = '';
    user.form.serviceType = useIndexStore().serviceLobLocked ? user.form.serviceType : '';
    user.form.categoryType = '';
    user.form.tzone = '';
    user.form.predictions = '';
    user.form.predictionsSelected = '';
    user.form.serviceDetail = ''; // categoryType: serviceDetail
    user.form.shreddingDetail = '';
    user.form.description = '';
    user.form.alreadyACustomer = false,
    user.form.hoa = '';
    user.form.tenant = '';
    user.form.hoaAndTenant = '';
    user.form.assignToDistrict = 0,
    user.form.webLeadStep = '';
    user.form.itemType = '';
    user.form.itemTypeDescription = '';
    user.form.selectedTypeId = '';
    user.form.selectedDumpsterId = [];
    user.form.menuNavigationId = '';
    user.form.menuOrder = ["ServiceView"];

    user.form.wantedStartDate = '';
    user.form.wantedStartDateMenu = false;
    user.form.wantedStartDateFormatted = '';

    user.form.wantedDeliveryDate = '';
    user.form.wantedDeliveryDateMenu = false;
    user.form.wantedDeliveryDateFormatted = '';

    user.form.wantedEndDate = '';
    user.form.wantedEndDateMenu = false;
    user.form.wantedEndDateFormatted = '';

    user.form.wantedFrequency = {};
    user.form.wantedSize = {};
    user.form.wantedQty = 0;
    user.form.wantedQtyErr = false;

    user.form.businessType = '';
    user.form.serviceForm = [];
    user.form.svgCategorySelected = '';

    user.form.servicesWantedOfferStatus = '';
    user.form.servicesWantedPricingAvailable = '';

    user.form.survey = [];
    user.form.minDate = new Date().toISOString().slice(0,10),
    user.form.maxDate = new Date( new Date().getFullYear() + 1, new Date().getMonth(), new Date().getDate() ) .toISOString() .slice(0, 10),


    index.apiResultOriginal.data = '';
    index.apiResultOriginal.storeFrontURL = '';
    index.apiResultOriginal.districtID= ""


    index.apiResult.data = '';
    index.apiResult.storeFrontURL = '';
    index.apiResult.districtID = '';


    index.storefrontResult.store = '';
    index.storefrontResult.user = '';

    user.form.servicesWantedJSON = null;
    index.residentialWantedForm = [
      { id:0, title: i18n.t('residentialWantedForm.garbagePickup', 'en'), titleFr: i18n.t('residentialWantedForm.garbagePickup', 'fr'), qty: 0, size: {} , frequency: {}, icon: 'home-garbage',},
      { id:1, title: i18n.t('residentialWantedForm.recycling', 'en'), titleFr: i18n.t('residentialWantedForm.recycling', 'fr'), qty: 0, size: {}, frequency: {}, icon: 'home-garbage-recycling',},
      { id:2, title: i18n.t('residentialWantedForm.organics', 'en'), titleFr: i18n.t('residentialWantedForm.organics', 'fr'),  qty: 0, size: {}, frequency: {}, icon: 'OrganicWaste',},
    ];
    index.residentialMhoamWantedForm = [
      { id:0, title: i18n.t('residentialWantedForm.garbagePickup', 'en'), titleFr: i18n.t('residentialWantedForm.garbagePickup', 'fr'), qty: 0, size: {}, frequency: {}, icon: 'solidwaste', icon2: 'mhoam-home-garbage',},
      { id:1, title: i18n.t('residentialWantedForm.recycling', 'en'), titleFr: i18n.t('residentialWantedForm.recycling', 'fr'), qty: 0, size: {}, frequency: {}, icon: 'recyclingdumpstericon', icon2: 'mhoam-home-garbage-recycling',},
      { id:2, title: i18n.t('residentialWantedForm.organics', 'en'), titleFr: i18n.t('residentialWantedForm.organics', 'fr'), qty: 0, size: {}, frequency: {}, icon: 'OrganicWaste',},
    ];
    index.commercialWantedForm = [
      { id:0, title: i18n.t('servicesWantedForm.solidWaste', 'en'), titleFr: i18n.t('servicesWantedForm.solidWaste', 'fr'), qty: 0, size: {} , frequency: {}, icon: 'solidwaste',},
      { id:1, title: i18n.t('servicesWantedForm.recyclingDumpster', 'en'), titleFr: i18n.t('servicesWantedForm.recyclingDumpster', 'fr'),  qty: 0, size: {}, frequency: {}, icon: 'recyclingdumpstericon',},
      { id:2, title: i18n.t('servicesWantedForm.organics', 'en'), titleFr: i18n.t('servicesWantedForm.organics', 'fr'),  qty: 0, size: {}, frequency: {}, icon: 'OrganicWaste',},
    ];
    index.commercialMhoamWantedForm = [
      { id:0, title: i18n.t('residentialWantedForm.garbagePickup', 'en'), titleFr: i18n.t('residentialWantedForm.garbagePickup', 'fr'), qty: 0, size: {} , frequency: {}, icon: 'solidwaste', icon2: 'mhoam-home-garbage',},
      { id:1, title: i18n.t('residentialWantedForm.recycling', 'en'), titleFr: i18n.t('residentialWantedForm.recycling', 'fr'), qty: 0, size: {}, frequency: {}, icon: 'recyclingdumpstericon', icon2: 'mhoam-home-garbage',},
      { id:2, title: i18n.t('residentialWantedForm.organics', 'en'), titleFr: i18n.t('residentialWantedForm.organics', 'fr'),  qty: 0, size: {}, frequency: {}, icon: 'OrganicWaste',},
    ];
    index.jsonForm = [
      {
        id:0,
        icon: '',
        title: '',
        businessName:'',
        qty: 0,
        size: {} ,
        frequency: {},
        description:'',
        date: '',
        servicetype: [],
        serviceDetail:'',
      },
    ];
};

//  Reset Api result of storefront
const apiStoreData = (keepDistrict) => {

  useIndexStore().apiResult.data = null;
  useIndexStore().apiResult.storeFrontURL = "";

  if(!keepDistrict){
    useIndexStore().apiResult.districtID = "";
  }
  // API call returned values for customer account from address
  useContactUsStore().apiValues.sessionID = "";
  useContactUsStore().apiValues.apiResponse = "";
  useContactUsStore().apiValues.questionsName = [];
  useContactUsStore().apiValues.questionsEmail = [];
  useContactUsStore().apiValues.questionsPhone = [];
  useContactUsStore().apiValues.answerName = "";
  useContactUsStore().apiValues.answerPhone = "";
  useContactUsStore().apiValues.answerEmail = "";

};

//  Reset user form
const user = () => {

  const user = useUserStore();

  user.form.name = '';
  user.form.firstName = '';
  user.form.lastName = '';
  user.form.email = '';
  user.form.phone = '';

};

//  Reset address data
const address = (keepAddress) => {

  const user = useUserStore();
  const index = useIndexStore();

  index.addressVerified = false;

  user.form.streetNumber = '';
  user.form.searchPlace = '',
  user.form.route = '';
  user.form.street = '';
  user.form.city = '';
  user.form.state = '';
  user.form.zip = '';
  user.form.country = '';
  user.form.lat = '';
  user.form.lng = '';

  if(!keepAddress){
    user.form.address = '';
  }

};
export default {rolloff,commercialChart,variable,quote,apiStoreData,user,address};