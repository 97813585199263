<template>
    <div class="mb-10">
        <v-col class="pa-0 min-height-44 d-flex align-center">
                <v-btn absolute large color="grey" class="white--text rounded-lg" @click="loading ? null : $emit('click')">
                <v-icon large left>
                mdi-chevron-left
                </v-icon>
                <v-spacer></v-spacer>
                {{ i18n.t("back") }}
                <v-spacer></v-spacer>
            </v-btn>
            <icon class="flex-grow-1" :svg='svg'/>
        </v-col>
    </div>
</template>

<script>
import i18n from '@/i18n';
import {mapWritableState} from 'pinia';
import { useIndexStore } from '@/store';
import icon from "../requestquote/utility/SvgLoader"
    export default {
        name: "BackView",
        props:{
            svg: String
        },
        components:{icon},
        data() {
            return{
                i18n:i18n,
            }
        },
        computed:{
            ...mapWritableState(useIndexStore,['loading'])
        }
    }
</script>

<style lang="scss" scoped>

</style>