// import axios from "axios";
// import { load } from "recaptcha-v3";
// import i18n from "@/i18n";
import { defineStore } from 'pinia'
// import { useDumpsterCommercialStore } from "./dumpstercommercial";

export const useUserStore = defineStore('UserStore', {
  state: () => (
      {
        form: {
            // Contact Us
            searchPlace: '',
            prediction: null,
            predictionSelected: null,
            name: '',
            type: undefined,
            rollOffBox: '',
            checkbox: '',
            addressTo: '',
            datePickup: '',
            datePickupFormatted: '',
            datePickupMenu: false,
            pickupBefore: '',
            pickupBeforeFormatted: '',
            pickupAfter: '',
            pickupAfterFormatted: '',
            containerOut: '',
            cartType: '',
            containerIssue: '',
            upgradeService: '',
            driverRating: 5,
            stopRequestStatus: '',
            driverTime: null,
            driverTimeMenu: false,
            fileUpload: null,
            // Request Quote
            leadExternalId: "",
            serviceType: "",
            categoryType: "",
            address: "",
            streetNumber: "",
            route: "",
            street: "",
            city: "",
            state: "",
            zip: "",
            country: "",
            lat: "",
            lng: "",
            tzone: "",
            acceptConditions: "",
            predictions: null,
            predictionsSelected: null,
            businessName: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            serviceDetail: "", // categoryType = serviceDetail
            shreddingDetail: "",
            description: "",
            alreadyACustomer: false,
            hoa: null,
            tenant: null,
            hoaAndTenant: null,
            assignToDistrict: 0,

            webLeadStep: "",

            itemType: "",
            itemTypeDescription: "",
            selectedTypeId: "",

            menuNavigationId: "",
            menuOrder: ["ServiceView"],

            wantedStartDate: "",
            wantedStartDateMenu: false,
            wantedStartDateFormatted: "",

            wantedDeliveryDate: "",
            wantedDeliveryDateMenu: false,
            wantedDeliveryDateFormatted: "",

            wantedEndDate: "",
            wantedEndDateMenu: false,
            wantedEndDateFormatted: "",

            wantedFrequency: {},
            wantedSize: {},
            wantedQty:0,
            wantedQtyErr:false,

            businessType: "",
            serviceForm: [],
            svgCategorySelected: "",
            servicesWantedJSON :null,
            servicesWantedOfferStatus: '',
            servicesWantedPricingAvailable: '',
            servicesWantedWithPricing: null,

            survey: [],

            minDate: new Date().toISOString().slice(0,10),
            maxDate: new Date(
            new Date().getFullYear() + 1,
            new Date().getMonth(),
            new Date().getDate()
            )
            .toISOString()
            .slice(0, 10),
        },
      }
    ),
  getters: {
  },
  actions: {
  },
})
