<template>
  <v-card>
    <v-card-title class="justify-center text-center text-break">
      {{ i18n.t("selectFullAddress") }}
    </v-card-title>
    <v-card-text class="pt-5">
      <v-list dense two-line subheader outlined class="pb-0 rounded-lg">
        <v-list-item-group v-model="form.predictionsSelected" mandatory="mandatory" class="rounded-lg">
          <template v-for="(prediction, index) in form.predictions">
            <v-list-item v-bind:key="'L' + index" v-bind:value="prediction.description" class="rounded-lg">
              <template v-slot:default="{ active }">
                <v-list-item-content
                  :class="index === Object.keys(form.predictions).length - 1 ? 'my-4 secondary--text' : ''">
                  <v-list-item-title
                    v-if="prediction.structured_formatting.main_text === i18n.t('continueWithThisAddress')"
                    class="justify-center text-center subtitle-2">
                    {{ prediction.structured_formatting.main_text }}
                  </v-list-item-title>
                  <v-list-item-subtitle class="text--primary justify-center text-center subtitle-2">
                    {{ prediction.structured_formatting.main_text !== i18n.t('continueWithThisAddress') ?
                      `${prediction.structured_formatting.main_text},`: ''}}
                    {{ prediction.structured_formatting.secondary_text }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon large v-if="!active">
                    mdi-checkbox-blank-outline
                  </v-icon>
                  <v-icon large v-else color="success">
                    mdi-checkbox-outline
                  </v-icon>
                </v-list-item-action>
              </template>
            </v-list-item>
            <v-divider v-if="index < form.predictions.length - 1" v-bind:key="'D' + index"></v-divider>
          </template>
        </v-list-item-group>
      </v-list>
    </v-card-text>
    <v-card-actions>
      <v-row class="no-gutters flex-wrap flex-row fill-height">
        <v-col cols="auto" class="grow pa-2">
          <BackBtn currentTabComponent="AddressView"></BackBtn>
        </v-col>
        <v-col cols="auto" class="grow pa-2">
          <ForwardBtn :parentRefs="$refs" currentTabComponent="AddressView"></ForwardBtn>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-card>
</template>

<script>
import i18n from "@/i18n";
import { useIndexStore } from '@/store/index';
import { useUserStore } from "@/store/user";
import { mapState, mapActions, mapWritableState } from 'pinia';

import BackBtn from "../requestquote/utility/backBtn.vue";
import ForwardBtn from "../requestquote/utility/forwardBtn.vue";

export default {
  name: "PredictionDialog",
  components: {"BackBtn": BackBtn, "ForwardBtn": ForwardBtn},
  data() {
    return {
      i18n: i18n,
    };
  },
  computed: {
    ...mapWritableState(useUserStore, ["form"]),
    ...mapState(useIndexStore, []),
    ...mapWritableState(useIndexStore, ["loading", "dialog"]),
  },
  methods: {
    ...mapActions(useIndexStore, [

    ]),
  },
};
</script>

<style scoped>
.subtitle-2 {
  white-space: normal;
}
</style>