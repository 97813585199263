<template>
    <div class="px-7">
        <div v-if="orderView == 'processing'">
            <v-sheet outlined class="mt-10 rounded-lg" height="482px" style="border: 4px solid #008239">
                <v-container fill-height>
                    <v-row>
                        <v-col>
                            <p class="text-h4 font-weight-medium text-center">{{ i18n.t('pleaseWait') }}</p>
                            <p class="text-h6 font-weight-medium text-center">{{ i18n.t('yourRequestIsBeingSent') }}</p>
                            <v-progress-linear indeterminate height="23px" class="rounded-xl mt-12" size color="green"></v-progress-linear>
                        </v-col>
                    </v-row>
                </v-container>
            </v-sheet>
        </div>

        <div v-if="orderView == 'confirmationEmail'">
            <p :class="$vuetify.breakpoint.xs ? 'text-h4':'text-h3'" class="font-weight-medium my-10">{{ i18n.t('weveGotYourOrder') }}</p>
            <v-sheet outlined class="rounded-lg" style="border: 4px solid #008239">
                <v-flex>
                    <v-col class="text-right pr-4 pb-0">
                        <v-btn
                        elevation="0"
                        class="green-svg"
                        @click="dialog='info'"
                        x-small
                        icon>
                        <icon svg="info"></icon>
                        </v-btn>
                    </v-col>
                </v-flex>
                <v-flex class="xs10 offset-xs1 my-8">

                    <v-row class="mb-6">
                        <v-col cols="auto">
                            <v-container fill-height grid-list-md text-center>
                                <v-layout row wrap align-center>
                                    <v-flex>
                                        <v-img :src="supportUser" :height="172" :width="172" alt="Support"></v-img>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-col>
                        <v-col class="pt-6">
                            <v-container fill-height grid-list-md>
                                <v-layout col wrap>
                                    <v-flex>
                                        <v-row>
                                            <v-col>
                                                <p class="text-h4 font-weight-medium mb-0">{{ i18n.t('hello') }} {{form.firstName ? form.firstName : 'John Doe'}}</p>
                                            </v-col>
                                        </v-row>
                                        <v-row>
                                            <v-col class="pt-0">
                                                <p v-if="i18n.locale !== 'fr'" class="text-body-1">{{ i18n.t('werePreparedServiceLlocation')}} <span class="font-weight-bold">${{form.servicesWantedPricingAvailable ? form.servicesWantedPricingAvailable.toFixed(2) : i18n.t('na')}} {{ i18n.t('perMonth') }}*</span></p>
                                                <p v-if="i18n.locale === 'fr'" class="text-body-1">{{ i18n.t('werePreparedServiceLlocation')}} <span class="font-weight-bold">{{form.servicesWantedPricingAvailable ? form.servicesWantedPricingAvailable.toFixed(2).replace('.', ',') : i18n.t('na')}}$ {{ i18n.t('perMonth') }}*</span></p>
                                            <!-- but we need to complete a <span class="green--text text--darken-4">site survey</span> and <span class="green--text text--darken-4">agree to terms and conditions</span> before this can be an <span class="green--text text--darken-4">official offer</span>. -->
                                            </v-col>
                                        </v-row>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-col>
                    </v-row>
                    <v-row v-for="(item,i) in updatedServicesWantedForm(true)" :key="i">
                        <v-col class="pt-0">
                            <v-card class="residential-bg rounded-lg" elevation="1">
                                <v-container fill-height fluid>
                                    <v-row align="center">
                                        <v-col :cols="$vuetify.breakpoint.mobile ? 'auto' : '3'" class="ml-2 my-2 text-body-1">
                                            <v-card-text v-if="i18n.locale !== 'fr'" class="px-0 py-0 font-weight-bold">{{item.size.text.match(/ *\([^)]*\) */g, "") ? item.size.text.replace(/ *\([^)]*\) */g, "") : item.size.text}}</v-card-text>
                                            <v-card-text v-if="i18n.locale === 'fr'" class="px-0 py-0 font-weight-bold">{{item.size.textFr.match(/ *\([^)]*\) */g, "") ? item.size.textFr.replace(/ *\([^)]*\) */g, "") : item.size.textFr}}</v-card-text>
                                            <v-card-text class="px-0 py-0">{{ i18n.t('holds') }} <span class="font-weight-bold">{{i18n.locale === 'fr' ? item.size.weightFr : item.size.weight}}</span></v-card-text>
                                        </v-col>
                                        <v-col :cols="$vuetify.breakpoint.xs ? 'auto' : $vuetify.breakpoint.sm ? '3' : '4'" class="my-2 text-body-1" :class="$vuetify.breakpoint.xs ? 'py-2' : ''">
                                            <v-list-item class="pr-0" :class="$vuetify.breakpoint.xs ? '' : 'pl-8'">
                                                <v-list-item-content class="pt-0">
                                                <v-list-item-title
                                                    class="text-wrap font-weight-bold">
                                                    <icon
                                                    svg="home-garbage" width="2em" height="2em"
                                                    />
                                                    <icon
                                                    svg="garbage-bag" width="1.5em" height="1.5em"
                                                    />
                                                    x {{item.size.amountGarbages}}
                                                </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <v-col :cols="$vuetify.breakpoint.mobile ? 'auto' : '3'" class="my-2 text-body-1">
                                            <v-card-text class="px-0 py-0">{{ i18n.t('quantity') }}: <span class="font-weight-bold">{{item.qty}}</span></v-card-text>
                                            <v-card-text class="px-0 py-0">{{ i18n.t('frequency') }}: <span class="font-weight-bold">{{i18n.locale === 'fr' ? item.frequency.frequencyFr : item.frequency.frequency}}</span></v-card-text>
                                        </v-col>
                                        <v-col v-if="!$vuetify.breakpoint.xs &&  !$vuetify.breakpoint.sm" class="text-center my-2">
                                            <icon svg="checkmark"/>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pb-0">
                            <p v-if="i18n.locale !== 'fr'"> <span class="text-h4 font-weight-medium">Total: </span><span class="text-h4 font-weight-medium green--text text--darken-4">${{form.servicesWantedPricingAvailable ? form.servicesWantedPricingAvailable.toFixed(2) : i18n.t('na')}}</span> / {{ i18n.t('everyMonth') }}</p>
                            <p v-if="i18n.locale === 'fr'"> <span class="text-h4 font-weight-medium">Total: </span><span class="text-h4 font-weight-medium green--text text--darken-4">{{form.servicesWantedPricingAvailable ? form.servicesWantedPricingAvailable.toFixed(2).replace('.', ',') : i18n.t('na')}}$</span>/{{ i18n.t('everyMonth') }}</p>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col :cols="$vuetify.breakpoint.xs ? '12':'5'">
                                <v-btn height="49" block style="text-transform: unset;" class="secondary rounded-lg text-h6" v-ripple="loading ? false : true" @click="loading ? null : validate('Accepted')">{{ i18n.t('getMeStarted') }}<v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular></v-btn>
                        </v-col>
                        <v-col :cols="$vuetify.breakpoint.xs ? '12':'6'">
                            <v-hover v-slot="{hover}">
                                <v-btn @click="dialog = 'survey'" height="49" class="rounded-lg callBtnLabel" :class="hover?'white--text':''" :color="hover?'#152F70':''" style="border: 2px solid #153F70;"  block><icon v-if="!$vuetify.breakpoint.xs" :class="hover ? 'white-svg':'blue-svg'" svg="mini-phone" /><span color="#153F70" :class="$vuetify.breakpoint.xs ? '':'pl-4'" class="text-h6">{{ i18n.t('haveAnAgentContactMe') }}</span> <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular></v-btn>
                            </v-hover>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-card-text class="pt-0">*{{ i18n.t('contingentOnASiteSafetySurvey') }}</v-card-text>
                    </v-row>
                    <!-- <v-row>
                        <v-col :cols="$vuetify.breakpoint.xs ? '12':'4'">
                            <v-hover v-slot="{hover}">
                                <v-btn height="49" color="#153F70" block class="rounded-lg text-h6" :class="hover ? 'blue lighten-4' : ''" outlined elevation="8" @click="1">More information</v-btn>
                            </v-hover>
                        </v-col>
                    </v-row> -->
                </v-flex>
            </v-sheet>
            <v-card class="my-8 residential-bg rounded-lg" elevation="0">
                <v-flex class="px-10 pt-8 pb-6">
                    <v-row>
                        <v-col :cols="$vuetify.breakpoint.xs ? '12':'12'" class="pb-0">
                            <p class="text-h6"><span class="pr-2"><icon class="green-svg" width="1.5em" height="1.5em" svg="question-mark" /></span>{{ i18n.t('needMoreHelp') }}</p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pt-0">
                            <p class="text-body-1">{{ i18n.t('wereHereToTalkIfYouWouldLikeTo') }} <b>{{ i18n.t('discussThisQuote') }}</b> {{ i18n.t('orOur') }} <b>{{ i18n.t('services') }}</b>{{ i18n.t('clickCallMe') }}</p>
                        </v-col>
                    </v-row>
                    <!-- <v-row>
                        <v-col :cols="$vuetify.breakpoint.xs ? '12':'4'">
                            <v-hover v-slot="{hover}">
                                <v-btn height="49" class="rounded-lg callBtnLabel" :class="hover?'white--text':''" :color="hover?'#152F70':''" style="border: 2px solid #153F70;"  block><icon :class="hover ? 'white-svg':'blue-svg'" svg="chat" /><span color="#153F70" class="pl-4 text-h6">Chat now</span></v-btn>
                            </v-hover>
                        </v-col>
                        <v-col :cols="$vuetify.breakpoint.xs ? '12':'5'">
                                <v-hover v-slot="{hover}">
                                <v-btn height="49" class="rounded-lg callBtnLabel" :class="hover?'white--text':''" :color="hover?'#152F70':''" style="border: 2px solid #153F70;"  block><icon :class="hover ? 'white-svg':'blue-svg'" svg="schedule" /><span color="#153F70" class="pl-4 text-h6">Schedule an appointment</span></v-btn>
                            </v-hover>
                        </v-col>
                    </v-row> -->
                    <v-row>
                        <v-col :cols="$vuetify.breakpoint.xs ? '12':'4'">
                            <v-hover v-slot="{hover}">
                                <v-btn :href="'tel:' + phone" height="49" class="rounded-lg callBtnLabel" :class="hover?'white--text':''" :color="hover?'#152F70':''" style="border: 2px solid #153F70;"  block><icon :class="hover ? 'white-svg':'blue-svg'" svg="mini-phone" /><span color="#153F70" class="pl-4 text-h6">{{ i18n.t('callMe') }}</span></v-btn>
                            </v-hover>
                        </v-col>
                        <!-- <v-col :cols="$vuetify.breakpoint.xs ? '12':'4'">
                                <v-hover v-slot="{hover}">
                                <v-btn height="49" class="rounded-lg callBtnLabel" :class="hover?'white--text':''" :color="hover?'#152F70':''" style="border: 2px solid #153F70;"  block><icon :class="hover ? 'white-svg':'blue-svg'" svg="refresh" /><span color="#153F70" class="pl-4 text-h6">Another order</span></v-btn>
                            </v-hover>
                        </v-col> -->
                    </v-row>
                </v-flex>
            </v-card>
        </div>

        <!-- <div v-if="orderView == 'confirmationQuote' ">
            <p class="text-h3 font-weight-medium my-10">We've got your order.</p>
            <v-sheet outlined class="rounded-lg" style="border: 4px solid #008239">
                <v-flex class="my-8 mx-5">
                    <v-row class="mb-6">
                        <v-col cols="auto">
                            <v-container fill-height grid-list-md text-center>
                                <v-layout row wrap align-center>
                                    <v-flex>
                                        <v-img :src="supportUser" :height="172" :width="172" alt="Support"></v-img>
                                    </v-flex>
                                </v-layout>
                            </v-container>
                        </v-col>
                        <v-col class="pt-8">
                            <v-container fill-height grid-list-md>
                                <v-layout row wrap>
                                    <v-row>
                                        <v-col>
                                            <p class="text-h4 font-weight-medium mb-0">Let's set it up!</p>
                                        </v-col>
                                    </v-row>
                                    <v-row>
                                        <v-col class="pt-0">
                                            <p class="text-body-1" style="font-size:18px!important;">To complete a site survey and agree to terms and conditions call my direct number, click Chat Now or schedule an appointment on my calendar below.</p>
                                            <br/>
                                        </v-col>
                                    </v-row>
                                </v-layout>
                            </v-container>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col :cols="$vuetify.breakpoint.xs ? '12':'3'">
                            <v-hover v-slot="{hover}">
                                <v-btn height="49" class="rounded-lg" :class="hover?'white--text':''" :color="hover?'#152F70':''" style="border: 2px solid #153F70;"  block><icon :class="hover ? 'white-svg':'blue-svg'" svg="chat" /><span color="#153F70" class="pl-4 text-h6">Chat now</span></v-btn>
                            </v-hover>
                        </v-col>
                        <v-col :cols="$vuetify.breakpoint.xs ? '12':'6'">
                            <v-hover v-slot="{hover}">
                                <v-btn height="49" class="rounded-lg" :class="hover?'white--text':''" :color="hover?'#152F70':''" style="border: 2px solid #153F70;"  block><icon :class="hover ? 'white-svg':'blue-svg'" svg="schedule" /><span color="#153F70" class="pl-4 text-h6">Schedule an appointment</span></v-btn>
                            </v-hover>
                        </v-col>
                        <v-col :cols="$vuetify.breakpoint.xs ? '12':'3'">
                            <v-hover v-slot="{hover}">
                                <v-btn :href="'tel:' + phone" height="49" class="rounded-lg callBtnLabel" :class="hover?'white--text':''" :color="hover?'#152F70':''" style="border: 2px solid #153F70;"  block><icon :class="hover ? 'white-svg':'blue-svg'" svg="mini-phone" /><span color="#153F70" class="pl-4 text-h6">Call me</span></v-btn>
                            </v-hover>
                        </v-col>
                    </v-row>

                    <v-row v-for="(item,i) in filterExistOnly(servicesWantedForm)" :key="i">
                        <v-col class="pt-0">
                            <v-card class="residential-bg rounded-lg" elevation="1">
                                <v-container fill-height fluid>
                                    <v-row align="center">
                                        <v-col :cols="$vuetify.breakpoint.mobile ? 'auto' : '3'" class="ml-2 my-2 text-body-1">
                                            <v-card-text class="px-0 py-0 font-weight-bold">{{item.text.replace(/ *\([^)]*\) */g, "")}}</v-card-text>
                                            <v-card-text class="px-0 py-0">Holds: <span class="font-weight-bold">{{item.weight}}</span></v-card-text>
                                        </v-col>
                                        <v-col :cols="$vuetify.breakpoint.mobile ? 'auto' : '3'" class="my-2 text-body-1">
                                            <v-list-item class="pr-0 pl-8">
                                                <v-list-item-content class="pt-0">
                                                <v-list-item-title
                                                    class="text-wrap font-weight-bold">
                                                    <icon
                                                    svg="home-garbage" width="2em" height="2em"
                                                    />
                                                    <icon
                                                    svg="garbage-bag" width="1.5em" height="1.5em"
                                                    />
                                                    x {{item.amountGarbages}}
                                                </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <v-col :cols="$vuetify.breakpoint.mobile ? 'auto' : '3'" class="my-2 text-body-1">
                                            <v-card-text class="px-0 py-0">Quantity: <span class="font-weight-bold">{{item.qty}}</span></v-card-text>
                                            <v-card-text class="px-0 py-0">Frequency: <span class="font-weight-bold">{{item.frequency}}</span></v-card-text>
                                        </v-col>
                                        <v-col class="text-right my-2" :cols="$vuetify.breakpoint.mobile ? '2' : ''">
                                            <icon svg="checkmark"/>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col class="pb-0">
                            <p class="text-h4 font-weight-medium">Total: <span class="green--text text--darken-4">${{form.servicesWantedPricingAvailable ? form.servicesWantedPricingAvailable.toFixed(2) : "N/A"}}</span></p>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-card-text class="px-0 py-0 text-body-1">Now we offer an online audit service, so you can get started straight away!</v-card-text>
                        <v-col :cols="$vuetify.breakpoint.xs ? '12':'6'">
                            <v-btn height="49" block class="secondary rounded-lg text-h6" @click="1">Perform online waste audit</v-btn>
                        </v-col>
                    </v-row>
                </v-flex>
            </v-sheet>
        </div> -->
        <!-- survey needs to be bind to a variable(s) and to APEX -->
        <v-dialog v-model='dialog' v-if="dialog=='survey'" transition="dialog-top-transition" max-width="900px" persistent :disabled="loading" attach hide-overlay>
            <div>
                <v-sheet outlined class="rounded-lg" style="border: 4px solid #008239">
                    <v-card>
                        <v-flex>
                            <v-col class="text-right pb-0">
                                <v-btn
                                    elevation="0"
                                    class="green-svg"
                                    @click="dialog=''"
                                    x-small
                                    icon>
                                    <icon svg="x"></icon>
                                </v-btn>
                            </v-col>
                        </v-flex>
                        <v-flex class="offset-xs1 xs10">
                                <v-row>
                                    <v-col>
                                            <p class="text-body-1 font-weight-medium">{{ i18n.t('pleaseHelpUsBettering') }}</p>
                                            <p class="text-h4 font-weight-bold">{{ i18n.t('tellUsWhy')}}</p>
                                            <v-checkbox
                                            class="font-weight-medium"
                                            v-model="form.survey"
                                            :label="i18n.t('theOrderIsNotCorrect')"
                                            value="The order is not correct"
                                            hide-details
                                            ></v-checkbox>
                                            <v-checkbox
                                                class="font-weight-medium"
                                                v-model="form.survey"
                                                :label="i18n.t('alreadyHaveOtherService')"
                                                value="Already have other service"
                                                hide-details
                                            ></v-checkbox>
                                            <v-checkbox
                                                class="font-weight-medium"
                                                v-model="form.survey"
                                                :label="i18n.t('priceIsTooHigh')"
                                                value="Price is too high"
                                                hide-details
                                            ></v-checkbox>
                                            <v-checkbox
                                                class="font-weight-medium"
                                                v-model="form.survey"
                                                :label="i18n.t('imStillThinkingAboutIt')"
                                                value="I’m still thinking about it"
                                                hide-details
                                            ></v-checkbox>
                                            <v-checkbox
                                                class="font-weight-medium"
                                                v-model="form.survey"
                                                :label="i18n.t('serviceOptions.other')"
                                                value="Other"
                                                hide-details
                                            ></v-checkbox>
                                    </v-col>
                                </v-row>
                                <v-card-actions>
                                    <v-row>
                                        <v-col class="d-flex flex-row my-6">
                                            <!-- <v-btn width="15%" class="btn-label rounded-lg white--text mr-2 flex-grow-0 accent lighten-1" height="45"  @click="loading ? null : back()">
                                            <v-icon x-large left>
                                                mdi-chevron-left
                                            </v-icon>
                                            <v-spacer></v-spacer>
                                            {{ i18n.t("back") }}
                                            <v-spacer></v-spacer>
                                            </v-btn> -->
                                            <v-btn width="22%" color="success" height="45" class="btn-label rounded-lg" v-ripple="loading ? false : true" @click="loading ? null : validate('Refused');dialog=''">
                                            <v-spacer></v-spacer>
                                                    {{ i18n.t('submit') }}
                                            <v-spacer></v-spacer>
                                            <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card-actions>
                        </v-flex>
                    </v-card>
                </v-sheet>
            </div>
        <!-- <v-row v-if="surveyBool" justify="center">
            <v-dialog v-model="surveyBool" scrollable hide-overlay max-width="696px">
                <v-card class="rounded-xl" color="#707070">
                    <v-toolbar color="#707070" elevation="0">
                        <v-spacer></v-spacer>
                        <v-btn icon @click="surveyBool = false">
                            <v-icon color="white" x-large>mdi-close-circle</v-icon>
                        </v-btn>
                    </v-toolbar>
                    <v-flex class="offset-xs1 xs10 white--text">
                        <p class="text-body-1 font-weight-medium" style="font-size: 18px!important;">Please, help us bettering our service and website</p>
                        <p class="text-h4 font-weight-bold mb-10">Tell us why aren't you ordering our service?</p>
                        <v-checkbox
                            color="white"
                            style="font-size: 18px!important;accent-color:white"
                            class="font-weight-medium"
                            v-model="form.survey"
                            label="The order is not correct"
                            value="The order is not correct"
                            hide-details>
                        </v-checkbox>
                        <v-checkbox
                            color="white"
                            style="font-size: 18px!important;"
                            class="font-weight-medium"
                            v-model="form.survey"
                            label="Already have other service"
                            value="Already have other service"
                            hide-details>
                        </v-checkbox>
                        <v-checkbox
                            color="white"
                            style="font-size: 18px!important;"
                            class="font-weight-medium"
                            v-model="form.survey"
                            label="Price is too high"
                            value="Price is too high"
                            hide-details>
                        </v-checkbox>
                        <v-checkbox
                            color="white"
                            style="font-size: 18px!important;"
                            class="font-weight-medium"
                            v-model="form.survey"
                            label="I’m still thinking about it"
                            value="I’m still thinking about it"
                            hide-details>
                        </v-checkbox>
                        <v-checkbox
                            color="white"
                            style="font-size: 18px!important;"
                            class="font-weight-medium"
                            v-model="form.survey"
                            label="Other"
                            value="Other"
                            hide-details>
                        </v-checkbox>
                        <v-card-actions>
                            <v-col class="text-center">
                            <v-btn width="23%" elevation="0" color="success" height="45" class="btn-label rounded-lg text-capitalize" style="font-size:18px" v-ripple="loading ? false : true" @click="loading ? null : validate('Refused')">
                            <v-spacer></v-spacer>
                                    Submit
                            <v-spacer></v-spacer>
                            <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
                            </v-btn>
                            </v-col>
                        </v-card-actions>
                    </v-flex>
                </v-card>
            </v-dialog>
        </v-row> -->
        </v-dialog>
        <div v-if="orderView =='summaryService'">
            <p :class="$vuetify.breakpoint.xs ? 'text-h4':'text-h3'" class="font-weight-medium my-10">{{ i18n.t('weveGotYourOrder') }}</p>
            <v-sheet outlined class="rounded-lg" style="border: 4px solid #008239">
                <v-flex class="xs10 offset-xs1 my-8">
                    <v-card-text class="text-h5 pl-0 pb-7">
                    {{ i18n.t('summaryOfServices') }}
                    </v-card-text>
                    <v-row v-for="(item,i) in summaryList" :key="i">
                        <v-col class="pt-0">
                            <v-card class="residential-bg rounded-lg" elevation="1">
                                <v-container fill-height fluid>
                                    <v-row align="center">
                                        <v-col :cols="$vuetify.breakpoint.xs ? 'auto' : '3'" class="ml-2 my-2 text-body-1" :class="$vuetify.breakpoint.xs ? 'py-2' : ''">
                                            <v-card-text v-if="i18n.locale !== 'fr'" class="px-0 py-0 font-weight-bold">{{item.size.text.replace(/ *\([^)]*\) */g, "")}}</v-card-text>
                                            <v-card-text v-if="i18n.locale === 'fr'" class="px-0 py-0 font-weight-bold">{{item.size.textFr.replace(/ *\([^)]*\) */g, "")}}</v-card-text>
                                            <v-card-text class="px-0 py-0">{{ i18n.t('holds') }} <span class="font-weight-bold">{{i18n.locale === 'fr' ? item.size.weightFr : item.size.weight}}</span></v-card-text>
                                        </v-col>
                                        <v-col :cols="$vuetify.breakpoint.xs ? 'auto' : $vuetify.breakpoint.sm ? '3' : '4'" class="my-2 text-body-1" :class="$vuetify.breakpoint.xs ? 'py-2' : ''">
                                            <v-list-item class="pr-0" :class="$vuetify.breakpoint.xs ? '' : 'pl-8'">
                                                <v-list-item-content class="pt-0">
                                                <v-list-item-title
                                                    class="text-wrap font-weight-bold">
                                                    <icon
                                                    svg="home-garbage" width="2em" height="2em"
                                                    />
                                                    <icon
                                                    svg="garbage-bag" width="1.5em" height="1.5em"
                                                    />
                                                    x {{item.size.amountGarbages}}
                                                </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-col>
                                        <v-col :cols="$vuetify.breakpoint.xs ? 'auto' : $vuetify.breakpoint.sm ? '5' : '4'" class="my-2 text-body-1" :class="$vuetify.breakpoint.xs ? 'py-2' : ''">
                                            <v-card-text class="px-0 py-0">{{ i18n.t('quantity') }}: <span class="font-weight-bold">{{item.qty}}</span></v-card-text>
                                            <v-card-text class="px-0 py-0">{{ i18n.t('frequency') }}: <span class="font-weight-bold">{{i18n.locale === 'fr' ? item.frequency.frequencyFr : item.frequency.frequency}}</span></v-card-text>
                                        </v-col>
                                        <v-col v-if="!$vuetify.breakpoint.xs &&  !$vuetify.breakpoint.sm" class="text-center my-2">
                                            <icon svg="checkmark"/>
                                        </v-col>
                                    </v-row>
                                </v-container>
                            </v-card>
                        </v-col>
                    </v-row>
                    <!-- <v-card class="residential-bg rounded-lg" elevation="1">
                        <v-container fill-height fluid>
                            <v-row align="center">
                                <v-col :cols="$vuetify.breakpoint.mobile ? 'auto' : '3'" class="ml-2 my-2 text-body-1">
                                    <v-card-text class="px-0 py-0 font-weight-bold">{{item.size.text.replace(/ *\([^)]*\) */g, "")}}</v-card-text>
                                    <v-card-text class="px-0 py-0">Holds: <span class="font-weight-bold">{{item.size.weight}}</span></v-card-text>
                                </v-col>
                                <v-col :cols="$vuetify.breakpoint.mobile ? 'auto' : '3'" class="my-2 text-body-1">
                                    <v-list-item class="pr-0 pl-8">
                                        <v-list-item-content class="pt-0">
                                        <v-list-item-title
                                            class="text-wrap font-weight-bold">
                                            <icon
                                            svg="home-garbage" width="2em" height="2em"
                                            />
                                            <icon
                                            svg="garbage-bag" width="1.5em" height="1.5em"
                                            />
                                            x {{item.size.amountGarbages}}
                                        </v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </v-col>
                                <v-col :cols="$vuetify.breakpoint.mobile ? 'auto' : '3'" class="my-2 text-body-1">
                                    <v-card-text class="px-0 py-0">Quantity: <span class="font-weight-bold">{{item.qty}}</span></v-card-text>
                                    <v-card-text class="px-0 py-0">Frequency: <span class="font-weight-bold">{{item.frequency.frequency}}</span></v-card-text>
                                </v-col>
                                <v-col class="text-right my-2" :cols="$vuetify.breakpoint.mobile ? '2' : ''">
                                    <icon svg="checkmark"/>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card> -->
                    <v-row>
                        <v-col>
                            <v-card-text class="text-h4 font-weight-medium mb-0 pl-0">{{ i18n.t('letsSetItUp') }}</v-card-text>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col :cols="$vuetify.breakpoint.xs ? '3': $vuetify.breakpoint.sm ? '2' : '1'" class=" text-center">
                            <icon svg="headphone"></icon>
                        </v-col>
                        <v-col class="pl-0 pt-0">
                            <v-card-text class="text-h5 pl-0 font-weight-medium">{{ i18n.t('conductSiteSurveyChoseAnOption') }}</v-card-text>
                            <v-card-text class="text-wrap pt-3 pl-0" style="font-size:18px!important;">{{ i18n.t('aSafetySiteSurvey') }}
                            </v-card-text>
                            <!-- Buttons "Chat Now", "Call my direct number", "Schedule an appointment" -->
                            <!-- <v-row>
                                <v-col class="pl-0" :cols="$vuetify.breakpoint.xs ? '12':'4'">
                                    <v-hover v-slot="{hover}">
                                        <v-btn height="49" class="rounded-lg" :class="hover?'white--text':''" :color="hover?'#152F70':''" style="border: 2px solid #153F70;" block><icon :class="hover ? 'white-svg':'blue-svg'" svg="chat" /><span color="#153F70" class="pl-4 text-h6">Chat now</span></v-btn>
                                    </v-hover>
                                </v-col>
                                <v-col class="pl-0" :cols="$vuetify.breakpoint.xs ? '12':'6'">
                                    <v-hover v-slot="{hover}">
                                        <v-btn :href="'tel:' + phone" height="49" class="rounded-lg callBtnLabel" :class="hover?'white--text':''" :color="hover?'#152F70':''" style="border: 2px solid #153F70;" block><icon :class="hover ? 'white-svg':'blue-svg'" svg="mini-phone" /><span color="#153F70" class="pl-4 text-h6">Call my direct number</span></v-btn>
                                    </v-hover>
                                </v-col>
                            </v-row>
                            <v-row>
                                <v-col class="pl-0 pt-0 pb-8" :cols="$vuetify.breakpoint.xs ? '12':'6'">
                                    <v-hover v-slot="{hover}">
                                        <v-btn height="49" class="rounded-lg" :class="hover?'white--text':''" :color="hover?'#152F70':''" style="border: 2px solid #153F70;"  block><icon :class="hover ? 'white-svg':'blue-svg'" svg="schedule" /><span color="#153F70" class="pl-4 text-h6">Schedule an appointment</span></v-btn>
                                    </v-hover>
                                </v-col>
                            </v-row> -->
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col :cols="$vuetify.breakpoint.xs ? '3': $vuetify.breakpoint.sm ? '2' : '1'" class="text-center">
                            <icon svg="paper"></icon>
                        </v-col>
                        <v-col class="pl-0 pt-0">
                            <v-card-text class="text-h5 pl-0 font-weight-medium">{{ i18n.t('contractReviewAndSignature')}}</v-card-text>
                            <v-card-text class="text-wrap pt-3 pl-0" style="font-size:18px!important;">{{ i18n.t('onceYouHaveChosenAnOption') }}
                            </v-card-text>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col :cols="$vuetify.breakpoint.xs ? '3': $vuetify.breakpoint.sm ? '2' : '1'" class="text-center">
                            <icon svg="mini-dumpster"></icon>
                        </v-col>
                        <v-col class="pl-0 pt-0">
                            <v-card-text class="text-h5 pl-0 font-weight-medium">{{ i18n.t('dumpsterDropOff') }}</v-card-text>
                            <v-card-text class="text-wrap pt-3 pl-0" style="font-size:18px!important;">{{ i18n.t('afterCompletingTheContract') }}
                            </v-card-text>
                        </v-col>
                    </v-row>
                </v-flex>
            </v-sheet>
        </div>


        <div v-if="orderView == 'noService'">
            <v-card-text class="text-h5 font-weight-medium red--text pb-0">{{ i18n.t('weDontOfferThisServiceOnYourArea') }}</v-card-text>
            <v-card-text class="font-weight-medium pt-2">{{ i18n.t('thisIsTheAlternativeService') }}</v-card-text>
            <v-card class="residential-bg rounded-lg" elevation="1">
                <v-container fill-height fluid>
                    <!-- <v-row align="center">
                        <v-col :cols="$vuetify.breakpoint.mobile ? 'auto' : '3'" class="ml-2 my-2 text-body-1">
                            <v-card-text class="px-0 py-0 font-weight-bold">{{item.text.replace(/ *\([^)]*\) */g, "")}}</v-card-text>
                            <v-card-text class="px-0 py-0">Holds: <span class="font-weight-bold">{{item.weight}}</span></v-card-text>
                        </v-col>
                        <v-col :cols="$vuetify.breakpoint.mobile ? 'auto' : '3'" class="my-2 text-body-1">
                            <v-list-item class="pr-0 pl-8">
                                <v-list-item-content class="pt-0">
                                <v-list-item-title
                                    class="text-wrap font-weight-bold">
                                    <icon
                                    svg="home-garbage" width="2em" height="2em"
                                    />
                                    <icon
                                    svg="garbage-bag" width="1.5em" height="1.5em"
                                    />
                                    x {{item.amountGarbages}}
                                </v-list-item-title>
                                </v-list-item-content>
                            </v-list-item>
                        </v-col>
                        <v-col :cols="$vuetify.breakpoint.mobile ? 'auto' : '3'" class="my-2 text-body-1">
                            <v-card-text class="px-0 py-0">Quantity: <span class="font-weight-bold">{{item.qty}}</span></v-card-text>
                            <v-card-text class="px-0 py-0">Frequency: <span class="font-weight-bold">{{item.frequency}}</span></v-card-text>
                        </v-col>
                        <v-col class="text-right my-2" :cols="$vuetify.breakpoint.mobile ? '2' : ''">
                            <icon svg="checkmark"/>
                        </v-col>
                    </v-row> -->
                    <v-row class="justify-center">
                        <v-col :cols="$vuetify.breakpoint.xs ? '12':'5'">
                                <v-btn height="49" block style="text-transform: unset;" class="secondary rounded-lg text-h6" v-ripple="loading ? false : true" @click="loading ? null : validate('Accepted')">{{ i18n.t('getMeStarted') }} <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular></v-btn>
                        </v-col>
                        <v-col :cols="$vuetify.breakpoint.xs ? '12':'5'">
                            <v-hover v-slot="{hover}">
                                <v-btn height="49" block class="rounded-lg text-h6" :class="hover?'white--text grey':'grey--text white'" outlined @click="surveyBool = true">{{ i18n.t('haveAnAgentContactMe') }} <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular></v-btn>
                            </v-hover>
                        </v-col>
                    </v-row>
                </v-container>
            </v-card>

            <v-card-text class="font-weight-medium">
                {{ i18n.t('ifThisDoesntFitYourNeedsCallUs') }}
            </v-card-text>
            <v-row>
                <v-col :cols="$vuetify.breakpoint.xs ? '12':'3'">
                    <v-hover v-slot="{hover}">
                        <v-btn :href="'tel:' + phone" height="49" class="rounded-lg callBtnLabel" :class="hover?'white--text':''" :color="hover?'#152F70':''" style="border: 2px solid #153F70;" block><icon :class="hover ? 'white-svg':'blue-svg'" svg="mini-phone" /><span color="#153F70" class="pl-4 text-h6">{{ i18n.t('callMe') }}</span></v-btn>
                    </v-hover>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.xs ? '12':'4'">
                <v-btn @click="updateViewComponent(getIndexComponent())" block height="49" style="text-transform: unset" class="white--text blue-active-svg rounded-lg" outlined>
                    <span class="text-h6">
                    {{ i18n.t('exploreOtherServices') }}
                    </span>
                </v-btn>
                </v-col>
            </v-row>
        </div>
        <div v-if="orderView =='feedback'" class="text-center">
            <v-row>
                <v-col cols="12" class="d-flex justify-center align-center mt-14 pt-14 pb-7 mb-0">
                <v-card elevation="0" width="382px">
                    <v-row>
                        <v-col class="pt-2 px-0">
                            <v-card-text class="text-h4 font-weight-bold" >{{ i18n.t('ThankYouForYourValuableFeedback') }}</v-card-text>
                            <v-card-text class="text-body-1">{{ i18n.t('weAppreciateYouLettingUsKnow')}}</v-card-text>
                        </v-col>
                    </v-row>
                </v-card>
                </v-col>
            </v-row>
            <v-row class="justify-center">
                <v-col :cols="$vuetify.breakpoint.xs ? '12':'3'">
                        <v-hover v-slot="{hover}">
                        <v-btn :href="'tel:' + phone" height="49" class="rounded-lg callBtnLabel" :class="hover?'white--text':''" :color="hover?'#152F70':''" style="border: 2px solid #153F70;"  block><icon :class="hover ? 'white-svg':'blue-svg'" svg="mini-phone" /><span color="#153F70" class="pl-4 text-h6">{{ i18n.t('callNow') }}</span></v-btn>
                    </v-hover>
                </v-col>
                <v-col :cols="$vuetify.breakpoint.xs ? '12':'4'">
                        <v-hover v-slot="{hover}">
                        <v-btn @click="updateViewComponent(getIndexComponent())" height="49" class="rounded-lg" :class="hover?'white--text':''" :color="hover?'#152F70':''" style="border: 2px solid #153F70;"  block><icon :class="hover ? 'white-svg':'blue-svg'" /><span color="#153F70" class="pl-4 text-h6" :class=" hover ? '' : 'callBtnLabel'">{{ i18n.t('exploreOtherServices') }}</span>
                        </v-btn>
                    </v-hover>
                </v-col>
            </v-row>
        </div>
        <v-dialog v-model="dialog" v-if="dialog=='info'" transition="dialog-top-transition" max-width="900px" :disabled="loading" attach hide-overlay>
            <div>
                <v-sheet outlined class="rounded-lg" style="border: 4px solid #008239">
                    <v-flex>
                        <v-col class="text-right pb-0">
                            <v-btn
                                elevation="0"
                                class="green-svg"
                                @click="dialog=''"
                                x-small
                                icon>
                                <icon svg="x"></icon>
                            </v-btn>
                        </v-col>
                    </v-flex>
                    <v-flex class="px-7">
                        <v-card-text class="text-h4 font-weight-bold">{{ i18n.t('commercialSolidWasteRecycling') }}</v-card-text>
                        <v-card-text class="text-body-1">{{ i18n.t('toEnsureThatOurCommercial') }}</v-card-text>
                        <v-card-text class="text-body-1">{{ i18n.t('thisProcessStartsWith') }}</v-card-text>
                        <v-card-text class="text-body-1">{{ i18n.t('afterTheConsultation') }}</v-card-text>
                        <v-card-text class="text-body-1">{{ i18n.t('onceTheSiteSafetySurvey') }}</v-card-text>
                        <v-card-text class="mb-6">
                            <v-hover v-slot="{hover}">
                                <v-btn :href="'tel:' + phone" height="49" class="rounded-lg callBtnLabel" :class="hover?'white--text':''" :color="hover?'#152F70':''" style="border: 2px solid #153F70;"  block><icon :class="hover ? 'white-svg':'blue-svg'" svg="mini-phone" /><span color="#153F70" class="pl-4 text-h6">{{ i18n.t('talkToAnAgent') }}</span>
                                </v-btn>
                            </v-hover>
                        </v-card-text>
                    </v-flex>
                </v-sheet>
            </div>
        </v-dialog>

    </div>
</template>



<script>
import i18n from "@/i18n";
import { useDumpsterCommercialStore } from '../../../../store/dumpstercommercial';
import { useIndexStore } from '../../../../store/index';
import { mapWritableState } from 'pinia';
import { mapActions } from 'pinia';
import { useAxiosFormStore } from "@/store/axios"
import icon from "../../utility/SvgLoader";

    export default {
        name: "ProcessingOrder",
        components:{icon},
        props:{
        phone: String,
        },
        data() {
            return {
                dialog: '',
                i18n: i18n,
                surveyBool: false,
                selectedType: "",
                supportUser: 'https://embed.wasteconnections.com/images-widget/supportUser.png',
            };
        },
  computed: {
    ...mapWritableState(useIndexStore, [
      "form","loading","servicesWantedForm","orderView","component","windowSize","summaryList"
    ])
  },
  methods: {
    ...mapActions(useIndexStore, [
      'updateViewComponent',"filterExistOnly","updatedServicesWantedForm","getIndexComponent"
    ]),
    ...mapActions(useDumpsterCommercialStore, [
      "dumpstersToJson"
    ]),
    async validate(status){
        this.loading = true;
        var vm = this;
        vm.form.servicesWantedOfferStatus = status

        useIndexStore().sendHttpRequestQuote('LeadInformation',useAxiosFormStore().getDataToAxios());
    }
  }

}
</script>

<style scoped>
/* ::v-deep i.v-icon.notranslate.mdi.mdi-checkbox-blank-outline{
    color:white!important;
}
::v-deep label.v-label.theme--light{
    color:white!important;
} */
::v-deep .callBtnLabel{
    text-transform: unset;
    color: #153F70 !important;
}
::v-deep .green-svg {
  fill: #175e45 !important;

}
::v-deep .white-svg {
  fill: white !important;
}
::v-deep .blue-svg {
  fill: #1f528f !important;
}
::v-deep .blue-active-svg {
  background-color: #1F528F !important;
}
::v-deep .residential-bg {
  background: #e9f4f0 !important;
}
</style>