<template>
  <v-row>
    <v-col offset="1" cols="10">
      <v-row>
        <v-col>
          <v-card-text primary-title class="px-0 mt-3 mb-0 title text-center font-weight-bold text-wrap" >
            {{ i18n.t('chooseYourPreferredService') }}
          </v-card-text>
          <v-card-text class="text-center pt-0 mb-8">
            <icon svg="step-marker"/>
          </v-card-text>
        </v-col>
      </v-row>
      <v-form ref="form" v-model="formValid" lazy-validation>
        <v-item-group>
          <v-row>
            <v-col :class="$vuetify.breakpoint.mdAndUp ? 'my-1' : 'my-1 px-8'" v-for="dumpster in dumpsters" :key="dumpster.size.text" cols="12" md="6">
                  <v-hover v-slot="{ hover }">
                    <v-card
                    @click="{dumpsterSelected = dumpster}"
                    class="box-shadow-1 rounded-lg mb-2 py-0 px-3 mx-3" :class="classSvg(dumpster.qty, hover)" height="240px" min-width="50%" :outlined="serviceFormErrors && dumpsters.every(function(v){ return v.qty == 0 }) && !dumpsterDetail.serviceCardId.length  ? true : false " :style="serviceFormErrors && dumpsters.every(function(v){ return v.qty == 0 }) && !dumpsterDetail.serviceCardId.length  ? 'border-width:2px !important;border-color:red !important;' : '' ">
                      <v-row align="center">
                        <v-col cols="auto" class="mb-0">
                          <v-card-text class="pr-0 my-0 card-title font-weight-bold" :class="dumpster.qty ? 'white--text py-0' : 'pt-6 pb-2'">
                            {{ i18n.locale==='fr'?dumpster.size.textFr:dumpster.size.text }}</v-card-text>
                        </v-col>
                        <v-col cols="auto">
                          <icon v-if="dumpster.qty" svg="checkmark"/>
                        </v-col>
                      </v-row>
                      <v-row align="center">
                        <v-col
                          class="px-0 pt-0 mt-0 flex-grow-1"
                          :class="dumpster.size.spec ? 'pt-0 mt-0' : ''">
                          <v-list-item class="pr-0 pl-5">
                            <v-list-item-content class="pt-0">
                              <v-list-item-title
                                class="garbage-number text-wrap"
                                :class="dumpster.qty ? 'white--text' : ''">
                                <icon
                                  :svg="dumpster.size.icon"
                                  :class="fillSvg(dumpster.qty, hover)"/>
                                <icon
                                  svg="garbage-bag"
                                  :class="fillSvg(dumpster.qty, hover)"/>
                                x {{ dumpster.size.amountGarbages }}
                              </v-list-item-title>
                              <v-list-item-title
                                class="card-label font-weight-bold text-wrap mt-1"
                                :class="dumpster.qty ? 'white--text' : ''">
                                {{ i18n.t('holds') }} {{ dumpster.size.weight }}
                              </v-list-item-title>
                              <v-list-item-subtitle
                                class="text-wrap"
                                :class="dumpster.qty ? 'white--text' : 'black--text'">
                                {{ i18n.locale==='fr'?dumpster.size.specFr : dumpster.size.spec }}
                              </v-list-item-subtitle>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                        <v-col class="px-0 pt-0 mt-0">
                          <v-list-item :class="$vuetify.breakpoint.mdAndUp ? 'pr-3 pl-0':'pr-1 pl-2'">
                            <icon svg="store" :class="fillSvg(dumpster.qty, hover)" />
                            <v-list-item-content class="pb-1">
                              <v-list-item-title
                                class="pl-2 card-label font-weight-medium text-wrap"
                                :class="dumpster.qty ? 'white--text' : ''">
                                {{ i18n.locale==='fr'?dumpster.size.businessTypeFr : dumpster.size.businessType }}
                              </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                          <v-list-item v-if="commChartOfficeLabel" :class="$vuetify.breakpoint.mdAndUp ? 'pr-3 pl-0':'pr-1 pl-2'">
                            <icon svg="peoples" :class="fillSvg(dumpster.qty, hover)" />
                            <v-list-item-content class="pb-1">
                              <v-list-item-title
                                class="pl-2 card-label font-weight-medium text-wrap"
                                :class="dumpster.qty ? 'white--text' : ''">
                                {{ i18n.locale==='fr'?dumpster.size.descriptionFr : dumpster.size.description }}
                                </v-list-item-title>
                            </v-list-item-content>
                          </v-list-item>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-hover>
            </v-col>
          </v-row>
          <v-row>
            <v-col :class="$vuetify.breakpoint.mdAndUp ? 'my-0 py-0 px-0' : 'my-0 py-0 px-3'">
              <v-item v-slot="{ active }">
                <v-hover v-slot="{ hover }">
                  <v-card
                    class="box-shadow-1 rounded-lg mb-3 py-3 px-3 mx-3"
                    :class="classSvg(active, hover)"
                    @click="servicefromquote = '';component = 'quote'; currentTabComponent= form.menuOrder[form.menuOrder.length - 1]">
                    <v-row align="center" justify="center">
                      <v-col cols="auto">
                        <v-row align="center" justify="center">
                          <v-col cols="auto" class="pr-0">
                            <icon svg="question-mark" :class="fillSvg(active, hover)" />
                          </v-col>
                          <v-col>
                            <v-row>
                              <v-col class="py-0">
                                <v-card-text class="py-0 card-label font-weight-bold text-wrap" :class="active ? 'white--text' : ''">{{ i18n.t('notSureWhatYouNeed') }}</v-card-text>
                              </v-col>
                            </v-row>
                            <v-row>
                              <v-col class="py-0">
                                <v-card-text class="py-0 card-label text-wrap" :class="active ? 'white--text' : ''">{{ i18n.t('requestAQuote') }}</v-card-text>
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-col>
                    </v-row>
                    <icon v-if="active" svg="checkmark" class="checkmark-solo" />
                  </v-card>
                </v-hover>
              </v-item>
            </v-col>
          </v-row>
        </v-item-group>
      </v-form>
    </v-col>
  </v-row>
</template>

<script>
import { useUserStore } from "../../store/user";
import { useIndexStore } from "../../store/index";
import { useDumpsterCommercialStore } from "../../store/dumpstercommercial";
import { mapState, mapWritableState } from "pinia";
import { mapActions } from "pinia";
import i18n from "@/i18n";
import icon from "../requestquote/utility/SvgLoader";
// import NumberInput from "../requestquote/utility/NumberInput";

export default {
  name: "ChooseDumpster",
  components: { icon },

  data() {
    return {
      i18n: i18n,
    };
  },
  computed: {
    qty() {
      return this.dumpsters.map((dumpster) => dumpster.qty);
    },
    ...mapWritableState(useDumpsterCommercialStore, ["dumpsters","dumpsterDetail","dumpsterSelected"]),
    ...mapState(useIndexStore, ['formRules','commChartOfficeLabel']),
    ...mapWritableState(useIndexStore, [
      "currentTabComponent",
      "component",
      "servicefromquote",
      "loading",
      "formValid",
      "formErrors",
      "serviceFormErrors"]),
    ...mapWritableState(useUserStore, [
      "form",
      ]),
  },

  watch: {
    "dumpsterSelected": function(newVal,oldVal){
      if(newVal != oldVal){
        if(this.dumpsterSelected){
          this.dumpsterSelected.qty = 1;
          this.updateViewComponent("DetailDumpster");
        }
        for (let index = 0; index < this.dumpsters.length; index++) {
          if(this.dumpsterSelected.size.text == this.dumpsters[index].size.text){
            this.dumpsters[index].qty = 1;
          }else{
            this.dumpsters[index].qty = 0;
          }
        }
      }
    },
    "dumpsterDetail.serviceCardId": function () {
        for (let index = 0; index < this.dumpsters.length; index++) {
          if (this.dumpsterDetail.serviceCardId.length) {
            this.serviceFormErrors = false;
            this.dumpsters[index].qty = 0;
            this.dumpsterDetail.path = "DumpsterCommercialForm";
            }
        }
    },
  },
  methods: {
    ...mapActions(useIndexStore, ["updateViewComponent"]),
    ...mapActions(useDumpsterCommercialStore, ["classSvg", "fillSvg"]),
    warn(toggle, event) {
      if (toggle != event) {
        toggle();
      }
    },
    validate() {
      this.loading = true;
      var vm = this
      if (vm.validateForm()) {
        vm.updateViewComponent(vm.dumpsterDetail.path);
        vm.loading = false;
      } else {
        vm.loading = false;
      }
    },
    validateForm() {
      if (!this.dumpsterDetail.serviceCardId.length && this.dumpsters.every(function(v){return v.qty == 0})) {
        this.serviceFormErrors = true;
        this.formValid = false;
        this.formErrors = [];
        this.formErrors.push(i18n.t("missingField"));
        return this.$refs.form.validate() && !this.formErrors.length;
      }
      return this.$refs.form.validate();
    },
  },
};
</script>
