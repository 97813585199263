<template>

    <!-- Inside the Dialog for Hoa-->
    <v-card>
        <!-- Title -->
        <v-card-title class="text-h5 justify-center text-center text-break">
            {{ i18n.t('hoa') }}
        </v-card-title>

        <!-- Description -->
        <v-card-text v-if="!hoaList" class="pb-0 text-center text-subtitle-2">
            {{ i18n.t('memberOfHoa') }}
        </v-card-text>

        <!-- Text when Yes to Hoa -->
        <v-card-text v-if="hoaList" class="pb-0">
            <v-alert color="warning" outlined text class="text-center my-4">
                {{ i18n.t('hoaContact') }}
            </v-alert>
        </v-card-text>

        <!-- Divider -->
        <v-divider v-if="!hoaList"></v-divider>

        <!-- Actions -->
        <v-card-actions>
            <v-row class="no-gutters flex-wrap flex-row fill-height">

                <!-- Button No -->
                <v-col v-if="!hoaList" cols="auto" class="grow pa-2">
                    <v-btn block large color="secondary" class="white--text rounded-lg" :loading="loading"
                        :disabled="!loading ? false : true"
                        @click="loading ? null : callHomeOwnerAssociationOrTenant()">
                        <v-icon left>mdi-thumb-down-outline</v-icon>
                        {{ i18n.t('no') }}
                        <template v-slot:loader>
                            <span>{{ i18n.t("validate") }}</span>
                            <v-progress-circular class="ml-2" indeterminate="indeterminate"
                                size="23"></v-progress-circular>
                        </template>
                    </v-btn>
                </v-col>

                <!-- Button Yes -->
                <v-col v-if="!hoaList" cols="auto" class="grow pa-2">
                    <v-btn block large color="success" class="white--text rounded-lg" :loading="loading"
                        :disabled="!loading ? false : true" @click="loading ? null : hoaList = true">
                        <v-icon left>mdi-thumb-up-outline</v-icon>
                        {{ i18n.t('yes') }}
                        <template v-slot:loader>
                            <span>{{ i18n.t("validate") }}</span>
                            <v-progress-circular class="ml-2" indeterminate="indeterminate"
                                size="23"></v-progress-circular>
                        </template>
                    </v-btn>
                </v-col>

                <!-- Button Close -->
                <v-col v-if="hoaList" cols="auto" class="grow pa-2">
                    <v-btn block large color="success" class="white--text rounded-lg" :loading="loading"
                        :disabled="!loading ? false : true" @click="loading ? null : updateLeadForHOA()">
                        {{ i18n.t('snackbar.close') }}
                        <template v-slot:loader>
                            <span>{{ i18n.t("validate") }}</span>
                            <v-progress-circular class="ml-2" indeterminate="indeterminate"
                                size="23"></v-progress-circular>
                        </template>
                    </v-btn>
                </v-col>
            </v-row>
        </v-card-actions>
    </v-card>
</template>

<script>
import i18n from "@/i18n";
import { useIndexStore } from '@/store/index';
import { mapActions, mapWritableState } from 'pinia';
import { useAxiosFormStore } from "@/store/axios";
import salesforce from "@/plugins/salesforce"

export default {
    name: "HoaDialog",
    props: ["disabled"],
    data() {
        return {
            i18n: i18n,
            hoaList: false,
        };
    },
    computed: {
        ...mapWritableState(useIndexStore, ["loading", "dialog"]),
    },
    watch: {
        dialog(newValue, oldValue) {
            if (newValue !== oldValue) {
                this.hoaList = false;
            }
        },
    },
    methods: {
        ...mapActions(useIndexStore, ["homeOwnerAssociationOrTenant"]),
        updateLeadForHOA() {
            useAxiosFormStore().leadHOAAnswer = true;
            salesforce.sendHttpRequestQuote('LeadInformation',useAxiosFormStore().getDataToAxios());
            this.dialog = false;
        },
        callHomeOwnerAssociationOrTenant() {
            useAxiosFormStore().leadHOAAnswer = false;
            this.homeOwnerAssociationOrTenant();
            this.dialog = false;
        },
    },
};
</script>