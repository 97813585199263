import { render, staticRenderFns } from "./MunicipalitiesView.vue?vue&type=template&id=bb064cc8&scoped=true"
import script from "./MunicipalitiesView.vue?vue&type=script&lang=js"
export * from "./MunicipalitiesView.vue?vue&type=script&lang=js"
import style0 from "./MunicipalitiesView.vue?vue&type=style&index=0&id=bb064cc8&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb064cc8",
  null
  
)

export default component.exports