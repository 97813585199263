import { defineStore } from 'pinia'
import i18n from "@/i18n";
import { useIndexStore } from '@/store/index';
import { useUserStore } from '@/store/user';

export const useDumpsterCommercialStore = defineStore('DumpsterCommercial', {
    state: () => ({
      form: useUserStore().form,
      dumpsterDetail:{
        path: '',
        title: '',
        qty: 0,
        pickupFrequency: '',
        recyclingDumpster: false,
        // serviceCardId: [],
        selectedDumpsterId: [],
      },
      dumpsters: [
        {
          id:1,
          qty: 0,
          recyclingDumpster: false,
          size:{
            text: i18n.t('dumpsters.96GallonCart', 'en'),
            textFr: i18n.t('dumpsters.96GallonCart', 'fr'),
            binSize: "96 GALLON",
            amountGarbages: 7,
            weight:'117 lbs',
            spec: '',
            specFr: '',
            businessType: i18n.t('dumpsters.smallRetailsShopsRestaurants', 'en'),
            businessTypeFr: i18n.t('dumpsters.smallRetailsShopsRestaurants', 'fr'),
            description: i18n.t('dumpsters.25-50OfficeStaff', 'en'),
            descriptionFr: i18n.t('dumpsters.25-50OfficeStaff', 'fr'),
            icon:'home-garbage',
          },
          frequency: {},
        },
        {
          id:2,
          qty: 0,
          recyclingDumpster: false,
          size:{
            text: i18n.t('dumpsters.2YardDumpster', 'en'),
            textFr: i18n.t('dumpsters.2YardDumpster', 'fr'),
            binSize: "2 YARD",
            amountGarbages:24,
            weight:'117 lbs',
            spec: i18n.t('dumpsters.2YardDumpsterSpec', 'en'),
            specFr: i18n.t('dumpsters.2YardDumpsterSpec', 'fr'),
            businessType: i18n.t('dumpsters.smallRetailsShopsRestaurants', 'en'),
            businessTypeFr: i18n.t('dumpsters.smallRetailsShopsRestaurants', 'fr'),
            description: i18n.t('dumpsters.25-50OfficeStaff', 'en'),
            descriptionFr: i18n.t('dumpsters.25-50OfficeStaff', 'fr'),
            icon:'commercial-dumpster',
          },
          frequency: {},
        },
        {
          id:3,
          qty: 0,
          recyclingDumpster: false,
          size:{
            text: i18n.t('dumpsters.3YardDumpster', 'en'),
            textFr: i18n.t('dumpsters.3YardDumpster', 'fr'),
            binSize: "3 YARD",
            amountGarbages:36,
            weight:'600 lbs',
            spec: i18n.t('dumpsters.2YardDumpsterSpec', 'en'),
            specFr: i18n.t('dumpsters.2YardDumpsterSpec', 'fr'),
            businessType: i18n.t('dumpsters.smallRetailsShopsRestaurants', 'en'),
            businessTypeFr: i18n.t('dumpsters.smallRetailsShopsRestaurants', 'fr'),
            description: i18n.t('dumpsters.50OfficeStaff', 'en'),
            descriptionFr: i18n.t('dumpsters.50OfficeStaff', 'fr'),
            icon:'commercial-dumpster',
          },
          frequency: {},
        },
        {
          id:4,
          qty: 0,
          recyclingDumpster: false,
          size: {
            text: i18n.t('dumpsters.4YardDumpster', 'en'),
            textFr: i18n.t('dumpsters.4YardDumpster', 'fr'),
            binSize: "4 YARD",
            amountGarbages:48,
            weight:'800 lbs',
            spec: i18n.t('dumpsters.4YardDumpsterSpec', 'en'),
            specFr: i18n.t('dumpsters.4YardDumpsterSpec', 'fr'),
            businessType: i18n.t('dumpsters.midsizeRetailsShopsRestaurants', 'en'),
            businessTypeFr: i18n.t('dumpsters.midsizeRetailsShopsRestaurants', 'fr'),
            description: i18n.t('dumpsters.100OfficeStaff', 'en'),
            descriptionFr: i18n.t('dumpsters.100OfficeStaff', 'fr'),
            icon:'commercial-dumpster',
          },
          frequency: {},
        },

        {
          id:5,
          qty: 0,
          recyclingDumpster: false,
          size:{
            text: i18n.t('dumpsters.6YardDumpster', 'en'),
            textFr: i18n.t('dumpsters.6YardDumpster', 'fr'),
            binSize: "6 YARD",
            amountGarbages:72,
            weight:'1200 lbs',
            spec: i18n.t('dumpsters.6YardDumpsterSpec', 'en'),
            specFr: i18n.t('dumpsters.6YardDumpsterSpec', 'fr'),
            businessType: i18n.t('dumpsters.bigRetailsShopsRestaurants', 'en'),
            businessTypeFr: i18n.t('dumpsters.bigRetailsShopsRestaurants', 'fr'),
            description: i18n.t('dumpsters.250OfficeStaff', 'en'),
            descriptionFr: i18n.t('dumpsters.250OfficeStaff', 'fr'),
            icon:'commercial-dumpster',
          },
          frequency: {},
        },
        {
          id:6,
          qty: 0,
          recyclingDumpster: false,
          size:{
            text: i18n.t('dumpsters.8YardDumpster', 'en'),
            textFr: i18n.t('dumpsters.8YardDumpster', 'fr'),
            binSize: "8 YARD",
            amountGarbages:96,
            weight:'1800 lbs',
            spec: i18n.t('dumpsters.8YardDumpsterSpec', 'en'),
            specFr: i18n.t('dumpsters.8YardDumpsterSpec', 'fr'),
            businessType: i18n.t('dumpsters.smallRetailsShopsGroceryStores', 'en'),
            businessTypeFr: i18n.t('dumpsters.smallRetailsShopsGroceryStores', 'fr'),
            description: i18n.t('dumpsters.500OfficeStaff', 'en'),
            descriptionFr: i18n.t('dumpsters.500OfficeStaff', 'fr'),
            icon:'commercial-dumpster',
          },
          frequency: {},
        },
      ],
      dumpsterSelected: [
      ],
      pickupFrequencies: [
          { id: 0 , frequency: i18n.t('frequencyPicker.1xPerWeek', 'en'), frequencyFr: i18n.t('frequencyPicker.1xPerWeek', 'fr'), pickupFrequency:"1XW"},
          { id: 1 , frequency: i18n.t('frequencyPicker.2xPerWeek', 'en'), frequencyFr: i18n.t('frequencyPicker.2xPerWeek', 'fr'), pickupFrequency:"2XW"},
          { id: 2 , frequency: i18n.t('frequencyPicker.3xPerWeek', 'en'), frequencyFr: i18n.t('frequencyPicker.3xPerWeek', 'fr'), pickupFrequency:"3XW"},
          { id: 3 , frequency: i18n.t('frequencyPicker.everyOtherWeek', 'en'), frequencyFr: i18n.t('frequencyPicker.everyOtherWeek', 'fr'), pickupFrequency:"EOW"},
          { id: 4 , frequency: i18n.t('frequencyPicker.other', 'en'), frequencyFr: i18n.t('frequencyPicker.other', 'fr'), pickupFrequency:"Other"}
      ],
      }),
    getters: {

    },
    actions: {
    // Do the recaptcha and after send api call to salesforce
    sendHttpRequestDumpsterChart(apiName){

      useIndexStore().apiName = apiName
      useIndexStore().orderView = "processing";
      useIndexStore().loading = true;
      useIndexStore().reCaptchaValidation('validationQuote');

    },
    // Go back to first component
    back(){
      this.dumpsterSelected = [];
      useIndexStore().updateViewComponent("ChooseDumpster")
      this.serviceFormErrors = false;
    },
    reset(){
      const store = useDumpsterCommercialStore();
      store.$reset();
    },
    dumpstersToJson(){
      // mapping them to a with specifics keys
      let dumpstersMapped = this.dumpsters.map(({qty,recyclingDumpster,size,frequency}) => ({qty,recyclingDumpster,size,frequency}))

      // filtering with the ones that has data in a object
      let filteredDumpsters = dumpstersMapped.filter((dumpster) => {
        // return dumpster.qty || dumpster.recyclingDumpster || dumpster.frequency.pickupFrequency
        return dumpster.qty
    })
      return filteredDumpsters;
    },

    // get format as request quote
    formatJSON(){

      // mapping them to a with specifics keys
      let dumpstersMapped = this.dumpsters.map(({qty,recyclingDumpster,size,frequency}) => ({qty,recyclingDumpster,size,frequency}))

      // filtering with the ones that has data in a object
      let filteredDumpsters = dumpstersMapped.filter((dumpster) => {
        return dumpster.qty || dumpster.recyclingDumpster || dumpster.frequency.pickupFrequency
    })
      return filteredDumpsters;

    },

    // Change color of background without the svg depending if its hover or active
    classSvg(active,hover){

        if(!active && hover ){
          return 'dumpster-hover'
        }
        if(!active && !hover ){
          return 'dumpster-default'
        }
        if(active){
          return 'dumpster-active'
        }
          return 'dumpster-default'

    },

    //Change color of the SVG ( icon )
    fillSvg(active,hover){

        if(!active && hover ){
          return 'dumpster-icon-default'
        }

        if(!active && !hover ){
          return 'dumpster-icon-default'
        }

        if(active){
          return 'dumpster-icon-white'
        }
          return 'dumpster-icon-default'

    },
    resetList(){

        for (let index = 0; index < this.dumpsters.length; index++) {
          this.dumpsters[index].qty = 0;

      }
    },
    scrollToTop() {
      document.getElementById('scrollToEmbed').scrollIntoView({behavior: "instant"});
    },
  }

  })