<template>
    <v-row>
        <v-col offset="1" cols="10">
            <v-row>
                <v-col>
                    <v-card-text primary-title class="px-0 mt-4 mb-0 title text-center font-weight-bold text-wrap">
                        {{ i18n.t('chooseYourPreferredService') }}
                    </v-card-text>
                    <div class="mb-10">
                        <v-col class="pa-0 min-height-44 d-flex align-center">
                            <v-btn
                                absolute large color="grey" class="white--text rounded-lg" @click="loading?null:back()">
                                <v-icon x-large left>
                                    mdi-chevron-left
                                </v-icon>
                                <v-spacer></v-spacer>
                                {{ i18n.t("back") }}
                                <v-spacer></v-spacer>
                            </v-btn>
                            <icon class="flex-grow-1" svg='step-marker-2-2'/>
                        </v-col>
                    </div>
                </v-col>
            </v-row>
            <v-form ref="form" v-model="formValid" lazy-validation>
                <div>
                    <v-card class="dumpster-active rounded-lg mb-3 py-0 px-3" elevation="1" min-height="150px">
                        <v-row class="mb-6">
                            <v-col cols="auto" sm="auto" class="px-0 pb-0">
                                <v-row class="pr-0 pl-8" align="center">
                                    <v-col cols="auto" class="mb-0 pb-0">
                                        <v-card-text class="py-2 px-0 text-h6 font-weight-medium text-wrap white--text">
                                            {{ i18n.locale==='fr'?dumpsterSelected.size.textFr : dumpsterSelected.size.text }}
                                        </v-card-text>
                                    </v-col>
                                    <v-col cols="auto" class="mb-0 pb-0 px-0">
                                    <icon svg="checkmark"/>
                                    </v-col>
                                    <v-col cols="auto" md="12" class="pb-3 garbage-number text-wrap white--text">
                                        <icon :svg="dumpsterSelected.size.icon" class="dumpster-icon-white"/>
                                        <icon svg="garbage-bag" class="dumpster-icon-white"/>
                                        x {{dumpsterSelected.size.amountGarbages}}
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-col v-if="!$vuetify.breakpoint.xs" cols="12" sm="auto" class="d-flex align-center justify-center px-0 py-7">
                            </v-col>
                            <v-col cols="12" sm="6" class="pb-0 mb-0">
                                    <v-col cols="12" class="py-0 mb-0">
                                        <v-card-text class="pa-1 mb-1 white--text card-label">
                                        {{ i18n.t("frequency") }}
                                        </v-card-text>
                                        <v-select return-object :items="pickupFrequencies" :item-text="i18n.locale==='fr'?'frequencyFr':'frequency'" item-value="pickupFrequency" attach flat dense :rules="formRules.pickupFrequencyRules" v-model="dumpsterSelected.frequency" :placeholder="i18n.t('select')" solo outlined class="rounded-lg input-label" v-bind:disabled="loading"></v-select>
                                    </v-col>
                                    <v-col cols="12" class="py-0 mb-4 pl-1">
                                        <v-card-text class="py-0 px-0 white--text">
                                            <v-btn @click="dumpsterSelected.recyclingDumpster = !dumpsterSelected.recyclingDumpster" icon>
                                                <span>
                                                    <v-icon v-if="!dumpsterSelected.recyclingDumpster" color="white">
                                                        mdi-checkbox-blank-outline
                                                    </v-icon>
                                                    <v-icon v-if="dumpsterSelected.recyclingDumpster" color="white">
                                                        mdi-checkbox-marked
                                                    </v-icon>
                                                </span>
                                            </v-btn>
                                            <span class="card-label">
                                                {{ i18n.t('addRecyclingDumpster') }}
                                            </span>
                                        </v-card-text>
                                    </v-col>
                                </v-col>
                            </v-row>
                    </v-card>
                </div>
                <UserDetailField :lang="lang" :country="country" :parentRefs="$refs"></UserDetailField>
                <DescriptionFieldText></DescriptionFieldText>
                <TermsPolicy :name="name" :terms="terms" :privacy="privacy" :lang="lang"></TermsPolicy>
                <div class="py-0 my-0">
                    <div class="text-center mt-3 pt-0">
                        <v-btn :color=" serviceFormErrors || !formValid  ? 'error' : 'success'" block  height="44px" class="rounded-lg white--text" v-ripple="loading ? false : true" @click="loading ? null : validate()">
                            <span class="btn-form">
                            {{ loading ? i18n.t("validate") : i18n.t("getMyQuote") }}
                            </span>
                            <v-progress-circular v-if="loading" class="ml-4" indeterminate="indeterminate" size="23"></v-progress-circular>
                        </v-btn>
                    </div>
                </div>
            </v-form>
        </v-col>
    </v-row>
</template>

<script>
import { useUserStore } from "../../store/user";
import { useIndexStore } from '../../store/index';
import { useDumpsterCommercialStore } from '../../store/dumpstercommercial';
import { mapState, mapWritableState } from 'pinia';
import { mapActions } from 'pinia';
import UserDetailField from '@/components/sfc/UserDetailField'
import DescriptionFieldText from '@/components/sfc/DescriptionFieldText'
import TermsPolicy from'@/components/sfc/TermsPolicy'
import i18n from "@/i18n";
import icon from "../requestquote/utility/SvgLoader";

export default {
    name: "DetailDumpster",
    components:{icon,UserDetailField,TermsPolicy,DescriptionFieldText},
    props: {
        name: String,
        lang: String,
        terms: String,
        privacy: String,
        country: String,
    },
    data() {
    return{
        i18n:i18n,
    };
    },
    computed: {
        ...mapWritableState(useUserStore, [
            "form",
        ]),
        ...mapState(useIndexStore, [
            'windowSize','formRules',
        ]),
        ...mapWritableState(useIndexStore, [
            "form",
            'loading',
            'formValid',
            "serviceFormErrors",
        ]),
        ...mapWritableState(useDumpsterCommercialStore, [
            'dumpsters','dumpsterDetail','pickupFrequencies','dumpsterSelected'
        ])
    },
    watch:{
        "dumpsterSelected.recyclingdumpsterSelected": function(){
            if(this.dumpsterSelected.recyclingdumpsterSelected == 0){
                this.dumpsterSelected.recyclingdumpsterSelected = true;
            }

        }
    },
    methods: {
        ...mapActions(useIndexStore, [
            'updateViewComponent'
        ]),
        ...mapActions(useDumpsterCommercialStore, [
            'classSvg','fillSvg','back','sendHttpRequestDumpsterChart'

        ]),
        warn(toggle,event){
            if(this.dumpsterDetail.selectedDumpsterId != event){
            toggle()
            }
        },
        validate(){
            this.loading = true;
            var vm = this
            if(vm.$refs.form.validate()){

                vm.sendHttpRequestDumpsterChart()

            }else {
                vm.loading = false;
            }
        },

        }
        };
</script>
<style>

</style>
