<template>
      <div class="my-0 py-0">
        <v-row class="my-0 py-0">
            <v-col>
                <v-checkbox v-model="form.acceptConditions" v-bind:rules="formRules.acceptConditions" v-bind:disabled="loading" color="success darken-2" class="mt-0">
                <template v-slot:label>
                    <div class="black--text">
                    {{ i18n.t("agreeTo") }}
                    <span v-if="i18n.locale == 'en'" class="text-capitalize">{{ name }}</span>
                    <v-tooltip attach bottom>
                        <template v-slot:activator="{ on }">
                        <a target="_blank" rel="nofollow noopener" v-bind:href="terms" v-on:click.stop="return false;" v-on="on">
                        {{ i18n.t("termsOfService") }}
                        </a>
                        </template>
                        <span>
                        {{ i18n.t("termsOfService") }}
                        </span>
                    </v-tooltip>
                    {{ i18n.t("and") }}
                    <v-tooltip attach bottom>
                        <template v-slot:activator="{ on }">
                        <a target="_blank" rel="nofollow noopener" v-bind:href="privacy" v-on:click.stop="return false;" v-on="on">
                            {{ i18n.t("privacyPolicy") }}
                        </a>
                        </template>
                        <span>
                        {{ i18n.t("privacyPolicy") }}
                        </span>
                    </v-tooltip>
                    </div>
                </template>
                </v-checkbox>
            </v-col>
        </v-row>
      </div>
</template>

<script>
import i18n from "@/i18n";
import {useUserStore} from "@/store/user"
import {useIndexStore} from "@/store/index"
import {mapState,mapWritableState} from "pinia"

export default {
  name: "TermsPolicy",
  components:{},
  props: {
    name: String,
    lang: String,
    terms: String,
    privacy: String,
  },

  data() {
    return {
      i18n: i18n,
    };
  },
    computed: {
        ...mapWritableState(useUserStore, [
            "form",
        ]),
        ...mapState(useIndexStore, [
            'formRules',
        ]),
        ...mapWritableState(useIndexStore, [
            'loading',
            "serviceFormErrors",
        ]),

    },
    created() {
      i18n.locale = this.lang;
    },
    watch: {
    "form.serviceType": function () {
      if (this.form.serviceType) {
        this.serviceFormErrors = false;
      }else{
        this.serviceFormErrors = true;
      }
  },
  }
};
</script>

<style scoped>
/* ::v-deep i.v-icon.notranslate.mdi.mdi-checkbox-blank-outline.theme--light{
  color:black !important;
} */
</style>