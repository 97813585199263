import { render, staticRenderFns } from "./UserDetailField.vue?vue&type=template&id=c2b9d504&scoped=true"
import script from "./UserDetailField.vue?vue&type=script&lang=js"
export * from "./UserDetailField.vue?vue&type=script&lang=js"
import style0 from "./UserDetailField.vue?vue&type=style&index=0&id=c2b9d504&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c2b9d504",
  null
  
)

export default component.exports