<template>
      <v-card-text>
        <v-form ref="wasteForm">
          <v-row>
            <v-col class="text-center">
                <!-- <v-img src="https://img.wasteconnections.com/corporate-office-the-woodlands/request_quote/thank-you/icon-action-check_circle.png" width="104px" height="104px" class="mx-auto mb-6"/> -->
                <icon svg = 'congrats-logo'/>
              <v-card-text style="color: #175e45" class="congrats pb-1 pt-6">
                {{ i18n.t('congrats') }}
              </v-card-text>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" class="d-flex justify-center align-center">
              <v-card elevation="0" width="420px">
                <v-row>
                  <v-col class="pt-2 px-0">
                    <v-card-text class="pb-6 text-center text-quote px-0 " :width="windowSize.x < 600 ? '400px' :'420px'">
                      {{ i18n.t('serviceRequest') }}
                    </v-card-text>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center pt-1">
              <v-btn @click="updateViewComponent(getIndexComponent())" width="305px" height="60px" style="text-transform: unset" class="btn-explore rounded-lg">
                <span class="btn-label">
                  {{ i18n.t('exploreOtherServices') }}
                </span>
              </v-btn>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
</template>

<script>
import i18n from "@/i18n";
import { useIndexStore } from '../../../../store/index';
import { mapWritableState } from 'pinia';
import { mapActions } from 'pinia';
import icon from "../../utility/SvgLoader";



export default {
  name: "ThankyouExplore",
  components:{icon},
  data() {
    return {
      i18n: i18n,
      selectedType: "",
    };
  },
  computed: {
    ...mapWritableState(useIndexStore, [
      "windowSize",
    ])
  },
  methods: {
    ...mapActions(useIndexStore, [
      "updateViewComponent","getIndexComponent"
    ]),
  },
};
</script>
<style scoped>
::v-deep .btn-label {
  font-size: 20px !important;
  line-height: 25px;
  word-break: normal !important;
  justify-content: center; /* horizontally centered */
}
::v-deep .btn-explore {
  background-color: #153F70 !important;
  color:white !important;
}
::v-deep .text-quote {
  font-size: 22px;
}
::v-deep .congrats {
  font-size: 32px;
}
@media screen and (max-width: 475px)  {
  ::v-deep .text-quote {
    font-size: 16px;
  }
  ::v-deep .congrats {
    font-size: 28px;
  }
}
@media screen and (max-width: 375px)  {
  ::v-deep .btn-label{
    font-size: 16px !important;
  }
}
</style>
